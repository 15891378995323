import React, {useState} from 'react';


type Props = {
    children: JSX.Element
}
interface ModalContextInterface{
    showModal: Boolean,
    modalMessage: any,
    modalContain: any,
    showDeleteModal: Boolean,
    deleteModal: any,
    setShowModal: any
    setTimeModal: any,
    setShowDeleteModal: any,
    setDeleteModal: any
}

export const ModalContextDefault = {
    showModal: false,
    modalMessage: '',
    modalContain: '',
    showDeleteModal: false,
    deleteModal: {},
    setShowModal: false,
    setTimeModal: '',
    setShowDeleteModal: false,
    setDeleteModal: {}
}

const ModalContext = React.createContext<ModalContextInterface>(ModalContextDefault)

export function ModalContextProvider ({children}: Props) {

    let [showModal, setShowModal] = useState(ModalContextDefault.showModal);
    let [modalContain, setModalContain] = useState<any>({})
    let [showDeleteModal, setShowDeleteModal] = useState<Boolean>(ModalContextDefault.showDeleteModal)
    let [deleteModal, setDeleteModal] = useState<any>({
        show: false,
        id: 0
    })

    const modalMessage = (title:any) => {
        setTimeModal(title.type)
        setModalContain(title)
    }
    
    const setTimeModal = (type: string) => {
        setShowModal(true)
        if(type !== 'unsubscribe'){
            setTimeout(() => {
                setShowModal(false)
            }, 3000);
        }
    }

    return (
        <ModalContext.Provider 
            value={{
                showModal,
                modalContain,
                showDeleteModal,
                deleteModal,
                setTimeModal,
                setShowModal,
                modalMessage,
                setShowDeleteModal,
                setDeleteModal
            }}>
            {children}
        </ModalContext.Provider>)

}

export default ModalContext
