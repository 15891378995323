import {useContext, useState} from 'react';
import { Form } from 'react-bootstrap'
import TeddySelectConstructor from './TeddySelectConstructor';
import UIContext from '../../../../context/UIContext';

import './leftMenuTeddy.css'
import {TeddyIcon, SayIcon, ThinkIcon, SentIcon, BodySelect, EarsSelect, PawsSelect, FaceSelect} from '../../../../assets/index'
import ToolsContext from '../../../../context/ToolsContext';
import UserToolsContext from '../../../../context/UserToolsContext';

interface leftMenuTeddyProps {
    iconChange: any,
    onUpdate: any
}
 
const LeftMenuTeddy: React.FC<leftMenuTeddyProps> = ({iconChange, onUpdate}) => {

    const {iconState, teddyBodyActive, teddyFaceActive, teddyEarsActive, teddyPawsActive, changeTeddyIcon, thinkIconActive, sayIconActive, setIconState, setSayIconActive, setThinkIconActive} = useContext(UIContext)
    const {showTeddyText, setShowTeddyText, setMessageSend} = useContext(ToolsContext)
    const {teddyMessage, setTeddyMessage} = useContext(UserToolsContext)

    const [teddyPart, setTeddyPart] = useState('')

    const [teddyMessageType, setTeddyMessageType] =useState('')

    const [teddyText, setTeddyText] = useState<any>({
        text: ""
    })

    const changeTeddyState = (mode:string) => {
        setIconState(mode)
        setShowTeddyText(true)
        iconChange(mode)
        changeTeddyIcon(mode)
        setTeddyMessageType(mode)
    }

    const handleChange = (e:any) => setTeddyText({ ...teddyText, [e.target.name]: e.target.value })

    const sendMessage = () => {
        setMessageSend(true)
        setShowTeddyText(false)
        setThinkIconActive(false)
        setSayIconActive(false)
        if(teddyMessageType === 'say'){
            setTeddyMessage({...teddyMessage, sayMessage: teddyText.text})
            onUpdate({teddyMessage: {...teddyMessage, sayMessage: teddyText.text}})
        }
        if(teddyMessageType === 'think'){
            setTeddyMessage({...teddyMessage, thinkMessage: teddyText.text})
            onUpdate({teddyMessage: {...teddyMessage, thinkMessage: teddyText.text}})
        }
    }

    const teddySelectorMenu = (teddyEarsActive || teddyFaceActive || teddyBodyActive || teddyPawsActive) ? true : false

    return (  
        <>
           <div className="left-menu-container">

                <EarsSelect 
                    onClick={() => {
                        changeTeddyIcon('ears')
                        setTeddyPart('ears')
                        setShowTeddyText(false)
                    }}
                    fill={(teddyPart === 'ears' && teddyEarsActive) ? '#79CE67' : '#363636'}
                />
                <FaceSelect 
                    onClick={() => {
                        changeTeddyIcon('face')
                        setTeddyPart('face')
                        setShowTeddyText(false)
                    }}
                    fill={(teddyPart === 'face' && teddyFaceActive) ? '#79CE67' : '#363636'}
                />
                <BodySelect 
                    onClick={() => {
                        changeTeddyIcon('body')
                        setTeddyPart('body')
                        setShowTeddyText(false)
                    }}
                    fill={(teddyPart === 'body' && teddyBodyActive) ? '#79CE67' : '#363636'}
                />
                <PawsSelect 
                    onClick={() => {
                        changeTeddyIcon('paws')
                        setTeddyPart('paws')
                        setShowTeddyText(false)
                    }}
                    fill={(teddyPart === 'paws' && teddyPawsActive) ? '#79CE67' : '#363636'}
                />
                {teddySelectorMenu
                    &&
                    <TeddySelectConstructor onUpdate={onUpdate} teddyPart={teddyPart}/>
                }              
                <SayIcon 
                    onClick={() => {
                        changeTeddyState('say')
                        setTeddyMessage({...teddyMessage, say: true})
                    }}
                    fill={sayIconActive && showTeddyText ? '#79CE67' : '#363636'}
                />
                <ThinkIcon 
                    onClick={() => {
                        changeTeddyState('think')
                        setTeddyMessage({...teddyMessage, think: true})
                    }}
                    fill={thinkIconActive && showTeddyText ? '#79CE67' : '#363636'}
                />
                <div className={(!thinkIconActive && !sayIconActive) ? "teddy-text" : `teddy-text expanded ${iconState}`}>
                    <Form.Control
                        onMouseDown={(e:any) => {e.stopPropagation()}}
                        className='input'
                        type='text'
                        name='text'
                        placeholder='Escribe tu texto aquí'
                        autoComplete="off"
                        value={teddyText.text}
                        onChange={  handleChange }
                    />
                    <SentIcon onClick={() => {
                        sendMessage()
                    }}/>
                </div>
            </div>
        </>
    );
}
 
export default LeftMenuTeddy;