import {useRef} from 'react';
import { IonContent } from '@ionic/react';
interface NatureModeProps {
    natureMode: Boolean,
    natureImages: any,
    sandboxElementClass: any,
    setSandboxImage: any
}
 
const NatureMode: React.FC<NatureModeProps> = ({natureMode, natureImages, sandboxElementClass, setSandboxImage}) => {

    const {cloud, mountainPeaks, mountainRoad, mountains, river, rock} = natureImages;

    const sandboxScroll = useRef<HTMLIonContentElement>(null);

    const scrollBy = (amount:number) => {
        if(sandboxScroll.current !== null){
            sandboxScroll.current.scrollByPoint(amount, amount, 1000)
        }
    }

    return ( 
        <IonContent className={natureMode ? 'sandbox-menu expanded' : 'sandbox-menu collapsed'} ref={sandboxScroll}>
            <div className="top-arrow" onClick={()=>scrollBy(-600)}>&raquo;</div>
            <div className="bottom-arrow" onClick={()=>scrollBy(600)}>&raquo;</div>
            <div className={`components ${natureMode}`}>
                <div className="images">
                    <div className={sandboxElementClass(cloud)}>
                        <img src={cloud} 
                            alt="cloud" 
                            draggable="false"
                            onClick={() => setSandboxImage(cloud)}
                        />
                    </div>
                    <div className={sandboxElementClass(mountainPeaks)}>
                        <img src={mountainPeaks} 
                            alt="mountain-peaks" 
                            draggable="false"
                            onClick={() => setSandboxImage(mountainPeaks)}
                        />
                    </div>
                        <div className={sandboxElementClass(mountainRoad)}>
                        <img src={mountainRoad} 
                            alt="mountain-road" 
                            draggable="false"
                            onClick={() => setSandboxImage(mountainRoad)}
                        />
                    </div>
                    <div className={sandboxElementClass(mountains)}>
                        <img src={mountains} 
                            alt="mountains" 
                            draggable="false"
                            onClick={() => setSandboxImage(mountains)}
                        />
                    </div>
                    <div className={sandboxElementClass(river)}>
                        <img src={river} 
                            alt="river" 
                            draggable="false"
                            onClick={() => setSandboxImage(river)}
                        />
                    </div>
                    <div className={sandboxElementClass(rock)}>
                        <img src={rock} 
                            alt="rock" 
                            draggable="false"
                            onClick={() => setSandboxImage(rock)}
                        />
                    </div>
                </div>
            </div>
        </IonContent>
     );
}
 
export default NatureMode;