import { useEffect, useState } from "react";

function Chrono() {
  const [diff, setDiff] = useState<any>(null)
  const [initial, setInitial] = useState<any>(+new Date())

  const redrawClock = ()=>{
    const now =  +new Date();
    setDiff(now - initial)
  }

  useEffect(() => {
    let intervalId = setInterval(redrawClock,1000)
    return(() => {
        clearInterval(intervalId)
    })
  }, []);

  return (
    <div>
      <p style={{fontWeight: 400}}>{timeFormat(diff)}</p>
    </div>
  );
}

const timeFormat = (milliseconds:number) => {
  let date = new Date(milliseconds);
  if (!date) return "00:00:00";

  let mm:any = date.getUTCMinutes();
  let ss:any = date.getSeconds();

  mm = mm < 10 ? "0"+mm :  mm;
  ss = ss < 10 ? "0"+ss :  ss;

  return `${mm}:${ss}`;
};

export default Chrono;