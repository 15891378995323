import {useEffect} from 'react';
import { useHistory } from 'react-router';

import { SandClock, LogoNegative } from '../../assets';
import './waitingRoom.css'
export interface Props{
    sessionCode: any,
}
 
const WaitingRoom: React.FC<Props> = ({sessionCode}) => {

    const history = useHistory()

    useEffect(()=>{
        setTimeout(() => {
            history.push(`/session-panel/${sessionCode}`)
        }, 3000);
    }, []);

    return ( 
        <div className="waiting-room-container">
            <div className="waiting-room-center">
                <div className="waiting-room">
                    <SandClock />
                    <h2>Espera unos segundos</h2>
                    <p>Estamos preparando tu sesión, en breve estaremos contigo.</p>
                </div>
            </div>
            <LogoNegative />
        </div>
     );
}
 
export default WaitingRoom;


