import {useState, useEffect, useRef, useContext, CSSProperties} from 'react';
import { Form } from 'react-bootstrap'
import ToolsContext from '../../../../../context/ToolsContext';
import UserToolsContext from '../../../../../context/UserToolsContext';
import UIContext from '../../../../../context/UIContext';

import Arrow from '../../../../../assets/img/arrow.svg'
import JohariImage from './../../../../../assets/img/draw-menu/johari-screen.png'

import './MentappDraw.css';
interface ContainerProps {
    zoom: number,
    dragging: number,
    setDragging: any,
    onUpdate:(draw:any) => void,
    sharedData: any,
 }

const convertCanvasToImage = (canvas:any) => { 
	var image = new Image(); 
	image.src = canvas.toDataURL("image/png"); 
	return image; 
} 

const MentappDraw: React.FC<ContainerProps> = ({zoom, dragging, setDragging, onUpdate, sharedData}) => {
    
    const {toolsMode, shapeState, lineWidth, color, textState, showTextModal, erase, cleanCanvas, startPositions, canvasCache, isDrawing, setLineWidth, changeColor, setIsDrawing, setStartPositions, setCanvasCache} = useContext(ToolsContext);
    const {drawState, showJohari, setDrawState, setShowJohari} = useContext(UserToolsContext)
    const {panning, setHideBottomMenu} = useContext(UIContext);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const contextRef = useRef<CanvasRenderingContext2D | null>(null);
    let origCanvasTop = 150;
    let origCanvasLeft = 220;
    let canvasTop = origCanvasTop/zoom;
    let canvasLeft = origCanvasLeft/zoom;
    let curve: number[][] = [];
    const [modal, setModal] = useState<Boolean>(false)
    const [text, setText] = useState<any>({
        newText: ""
    })
    const [clickOffset, setClickOffset] = useState({x:0,y:0});
    const [position, setPosition] = useState({px:220,py: 150});
    const [movement, setMovement] = useState(false);

    useEffect(()=>{
        const canvas = canvasRef.current;
        if (null !== canvas) {
            canvas.width = 688 * 2;
            canvas.height = 484 * 2;
            canvas.style.width = '688px';
            canvas.style.height = '484px';
            const context: any = canvas.getContext('2d');
            context.lineCap = 'round';
            context.lineJoin = 'round'
            context.scale(2,2);
            contextRef.current = context;
            if(contextRef.current !== null){ 
                contextRef.current.fillStyle = '#FFFFFF';
                contextRef.current.fillRect(0, 0, 688, 484);
            }
        }
        setHideBottomMenu(false)
        if (drawState){
            var image = new Image();
            image.onload = function() {
                const canv = document.getElementById('drawcanvas') as HTMLCanvasElement;
                contextRef.current?.drawImage(image, 0, 0, canv.width/2, canv.height/2);
            };
            image.src = drawState;
        }
    }, [])
    
    useEffect(()=>{
      const canvas = canvasRef.current;
      if (null !== canvas) {
          const context: any = canvas.getContext('2d');
          context.lineWidth = erase ? 40 : lineWidth;
          context.strokeStyle = erase ? '#FFF' : color;
      }
      if(toolsMode === 'text' && textState !== 'none'){
          setModal(true)
      }
      else{
          setModal(false)
      }
    }, [color, erase, lineWidth, toolsMode])

    useEffect(()=>{
        if (cleanCanvas){
            const canvas = canvasRef.current;
                const context: any = canvas?.getContext('2d');
                if(canvas !== null){
                context.clearRect(0, 0, canvas.width, canvas.height);
                onUpdate({clean: cleanCanvas});
                }
        }
    }, [cleanCanvas]);

    useEffect(()=>{
        if(sharedData.clean){
            const canvas = canvasRef.current;
            const context: any = canvas?.getContext('2d');
            if(canvas !== null){
            context.clearRect(0, 0, canvas.width, canvas.height);
            }
        }
    }, [sharedData.clean]);

  useEffect(()=>{
    if (sharedData.curve && sharedData.curve[0]){
        if (sharedData.style && contextRef.current){
            contextRef.current.lineWidth = sharedData.style.lw;
            contextRef.current.strokeStyle = sharedData.style.cl;

            //STOP WHITE COLOR SHARED
            if(sharedData.style.cl !== '#FFF'){
                changeColor(sharedData.style.cl)
            };

            setLineWidth(sharedData.style.lw);
        }
        contextRef.current?.beginPath();
        contextRef.current?.moveTo( sharedData.curve[0][0] , sharedData.curve[0][1] );
        for(let i=1; i< sharedData.curve.length; i++){
            contextRef.current?.lineTo( sharedData.curve[i][0] , sharedData.curve[i][1] );
            contextRef.current?.stroke();
        }
        contextRef.current?.closePath();
    } }, [sharedData.curve, sharedData.style, contextRef])


  useEffect(()=>{
    if (sharedData.png){
        var image = new Image();
        image.onload = function() {
            const canv = document.getElementById('drawcanvas') as HTMLCanvasElement;
            contextRef.current?.drawImage(image, 0, 0, canv.width/2, canv.height/2);
        };
        image.src = sharedData.png;
        setDrawState(sharedData.png)
    }}, [sharedData.png, contextRef])

    useEffect(()=>{
        if (sharedData.position !== undefined){
            setPosition({...position, px: sharedData.position.px, py: sharedData.position.py})
        }}, [sharedData.position])

    useEffect(()=>{
        if (sharedData.johari !== undefined){
            setShowJohari(sharedData.johari)
        }
    }, [sharedData.johari]);

    // useEffect (() => {
    //     const png = takeshot();
    //     onUpdate({png: png})
    // },[drawState])

    const startLine = ({nativeEvent}:any)=>{
        const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent;          
        const positionX = (clientX)/zoom - position.px;
        const positionY = (clientY)/zoom - position.py;
        
        setStartPositions({...startPositions, startX : positionX, startY : positionY});
        contextRef.current?.beginPath();
        contextRef.current?.moveTo(positionX , positionY );
        if(panning){
            setIsDrawing(true)
        };
        curve = [];
        curve.push([positionX, positionY]);
        
        if(toolsMode === 'point'){
            pointIcon(positionX, positionY)
        }

        if(toolsMode=== 'text'){
            setModal(!modal)
            writeText(positionX, positionY)
        }
        
        if (canvasRef.current!= null && toolsMode !== 'point'){
            const im = convertCanvasToImage(canvasRef.current);
            setCanvasCache(im);
            /*
            createImageBitmap(canvasRef.current).then((response:ImageBitmap)=> { 
                setCanvasCache(response);
            });
            */
            
        }
        if (color === undefined){
            changeColor('#323232');
        }
    }

    const endLine = () => {
        setIsDrawing(false);
        contextRef.current?.save();
        contextRef.current?.closePath(); 
        const png = takeshot();
        onUpdate({curve: curve, png: png});
        setDrawState(png)
    }

    const takeshot = () => {
        const canv = document.getElementById('drawcanvas') as HTMLCanvasElement;
        if (canv != null){
            return canv.toDataURL();        
        }
        else{
            return null;
        }
    };

    const draw = ({nativeEvent}:any)=>{
        const canvas = canvasRef.current;
        if(canvas !== null){
            const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent;          
            const positionX = (clientX)/zoom - position.px;
            const positionY = (clientY)/zoom - position.py;
            if (isDrawing && toolsMode === 'paint'){
                contextRef.current?.lineTo(positionX, positionY);
                curve.push([positionX, positionY]);
                contextRef.current?.stroke();
            }
            if(toolsMode === 'shapes'){
                const startX = startPositions.startX;
                const startY = startPositions.startY;
                const width = positionX - startPositions.startX;
                const height = positionY - startPositions.startY;

                if(isDrawing){
                    switch(shapeState){
                        case 'square':
                            if(canvasCache){
                                contextRef.current?.clearRect(0, 0, canvas.width, canvas.height);
                                contextRef.current?.drawImage(canvasCache, 0, 0, canvas.width/2, canvas.height/2);
                                contextRef.current?.strokeRect(startX, startY, width, height);
                            }
                            break;
                        case 'circle':
                            contextRef.current?.clearRect(0, 0, canvas.width, canvas.height);
                            contextRef.current?.drawImage(canvasCache, 0, 0, canvas.width/2, canvas.height/2);
                            contextRef.current?.beginPath();
                            contextRef.current?.moveTo(startX, startY + (positionY-startY)/2);
                            contextRef.current?.bezierCurveTo(startX, startY, positionX, startY, positionX, startY + (positionY-startY)/2);
                            contextRef.current?.bezierCurveTo(positionX, positionY, startX, positionY, startX, startY + (positionY-startY)/2);
                            contextRef.current?.stroke();
                            break;
                        case 'triangle':
                            contextRef.current?.beginPath();
                            contextRef.current?.clearRect(0, 0, canvas.width, canvas.height);
                            contextRef.current?.drawImage(canvasCache, 0, 0, canvas.width/2, canvas.height/2);
                            contextRef.current?.moveTo(startX + (positionX - startX) / 2, startY);
                            contextRef.current?.lineTo(startX, positionY);
                            contextRef.current?.lineTo(positionX, positionY);
                            contextRef.current?.lineTo(startX + (positionX - startX) / 2, startY);
                            contextRef.current?.stroke();
                            contextRef.current?.closePath();
                            break;
                        case 'squareFill':
                            if(canvasCache){
                                contextRef.current?.clearRect(0, 0, canvas.width, canvas.height);
                                if(contextRef.current !== null){ 
                                    contextRef.current?.drawImage(canvasCache, 0, 0, canvas.width/2, canvas.height/2);
                                    contextRef.current.fillStyle = color;
                                    contextRef.current?.fillRect(startX, startY, width, height);
                                }
                            }
                            break;
                        case 'circleFill':
                            contextRef.current?.clearRect(0, 0, canvas.width, canvas.height);
                            if(contextRef.current !== null){ 
                                contextRef.current?.drawImage(canvasCache, 0, 0, canvas.width/2, canvas.height/2);
                                contextRef.current?.beginPath();
                                contextRef.current.fillStyle = color;
                                contextRef.current?.moveTo(startX, startY + (positionY-startY)/2);
                                contextRef.current?.bezierCurveTo(startX, startY, positionX, startY, positionX, startY + (positionY-startY)/2);
                                contextRef.current?.bezierCurveTo(positionX, positionY, startX, positionY, startX, startY + (positionY-startY)/2);
                                contextRef.current?.stroke();
                                contextRef.current?.fill();
                            }
                            break;
                        case 'triangleFill':
                            contextRef.current?.clearRect(0, 0, canvas.width, canvas.height);
                            if(contextRef.current){
                                contextRef.current?.beginPath();
                                contextRef.current?.drawImage(canvasCache, 0, 0, canvas.width/2, canvas.height/2);
                                contextRef.current?.moveTo(startX + (positionX - startX) / 2, startY);
                                contextRef.current?.lineTo(startX, positionY);
                                contextRef.current?.lineTo(positionX, positionY);
                                contextRef.current?.lineTo(startX + (positionX - startX) / 2, startY);
                                contextRef.current?.stroke();
                                contextRef.current?.closePath();
                                contextRef.current.fillStyle = color;
                                contextRef.current?.fill();
                            };
                            break;
                    }
                }
            }
            
        }
    }

    const pointIcon = (clientX:number, clientY:number) => {     
        const canvas = canvasRef.current;
        const context: any = canvas?.getContext('2d');
        let base_image = new Image();
        base_image.src = Arrow;
        base_image.onload= function() {
            context.drawImage(base_image, (clientX)/zoom - canvasLeft - 10, (clientY)/zoom - canvasTop - 10);
        }
    }

    const handleChange = (e:any) => setText({ ...text, [e.target.name]: e.target.value })

    const writeText = (positionX: number, positionY: number) => {       

        const canvas = canvasRef.current;
        const context: any = canvas?.getContext('2d');
        if(contextRef.current){
            contextRef.current.fillStyle = color;
        };
        if(textState === 'high'){
            context.font =  '40px sans-serif'
        }
        else if(textState === 'small'){
            context.font =  '20px sans-serif'
        }
        contextRef.current?.fillText(text.newText, positionX, positionY);
    }

    const startDrag = (e:any)=>{
        if(dragging > 0){
            setMovement(true);
            const nativeEvent = e.nativeEvent;
            const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent;
            setClickOffset({x: position.px - clientX, y: position.py - clientY});
        }
      }
    
      const drag = (e:any)=>{
        if(movement){
            const nativeEvent = e.nativeEvent;
            const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent
            setPosition({...position, px : clickOffset.x + clientX, py : clickOffset.y + clientY});
            onUpdate({position: position})
          }
      }
    
    const endDrag = ({nativeEvent}:any)=>{
        setMovement(false)
        nativeEvent.preventDefault();
    }

    const start = (e:any) => {
        startLine(e)
        startDrag(e)
    }

    const action = (e:any) => {
        draw(e)
        drag(e)
    }

    const end = (e:any) => {
        endLine()
        endDrag(e)
    }

    const canvasStyle : CSSProperties = {
        top: position.py,
        left: position.px,
        cursor: (dragging>0)?'grab':'auto'
    }

    const johariStyle : CSSProperties = {
        top: position.py + 26,
        left: position.px + 17,
        cursor: (dragging>0)?'grab':'auto'
    }

    return (
        <>
            {
                showJohari && 
                <div 
                    id="johariscreen"
                    onMouseDown={start}
                    onTouchStart={start}
                    onMouseUp={end}
                    onMouseOut={end}
                    onTouchEnd={end}
                    onMouseMove={action}
                    onTouchMove={action}
                    style={johariStyle}
                >
                    <img draggable="false" src={JohariImage} alt="johari-screen" />
                </div>
            }
            <canvas 
                id="drawcanvas"
                onMouseDown={start}
                onTouchStart={start}
                onMouseUp={end}
                onMouseOut={end}
                onTouchEnd={end}
                onMouseMove={action}
                onTouchMove={action}
                style={canvasStyle}
                ref={canvasRef}
            />

            {((textState === 'high' || textState === 'small') && showTextModal) && 
            <div className="canvas-form-text">
                <p>Escribe tu texto y pulsa en el lienzo blanco para colocarlo:</p>
                <Form.Control
                    onMouseDown={(e:any) => {e.stopPropagation()}}
                    className='input'
                    type='text'
                    name='newText'
                    value={text.newText}
                    autoComplete="off"
                    placeholder="Introduce aquí tu texto"
                    onChange={  handleChange }
                />
            </div>
            }

        </>
    );
};

export default MentappDraw;


// {
//     showJohari 
//      ?
//     <JohariScreen 
//         position={position}
//         dragging={dragging}
//         start={start}
//         end={end}
//         action={action}
//     />
//      :
//      <canvas 
//      id="drawcanvas"
//      onMouseDown={start}
//      onTouchStart={start}
//      onMouseUp={end}
//      onMouseOut={end}
//      onTouchEnd={end}
//      onMouseMove={action}
//      onTouchMove={action}
//      style={canvasStyle}
//      ref={canvasRef}
//  />
// }