import axios from "axios";

export default class UserService{
    constructor() {
        this.apiHandler = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            withCredentials: true
        })
    }

    getUser = (userId, token) => this.apiHandler.get(`api/users/${userId}/get-user`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })

    editUser = formData => this.apiHandler.put(`api/users/edit-user`, formData)

    editUserPlan = formData => this.apiHandler.put(`api/users/edit-user-plan`, formData)

    updateInAppPurchase = formData => this.apiHandler.put(`api/iap-webhook/`, formData)

    deleteUser = (userId, token) => this.apiHandler.delete(`api/users/${userId}/delete-user`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })


}