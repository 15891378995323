import {useState } from 'react';
import { useHistory } from 'react-router-dom'
import AuthService from '../../service/auth.service';

import { Form } from 'react-bootstrap'
import {  IonButton, IonLabel} from '@ionic/react';

import { RevealIcon, ArrowBack} from '../../assets/index';
import './signin.css'

export interface Props {
    signIn: any,
}

const SignUp : React.FC<Props> = ({signIn})  => {

    const history = useHistory();
    const authService = new AuthService();

    const [createAccount, setCreateAccount] = useState<any>({
        email: '',
        name: '',
        password: '',
        confirmPassword: '',
        passwordShown: false,
        checkedPrivacy: false,
    })

    const [error, setError] = useState<any>({
        active: '',
        errorMessage: ''
    })

    const handleChange = (e:any) => setCreateAccount({ ...createAccount, [e.target.name]: e.target.value })

    const handleSubmit = (e:any) => {
        e.preventDefault();

        if (createAccount.password !== createAccount.confirmPassword){
            setError({...error, active : 'password', errorMessage: 'La contraseña no coincide'})
        }
        else if(!createAccount.checkedPrivacy){
            setError({...error, active : 'password', errorMessage: 'Debes aceptar los términos y política de privacidad'})
            return
        }
        else{
            const {email, name, password} = createAccount
            authService
                .signIn({email, name, password})
                .then(() => {
                    setError({...error, active: '' , errorMessage: ''})
                    history.push('/verificar-email')
                })
                .catch(err => {
                    // console.error(err)
                    setError({...error, active : 'password', errorMessage: err.response.data.error})
                })
        }
        setTimeout(() => {
            setError({...error, active: '' , errorMessage: ''})
        }, 10000);
    };

    const handleChecked = (e:any) => {
        setCreateAccount({...createAccount, checkedPrivacy : !createAccount.checkedPrivacy})
    }

    const togglePasswordVisibility = () => {
        setCreateAccount({...createAccount, passwordShown : !createAccount.passwordShown})
    }

    return ( 
        <div className="login" scroll-y="false">
            <div className="session-form">
                <div className="signup">
                        <div className="back-icons">
                            <ArrowBack /><a href="/">Volver atrás</a>
                        </div>
                    <h1>Crear cuenta de terapeuta</h1>
                        <Form onSubmit={handleSubmit} className="signup-form">

                        <Form.Group controlId='email'>
                            <Form.Control 
                                className={error.active === 'email' ? 'error-input': ''}
                                type='text'
                                name='email'
                                value={createAccount.email}
                                placeholder="Correo electrónico"
                                onChange={ handleChange }
                                autoComplete="off"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId='name'>
                            <Form.Control 
                                type='text'
                                name='name'
                                value={createAccount.name} 
                                placeholder="Nombre y Apellidos / Empresa"
                                onChange={ handleChange }
                                autoComplete="off"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId='password'>
                            <Form.Control 
                                type={createAccount.passwordShown ? 'text' : 'password'}
                                name='password'
                                value={createAccount.password}
                                placeholder="Contraseña" 
                                onChange={ handleChange }
                                autoComplete="off"
                                required
                            />
                            <RevealIcon onClick={togglePasswordVisibility} />
                        </Form.Group>

                        <Form.Group controlId='confirmPassword'>
                            <Form.Control 
                                className={error.active === 'password' ? 'error-input': ''}
                                type={createAccount.passwordShown ? 'text' : 'password'}
                                name='confirmPassword'
                                value={createAccount.confirmPassword}
                                placeholder="Confirmar contraseña"
                                onChange={ handleChange }
                                autoComplete="off"
                                required
                            />
                            <RevealIcon onClick={togglePasswordVisibility} />
                        </Form.Group>
                        {error.active && <p className="error-message">{error.errorMessage}</p>}
                        <IonButton fill="clear" type="submit" className="button button-primary no-ripple">Crear cuenta</IonButton>
                        <div className="privacy-policy-checkbox">
                            <input
                                name="privacy"
                                type="checkbox"
                                onChange={handleChecked}
                            />
                            <IonLabel><p>Acepto <a href="/terminos">los términos de uso</a> y la <a href="/politica-privacidad">política de privacidad</a>.</p></IonLabel>
                        </div>
                        <div className="existing-account-container">
                            <p>¿Ya tienes una cuenta de terapeuta?</p><IonButton fill="clear" className="existing-account" href="/acceso">Accede</IonButton>
                        </div>
                    </Form>
                </div>
            </div>
        </div>

     );
}
 
export default SignUp;