import { useState, useContext, useEffect } from 'react';
import SessionService from '../../service/sessions.service';
import UIContext from '../../context/UIContext';
import UserContext from '../../context/UserContext';
import UserToolsContext from '../../context/UserToolsContext';
import NextSessionsCard from './next-session/NextSessionsCard';
import NextSessionForm from './next-session/NextSessionForm';
import Chrono from '../shared/chrono/chrono';
import { useIonRouter } from '@ionic/react';

import { IonHeader, IonText, IonButton, IonModal, IonLabel } from '@ionic/react';
import {Logo, MenuCollapsedIcon, Clock, MenuCloseIcon, LockIcon, LockIconOpen} from './../../assets/index'; 
import './topSessionBar.css';
export interface TopProps{
    endCall: any,
    sessionCode: any,
    sessionId: any,
    sessionUser: any,
    onSelectionChange: any,
    status:string,
    onStatusLock: any
    onUpdate: any
}

const TopSessionBar: React.FC<TopProps> = ({endCall, sessionCode, sessionId, sessionUser, onSelectionChange, status, onStatusLock, onUpdate}) => {

    const {setHideLeftMenu, setDashboardState, setHideToolsMenu, setHideBottomMenu, setIconActive, setIconState} = useContext(UIContext);
    const {cardState, drawState, sandboxState, dices, teddyMessage, teddyPos, teddyElementsArr, toysState, setSession} = useContext(UserToolsContext)
    const {userId, token} = useContext(UserContext);
    const sessionService = new SessionService()

    const [openMenu, setOpenMenu] = useState<any>(false);
    const [addNextSession, setAddNextSession] = useState<Boolean>(false)

    const [openModal, setOpenModal] = useState<any>({
        isOpen: false
    })

	const router = useIonRouter();
    const [state, setState] = useState<any>({
        date: '',
        time: '',
    });
    const [orderedNextSessionsByDate, setOrderedNextSessionsByDate] = useState<any>([]);
    const [counter, setCounter] = useState<Number>(0)

    useEffect(() => {
        sessionService
            .getUserSessions(userId, token)
            .then(res => {
                setNextSessions(res.data.userSessions)
            })
            .catch((err:any) => {
                console.error(err)
            }) 
    }, []);

    useEffect(() => {
        sessionService
            .getUserSessions(userId, token)
            .then(res => {
                setNextSessions(res.data.userSessions)
            })
            .catch((err:any) => {
                console.error(err)
            }) 
    }, [counter]);

    const actualDate = Date.now();
    const today = new Date(actualDate)
    let dayInMillis=24*3600000;

    const setNextSessions = (sessions:any) => {
        let futureSess:any = []; 
        sessions.forEach((session:any) => {
            let days1=Math.floor(today.getTime()/dayInMillis);
            let days2=Math.floor(new Date(session.date).getTime()/dayInMillis);
            if(days1 < days2){
                futureSess.push(session);
            }
        });
        const sortedFutureSessions = futureSess.sort((a:any, b:any) => (a.date > b.date) ? 1 : -1)
        setOrderedNextSessionsByDate(sortedFutureSessions)
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement>): void  => setState({ ...state, [e.currentTarget.name]: e.currentTarget.value });
    
    const updateSession = (newStatus:string = 'Finalizada') => {
        const sessionState = buildSessionState(newStatus)
        sessionService 
            .updateSession(sessionId, sessionState)
            .then((res:any) => {
                //console.log(res)
                setIconActive(false)
                setIconState('none')
                setHideToolsMenu(true)
                onSelectionChange('none')
                setDashboardState('session')
                onUpdate({state: newStatus})
                setSession({})
                router.push('/dashboard/',  'back')
            })
            .catch((err:any) => {
                console.error(err)
            })
    }

    const buildSessionState = (newStatus:string) => {
        const userToolsState = {
            "card" : {
                "cardState": cardState
            },
            "draw" : {
                "drawState": drawState
            },
            "dices" : {
                "dicesState": dices
            },
            "sandbox" : {
                "sandBoxState" : sandboxState
            },
            "teddy": {
                "position": teddyPos,
                "elements": teddyElementsArr,
                "message": teddyMessage,
            },
            "toy" : {
                "toyState" : toysState,
            },
            "status": newStatus
        }
        return userToolsState
    }
    
    const displayMenu = (display:any) => {
        setOpenMenu(display)
        setHideLeftMenu(display)
        setHideBottomMenu(display)
    }  
    const lockSession = ()=>{
        const sessionState = buildSessionState('Finalizada')
        sessionService 
            .updateSession(sessionId, sessionState)
            .then((res:any) => {
                onSelectionChange('none')
                onSelectionChange('')
                onStatusLock('Finalizada')
            })
            .catch((err:any) => {
                console.error(err)
            })
    }
    const unlockSession = ()=>{
        const sessionState = buildSessionState('Pendiente')
        sessionService 
            .updateSession(sessionId, sessionState)
            .then((res:any) => {
                onStatusLock('Pendiente')
            })
            .catch((err:any) => {
                console.error(err)
            })
    }

    const showEndSession = window.sessionStorage.getItem('userID') === sessionUser ? true : false
    
    const exitSession = (e:any)=>{
        if (window.confirm('¿Seguro que quieres salir de la sesión sin guardar?')){
            // updateSession()
            endCall()
            setIconActive(false)
            setIconState('none')
            setHideToolsMenu(true)
            onSelectionChange('none')
            setDashboardState('session')
            setSession({})
            router.push('/dashboard/',  'back')
            return true;
        } else {
            e.preventDefault()
        }
        return
    }

    const exitSessionUser = (e:any)=>{
        window.location.href = "live.mentapp.es";
    }

    return( 
        <> 
            {token !== null ?
            <div className="top-bar-container">
                <MenuCollapsedIcon className="collapsed-icon" onClick={() => {displayMenu(true)}}/>
                <div className={openMenu ? "next-session-menu open" : "next-session-menu hidden" }>
                    <div className="next-session-menu-container">
                        <IonHeader>
                            <MenuCloseIcon onClick={() => {displayMenu(false)}}/>
                        </IonHeader>
                        <div className="next-sessions-title">
                            <IonText>
                                <h1>Próximas Sesiones</h1>
                            </IonText>
                                <Clock className="clock-next-sessions"/>
                        </div>
                        <div className={!addNextSession ? "sessions-row" : "sessions-row decreased"}>
                            {orderedNextSessionsByDate &&
                                orderedNextSessionsByDate.map((sessions:any) => 
                                <NextSessionsCard
                                    key={ sessions.id }
                                    name={ sessions.name }
                                    date={ sessions.date }
                                    code={ sessions.code }
                                />
                            )
                            }
                        </div>
                        
                        <IonButton
                            fill="clear"
                            className={!addNextSession ? 'button button-primary': 'button-hidde'}
                            onClick={() => setAddNextSession(!addNextSession)}
                        >Añadir próxima sesión</IonButton>

                        {addNextSession 
                            && 
                            <NextSessionForm 
                                setAddNextSession={setAddNextSession}
                                setCounter={setCounter}
                                counter={counter}
                                />
                        }

                        <IonModal isOpen={openModal.isOpen}>
                        <div className="next-session-modal">
                            <div className="form-group date">
                                <IonLabel>Seleccionar fecha</IonLabel>
                                <input className='input'
                                    type='date'
                                    name='date'
                                    autoComplete="off"
                                    placeholder='Select date'
                                    onChange={ handleChange }
                                />
                            </div>
                            <div className="form-group time">
                                <IonLabel>Seleccionar hora</IonLabel>
                                <input className='input'
                                    type='time'
                                    name='time'
                                    autoComplete="off"
                                    placeholder='Select time'
                                    onChange={ handleChange }
                                />
                            </div>
                            <IonButton
                                fill="clear"
                                className={`button button-primary`}
                                onClick={() => setOpenModal({isOpen: false})}
                            >Guardar</IonButton>
                        </div>
                        </IonModal>
                    </div>
                </div>
                 
                <button onClick={showEndSession ? exitSession : exitSessionUser}>
                    <Logo className="logo-bar"/>
                </button>
                
                <div className="session-timing">
                    <p>Sesión {sessionCode}</p>
                    <Chrono />
                </div> 
                { showEndSession
                    &&
                    <IonButton   
                        fill="clear"
                        className={`button button-accent finish-session`}
                        onClick={async () => {
                            await endCall()
                            updateSession(status)
                        }}
                    >Finalizar terapia</IonButton>
                }
                { (status==='Finalizada' && showEndSession) && 
                <LockIcon
                    className="lock-icon" 
                    fill="#333333"
                    onClick={() => unlockSession()}
                />
                }
                { (status!=='Finalizada' && showEndSession) && 
                <LockIconOpen
                    className="lock-icon" 
                    fill="rgb(121, 206, 103)"
                    onClick={async () => {
                        await endCall()
                        lockSession()
                    }}
                />
                }
            </div>
        :
        <div className="top-bar-container-user">
            <a href="/" onClick={exitSession}>
                <Logo className="logo-bar"/>
            </a>
            <div className="session-timing">
                <p>Sesión {sessionCode}</p>
                <Chrono />
            </div> 
        </div>
        }
        </>
    )
}


export default TopSessionBar;