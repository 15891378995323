import {useEffect, useState, useContext} from 'react';
import Modal from './../../shared/modal/Modal'
import SessionService from '../../../service/sessions.service';
import UIContext from '../../../context/UIContext';
import UserContext from '../../../context/UserContext';
import ModalContext from '../../../context/ModalContext';
import { versionNumber } from '../../shared/version/VersionNumber';

import './NewSessions.css'
import {    
    CardsIcon,
    ClicksIcon,
    DiceIcon,
    SandboxIcon,
    PaintIcon,
    TeddyIcon,
    CopyIcon,
    VideocallIcon
} from '../../../assets/index';
import { Form } from 'react-bootstrap'
import { IonHeader, IonCol, IonRow, IonText, IonButton } from '@ionic/react';

const NewSession: React.FC= () => {

    const {userId} = useContext(UserContext)
    const {setDashboardState} = useContext(UIContext)
    const {modalMessage, showModal} = useContext(ModalContext);

    const [state, setState] = useState<any>({
        code: '',
        name: '',
        date: '',
        time: '',
        tools: [],
    });

    const [sessionLink, setSessionLink] = useState<any>({
        link:''
    });

    const [enterSession, setEnterSession] = useState<Boolean>(false);
    
    //GENERATE ALEATORY CODE
    const generateSessionCode = () => {
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result= ' ';
        for ( let i = 0; i < 6; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result
    }  
    const generateLink = () => {
        let sessionCode = generateSessionCode().trim();
        let url = `https://live.mentapp.es/session-panel/${sessionCode}`
        setState({...state, code: sessionCode})
        setSessionLink({...sessionLink, link : url});
    }
    
    useEffect(() => {
        generateLink()
    }, []);
    
    //COPY FUNCTIONALITY
    const copyToClipboard = () => {
        modalMessage({type: 'success', modalTitle: "Enlace copiado", modalSubtitle: "Su enlace se ha copiado con éxito"})
        const aux = document.createElement('input');
        aux.setAttribute("value", sessionLink.link);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }
    
    const addTool = (tool: any) => {
        if(!state.tools.includes(tool)){
            setState({...state, tools : [...state.tools, tool] })
        }
        else{
            let filteredTools = state.tools.filter((e:any) => e !== tool)
            setState({...state, tools : [...filteredTools] })
        }
    }
    
    const handleChange = (e: React.FormEvent<HTMLInputElement>): void  => setState({ ...state, [e.currentTarget.name]: e.currentTarget.value }); 
    
    const handleSubmit = (e:any) => {
        e.preventDefault();

        const sessionService = new SessionService();  
        const {code, name, date, time, tools} = state;

        sessionService
            .createSession({code, name, date, time, tools, userId})
            .then((res:any) => {setEnterSession(true)})
            .catch((err:any) => {
                console.error(err)
            })
    }

    return(
        <>
        <div className="new-session-container">
            <div className="new-session-container-mobile">
            <IonHeader>
                <h1>Nueva sesión</h1>
            </IonHeader>

                <Form onSubmit={handleSubmit} className='new-session-form'>
                {showModal && <Modal />}
                    <div className="sessions-form-codes">
                        <div className="session-form-codes-row">
                                <IonText>{state.code}</IonText>
                                <div className="form-group name">
                                    <Form.Control className='input'
                                        type='text'
                                        name='name'
                                        autoComplete="off"
                                        placeholder="Nombre sesión"
                                        onChange={ (e) =>  handleChange((e as any)) }
                                    />
                                </div>
                        </div>

                        <div className="session-form-codes-row">
                                <div className="form-group date">
                                    <p>Fecha</p>
                                    <Form.Control className='input'
                                        type='date'
                                        name='date'
                                        autoComplete="off"
                                        onChange={ (e) =>  handleChange((e as any)) }
                                    />
                                </div>
                                <div className="form-group time">
                                <p>Hora</p>
                                <Form.Control className='input'
                                    type='time'
                                    name='time'
                                    autoComplete="off"
                                    onChange={ (e) =>  handleChange((e as any)) }
                                    />
                                </div>
                        </div>
                    </div>
                    <div className="lineBreak"></div>
                        <div className="tools-container">
                            <IonText>De las siguientes herramientas selecciona las que deseas que pueda controlar el paciente.</IonText>
                            <IonRow>
                                <IonCol size="12">
                                            <CardsIcon className="iconButton"
                                                onClick={() => addTool('cards')}
                                                fill={state.tools.includes('cards') ? '#363636' : '#36363625' }

                                            />
                                            <PaintIcon className="iconButton"
                                                onClick={() => addTool('paint')}
                                                fill={state.tools.includes('paint') ? '#363636' : '#36363625' }
                                            />
                                            <DiceIcon className="iconButton"
                                                onClick={() => addTool('dice')}
                                                fill={state.tools.includes('dice') ? '#363636' : '#36363625' }
                                            />
                                            <ClicksIcon className="iconButton"
                                                onClick={() => addTool('clicks')}
                                                fill={state.tools.includes('clicks') ? '#363636' : '#36363625' }
                                            />
                                            <SandboxIcon className="iconButton"
                                                onClick={() => addTool('sandbox')}
                                                fill={state.tools.includes('sandbox') ? '#363636' : '#36363625' }
                                            />
                                            <TeddyIcon className="iconButton"
                                                onClick={() => addTool('teddy')}
                                                fill={state.tools.includes('teddy') ? '#363636' : '#36363625' }
                                            />
                                            <VideocallIcon className="iconButton"
                                                onClick={() => addTool('videocall')}
                                                fill={state.tools.includes('videocall') ? '#363636' : '#36363625' }
                                            />
                                </IonCol>
                            </IonRow>
                        </div>
                    <div className="lineBreak"></div>                                
                        <div className="session-link">
                            <div className="link-container">
                                <IonText>{sessionLink.link}</IonText>
                                <CopyIcon onClick={() => copyToClipboard()}/>
                            </div>
                                {!enterSession && 
                                <div className ="alert-if-disabled" onClick={() => modalMessage({type: 'warning', modalTitle: "No se puede acceder", modalSubtitle: "Debes guardar la sesión antes de entrar"})}></div>}
                                    <IonButton 
                                        disabled={!enterSession || !state.name || !state.date || !state.time} 
                                        fill="clear"
                                        className={`button button-accent enter-session`}
                                        href={`/session-panel/${state.code}`}
                                        >Entrar en la sesión
                                    </IonButton>
                        </div>
                    <div className="btn-footer-session">
                        <div className="btn">
                            <IonButton
                                disabled={!state.name || !state.date || !state.time} 
                                fill="clear"
                                type="submit"
                                className={`button button-primary`}
                                onClick={ () => modalMessage({type: 'success', modalTitle: "Sesión creada", modalSubtitle: "Tu sesión se ha creado correctamente"})}
                                >Guardar sesión</IonButton>
                        </div>
                        <div className="btn">
                                <IonButton
                                    fill="clear"
                                    className={`button button-tertiary`}
                                    onClick={() => setDashboardState('session')}
                                    >Cancelar</IonButton>
                        </div>
                    </div>
                    </Form>

                    <div className='version-code'>
                    <p>V. {versionNumber()}</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default NewSession;