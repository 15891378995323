import React, {useState} from 'react';
interface SandboxElementProps {
    element: any,
    onStartDrag: (e:any, index:number)=>void,
    onDrag: (e:any, index:number)=>void,
    onEndDrag: (e:any)=>void,
    onChangeType: (e:any, index:number, type:string)=>void
}
 
const SandboxElement: React.FC<SandboxElementProps> = ({element, onStartDrag, onDrag, onEndDrag, onChangeType}) => {

    const {id, image, pX, pY, z, type} = element
    const [lastTap, setLastTap] = useState(new Date().getTime());
    
    const style = {
        top: pY,
        left: pX,
        zIndex: z
    }

    const callStartDrag = (e:any)=>{
        e.stopPropagation()
        onStartDrag(e, id)
        setLastTap(new Date().getTime())
    }
    const callDrag = (e:any)=>{
        onDrag(e, id)
    }
    const callEndDrag = (e:any)=>{
        e.stopPropagation()
        onEndDrag(e)
        
        // hack to detect double short click
        const last = new Date().getTime() - lastTap
        if (last < 120 && last > 20) {
            changeType(e)
            setLastTap(new Date().getTime())
        }
    }
    const changeType = (e:any)=>{
        e.stopPropagation(); 
        const newType =  (type === 'small') ? 'large' : 'small'
        onChangeType(e, id, newType)
    }

    const removeElement = (e:any) =>{
        onChangeType(e, id, 'remove')
    }
    
    return ( 
            <div 
                className={'sandbox-image ' + ((type==='large')?'large':'')}
                style={style}
                onMouseDown={callStartDrag}
                onTouchStart={callStartDrag}
                onMouseMove={callDrag}
                onTouchMove={callDrag}
                onMouseUp={callEndDrag}
                onTouchEnd={callEndDrag}
                onMouseOut={callEndDrag}
                //onDoubleClick={changeType}
            >
                {element.image && <>
                <img src={image} alt='element' draggable='false' />
                <div className="remove-element" onClick={removeElement}>✕</div>
                </>}
            </div>
     );
}
 
export default SandboxElement;