import {useState, useContext} from 'react';
import UIContext from '../../../../context/UIContext';
import ToolsContext from '../../../../context/ToolsContext';
import AnimalsMode from './animalsMode/AnimalsMode';
import PersonsMode from './personsMode/PersonsMode';
import BuildingsMode from './buildingsMode/BuildingsMode';
import UrbanMode from './urbanMode/UrbanMode';
import NatureMode from './natureMode/NatureMode';
import PlantMode from './plantMode/PlantMode';
import TreeMode from './treeMode/TreeMode';

import { sandboxImages } from '../../../shared/sandbox-utils/SandboxUtils';

import {
    AnimalIcon,
    BuildingIcon,
    NatureIcon,
    PersonIcon,
    PlantIcon,
    TreeIcon,
    UrbanIcon} from '../../../../assets/index'; 

import './leftMenuSandbox.css'
interface LeftMenuSandboxProps {
    iconChange: any
}
 
const LeftMenuSandbox: React.FC<LeftMenuSandboxProps> = ({iconChange}) => {

    const [personMode, setPersonMode] = useState(false)
    const [animalMode, setAnimalMode] = useState(false)
    const [buildingMode, setBuildingMode] = useState(false)
    const [urbanMode, setUrbanMode] = useState(false)
    const [natureMode, setNatureMode] = useState(false)
    const [plantMode, setPlantMode] = useState(false)
    const [treeMode, setTreeMode] = useState(false)

    const {iconState, setIconState, setPanning} = useContext(UIContext)
    const {sandboxImage, setSandboxImage} = useContext(ToolsContext)
    const changeSandboxImage = (image:string) => {
        setPanning(true)
        setSandboxImage(image)
    }
    const changeSandboxState = (state:string) => {
        setIconState(state)
        iconChange(state)
        switch(state){
            case 'person':
                setPersonMode(!personMode)
                setAnimalMode(false)
                setBuildingMode(false)
                setUrbanMode(false)
                setNatureMode(false)
                setPlantMode(false)
                setTreeMode(false)
                return;
            case 'animal':
                setAnimalMode(!animalMode)
                setPersonMode(false)
                setBuildingMode(false)
                setUrbanMode(false)
                setNatureMode(false)
                setPlantMode(false)
                setTreeMode(false)
                return;
            case 'building':
                setBuildingMode(!buildingMode)
                setPersonMode(false)
                setAnimalMode(false)
                setUrbanMode(false)
                setNatureMode(false)
                setPlantMode(false)
                setTreeMode(false)
                return;
            case 'urban':
                setUrbanMode(!urbanMode)
                setPersonMode(false)
                setAnimalMode(false)
                setBuildingMode(false)
                setNatureMode(false)
                setPlantMode(false)
                setTreeMode(false)
                return;
            case 'nature':
                setNatureMode(!natureMode)
                setPersonMode(false)
                setAnimalMode(false)
                setBuildingMode(false)
                setUrbanMode(false)
                setPlantMode(false)
                setTreeMode(false)
                return;
            case 'plant':
                setPlantMode(!plantMode)
                setPersonMode(false)
                setAnimalMode(false)
                setBuildingMode(false)
                setUrbanMode(false)
                setNatureMode(false)
                setTreeMode(false)
                return;
            case 'tree':
                setTreeMode(!treeMode)
                setPersonMode(false)
                setAnimalMode(false)
                setBuildingMode(false)
                setUrbanMode(false)
                setNatureMode(false)
                setPlantMode(false)
                return;
        }
    }

    const sandboxElementClass = (state: any) => {
        if(sandboxImage.includes(state)){
            return 'selected-image active'
        } 
        else{
            return 'selected-image inactive'
        }
    }

    return ( 
        <>
        <div className="left-menu-container">
            <PersonIcon 
                 onClick={() => {changeSandboxState('person')}}
                 fill={iconState === 'person' ? '#79CE67' : '#363636'}
            />
                <PersonsMode personMode={personMode} personImages = {sandboxImages.persons} sandboxElementClass={sandboxElementClass} setSandboxImage={changeSandboxImage}/>
            <AnimalIcon 
                onClick={() => {changeSandboxState('animal')}}
                fill={iconState === 'animal' ? '#79CE67' : '#363636'}
           />
                <AnimalsMode animalMode={animalMode} animalImages = {sandboxImages.animals} sandboxElementClass={sandboxElementClass}setSandboxImage={changeSandboxImage}/>
           <BuildingIcon 
                onClick={() => {changeSandboxState('building')}}
                fill={iconState === 'building' ? '#79CE67' : '#363636'}
           />
                <BuildingsMode buildingMode={buildingMode} buildingImages = {sandboxImages.buildings} sandboxElementClass={sandboxElementClass} setSandboxImage={changeSandboxImage}/>
            <UrbanIcon 
                onClick={() => {changeSandboxState('urban')}}
                fill={iconState === 'urban' ? '#79CE67' : '#363636'}
            />  
                <UrbanMode urbanMode={urbanMode} urbanImages = {sandboxImages.urbanElements} sandboxElementClass={sandboxElementClass} setSandboxImage={changeSandboxImage}/>
           <NatureIcon 
                onClick={() => {changeSandboxState('nature')}}
                fill={iconState === 'nature' ? '#79CE67' : '#363636'}
           />
                <NatureMode natureMode={natureMode} natureImages = {sandboxImages.nature} sandboxElementClass={sandboxElementClass} setSandboxImage={changeSandboxImage}/>
           <PlantIcon 
                onClick={() => {changeSandboxState('plant')}}
                fill={iconState === 'plant' ? '#79CE67' : '#363636'}
           />
                <PlantMode plantMode={plantMode} plantImages = {sandboxImages.plants} sandboxElementClass={sandboxElementClass} setSandboxImage={changeSandboxImage}/>
           <TreeIcon 
                onClick={() => {changeSandboxState('tree')}}
                fill={iconState === 'tree' ? '#79CE67' : '#363636'}
           />
                <TreeMode treeMode={treeMode} treeImages = {sandboxImages.trees} sandboxElementClass={sandboxElementClass} setSandboxImage={changeSandboxImage}/>
        </div>
    </>
    );
}
 
export default LeftMenuSandbox;