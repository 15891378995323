import * as React from 'react';

interface TreeModeProps {
    treeMode: Boolean,
    treeImages: any,
    sandboxElementClass: any,
    setSandboxImage: any
}
 
const TreeMode: React.FC<TreeModeProps> = ({treeMode, treeImages, sandboxElementClass, setSandboxImage}) => {

    const {pine, tree, treeNoLeaves} = treeImages

    return ( 
        <div className={treeMode ? 'sandbox-menu expanded height-auto' : 'sandbox-menu collapsed'}>
            <div className="components">
                <div className="images">
                    <div className={sandboxElementClass(pine)}>
                        <img src={pine} 
                            alt="pine" 
                            draggable="false"
                            onClick={() => setSandboxImage(pine)}
                        />
                    </div>
                    <div className={sandboxElementClass(tree)}>
                        <img src={tree} 
                            alt="tree" 
                            draggable="false"
                            onClick={() => setSandboxImage(tree)}
                        />
                    </div>
                    <div className={sandboxElementClass(treeNoLeaves)}>
                        <img src={treeNoLeaves} 
                            alt="tree-no-leaves" 
                            draggable="false"
                            onClick={() => setSandboxImage(treeNoLeaves)}
                        />
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default TreeMode;