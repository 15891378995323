
import { io } from "socket.io-client";

const socket = io('https://socket.mentapp.es/', { transports: ['websocket'] });
const uid = Math.round(Math.random()*10000);
let gid = '';

export default class SocketService {
    
    setup = ( eventReceived, newGid )=>{
        gid = newGid;
        socket.emit('createUser', uid );
        socket.emit('joinSession', {uid, gid} );
        socket.on('event', data => {
            eventReceived(data);
        });
    }

    sendMessage = (key, payload) => {
        socket.emit(key, payload);
    }
    
}