import Card1 from  './../../../assets/img/cards/Card_1.jpg'
import Card2 from './../../../assets/img/cards/Card_2.jpg'
import Card3 from './../../../assets/img/cards/Card_3.jpg'
import Card4 from './../../../assets/img/cards/Card_4.jpg'
import Card5 from './../../../assets/img/cards/Card_5.jpg'
import Card6 from './../../../assets/img/cards/Card_6.jpg'
import Card7 from './../../../assets/img/cards/Card_7.jpg'
import Card8 from './../../../assets/img/cards/Card_8.jpg'
import Card9 from './../../../assets/img/cards/Card_9.jpg'
import Card10 from './../../../assets/img/cards/Card_10.jpg'
import Card11 from './../../../assets/img/cards/Card_11.jpg'
import Card12 from './../../../assets/img/cards/Card_12.jpg'
import Card13 from './../../../assets/img/cards/Card_13.jpg'
import Card14 from './../../../assets/img/cards/Card_14.jpg'
import Card15 from './../../../assets/img/cards/Card_15.jpg'
import Card16 from './../../../assets/img/cards/Card_16.jpg'
import Card17 from './../../../assets/img/cards/Card_17.jpg'
import Card18 from './../../../assets/img/cards/Card_18.jpg'
import Card19 from './../../../assets/img/cards/Card_19.jpg'
import Card20 from './../../../assets/img/cards/Card_20.jpg'
import Card21 from './../../../assets/img/cards/Card_21.jpg'
import Card22 from './../../../assets/img/cards/Card_22.jpg'
import Card23 from './../../../assets/img/cards/Card_23.jpg'
import Card24 from './../../../assets/img/cards/Card_24.jpg'

export const CARDS = [
    {id:0, sel: false, px: 0, py: 0, z: 1},
    {id:1, sel: false, px: 0, py: 0, z: 1},
    {id:2, sel: false, px: 0, py: 0, z: 1},
    {id:3, sel: false, px: 0, py: 0, z: 1},
    {id:4, sel: false, px: 0, py: 0, z: 1},
    {id:5, sel: false, px: 0, py: 0, z: 1},
    {id:6, sel: false, px: 0, py: 0, z: 1},
    {id:7, sel: false, px: 0, py: 0, z: 1},
    {id:8, sel: false, px: 0, py: 0, z: 1},
    {id:9, sel: false, px: 0, py: 0, z: 1},
    {id:10, sel: false, px: 0, py: 0, z: 1},
    {id:11, sel: false, px: 0, py: 0, z: 1},
    {id:12, sel: false, px: 0, py: 0, z: 1},
    {id:13, sel: false, px: 0, py: 0, z: 1},
    {id:14, sel: false, px: 0, py: 0, z: 1},
    {id:15, sel: false, px: 0, py: 0, z: 1},
    {id:16, sel: false, px: 0, py: 0, z: 1},
    {id:17, sel: false, px: 0, py: 0, z: 1},
    {id:18, sel: false, px: 0, py: 0, z: 1},
    {id:19, sel: false, px: 0, py: 0, z: 1},
    {id:20, sel: false, px: 0, py: 0, z: 1},
    {id:21, sel: false, px: 0, py: 0, z: 1},
    {id:22, sel: false, px: 0, py: 0, z: 1},
    {id:23, sel: false, px: 0, py: 0, z: 1},
]

export const CARDS_IMGS = [
    Card1,
    Card2,
    Card3,
    Card4,
    Card5,
    Card6,
    Card7,
    Card8,
    Card9,
    Card10,
    Card11,
    Card12,
    Card13,
    Card14,
    Card15,
    Card16,
    Card17,
    Card18,
    Card19,
    Card20,
    Card21,
    Card22,
    Card23,
    Card24,
]