import {useContext} from 'react';
import UserToolsContext from '../../../../context/UserToolsContext';

import {ArrowLeft, ArrowRight} from '../../../../assets/index'


interface TeddySelectConstructorProps {
    onUpdate: any
    teddyPart: string
}
 
const TeddySelectConstructor: React.FC<TeddySelectConstructorProps> = ({onUpdate, teddyPart}) => {

    const {teddyElementsArr, setTeddyElementsArr} = useContext(UserToolsContext)

    const moveTeddyPiece = (movement:any, piece:string) => {
        const elemnt = document.getElementById(piece);
        let scrollPosition = Math.round(getTranslateX(elemnt)/1024);
        if (elemnt!=null){
            switch(movement){
                case 'right':
                    if (scrollPosition<-5) scrollPosition = 1;
                    const p1 = Math.max(scrollPosition*1024 - 1024, -6144)
                    elemnt.style.transform = "translateX(" + p1 + "px)";
                    updateTeddyArr(movement, piece, p1, scrollPosition)
                    setTeddyElementsArr(teddyElementsArr)
                    onUpdate({teddy: teddyElementsArr})
                    return;
                case 'left':
                    if (scrollPosition>=0) scrollPosition = -7;
                    const p2 = Math.min(scrollPosition*1024 + 1024, 0)
                    elemnt.style.transform = "translateX(" + p2 + "px)";
                    updateTeddyArr(movement, piece, p2, scrollPosition)
                    setTeddyElementsArr(teddyElementsArr)
                    onUpdate({teddy: teddyElementsArr})
                    return;
            }
        }
    }

    const updateTeddyArr = (movement:any, piece:any, p:number, pos:number)  => {
        if(movement === 'right'){
            switch(piece){
                case 'teddy-ears':
                    if(teddyElementsArr[0].piece < 6){
                        teddyElementsArr[0].piece = pos
                        teddyElementsArr[0].pos = p
                    }
                    break;
                case 'teddy-face':
                    if(teddyElementsArr[1].piece < 6){
                        teddyElementsArr[1].piece = pos
                        teddyElementsArr[1].pos = p
                    }                    
                    break;
                case 'teddy-body':
                    if(teddyElementsArr[2].piece < 6){
                        teddyElementsArr[2].piece = pos
                        teddyElementsArr[2].pos = p
                    }                    
                    break;
                case 'teddy-paws':
                    if(teddyElementsArr[3].piece < 6){
                        teddyElementsArr[3].piece = pos
                        teddyElementsArr[3].pos = p
                    }                    
                    break;
            }           
        }
        else if(movement === 'left'){
            switch(piece){
                case 'teddy-ears':
                    teddyElementsArr[0].piece = pos
                    teddyElementsArr[0].pos = p
                    
                    break;
                case 'teddy-face':
                        teddyElementsArr[1].piece = pos
                        teddyElementsArr[1].pos = p                 
                    break;
                case 'teddy-body':
                        teddyElementsArr[2].piece = pos
                        teddyElementsArr[2].pos = p                    
                    break;
                case 'teddy-paws':
                        teddyElementsArr[3].piece = pos
                        teddyElementsArr[3].pos = p
                                     
                    break;
            }
        }
    }

    const getTranslateX = (element:any) => {
        var style = window.getComputedStyle(element);
        var matrix = new WebKitCSSMatrix(style.transform);
        return matrix.m41;
    }

    const teddyPartsMenu = (teddyPart:string) => {
        if(teddyPart === 'ears'){
            return  <div className="teddy-select-parts">
            <ArrowLeft onClick={() => moveTeddyPiece('left', 'teddy-ears')}/>
            <ArrowRight onClick={() => moveTeddyPiece('right','teddy-ears')}/>
        </div>
        }
        if(teddyPart === 'face'){
            return <div className="teddy-select-parts">
            <ArrowLeft onClick={() => moveTeddyPiece( 'left', 'teddy-face')} />
            <ArrowRight onClick={() => moveTeddyPiece('right', 'teddy-face')}/>
        </div>
        }
        if(teddyPart === 'body'){
            return  <div className="teddy-select-parts">
            <ArrowLeft onClick={() => moveTeddyPiece( 'left', 'teddy-body')}/>
            <ArrowRight onClick={() => moveTeddyPiece('right',  'teddy-body')}/>
        </div>
        }
        if(teddyPart === 'paws'){
            return <div className="teddy-select-parts">
            <ArrowLeft onClick={() => moveTeddyPiece('left', 'teddy-paws')}/>
            <ArrowRight onClick={() => moveTeddyPiece('right', 'teddy-paws')}/>
        </div> 
        }
    }

    return ( 
        <div className={`teddy-select-constructor ${teddyPart}`}>
            {teddyPartsMenu(teddyPart)}
        </div>
     );
}
 
export default TeddySelectConstructor;