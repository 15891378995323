import {useState} from 'react';
import { calculateHour } from '../../shared/dates-utils/SessionsCardsUtil';
import { IonRow, IonCol} from '@ionic/react';

import { CopyIcon } from '../../../assets';

export interface NextSessionsCardProps {
    name: any,
    date: any,
    code: string
}
 
const NextSessionsCard: React.FC<NextSessionsCardProps> = ({name, date, code}) => {

    const hour = calculateHour(date)
    const newDate = new Date(date)

    const sessionLink = `https://live.mentapp.es/session-panel/${code}`;

    const [showCopyLinkMessage, setShowCopyLinkMessage] = useState(false)

    const copyToClipboard = () => {
        setShowCopyLinkMessage(true);
        const aux = document.createElement('input');
        aux.setAttribute("value", sessionLink);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        setTimeout(() => {
            setShowCopyLinkMessage(false);
        }, 3000);
    }

    return (

            <IonRow>
                <IonCol size="6"><p>{newDate.getDate()}/{newDate.getMonth()+1}/{newDate.getFullYear()}</p></IonCol>
                <IonCol size="6"><p>{hour}</p></IonCol>
                <IonCol size="12"><p className="session-name">{name}</p></IonCol>
                <IonCol size="12">
                        <div className="next-session-link-url">
                            <p>{`https://live.mentapp.es/session-panel/${code}`}</p>
                            <CopyIcon className="copy-icon" onClick={() => copyToClipboard()}/>
                        </div>
                        {showCopyLinkMessage && <p className="copy-message">Copiado</p>}
                </IonCol>
               
            </IonRow>
     );
}
 
export default NextSessionsCard;