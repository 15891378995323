import React from 'react';
import { calculateDate, calculateHour } from '../../shared/dates-utils/SessionsCardsUtil';

import { BinIcon } from './../../../assets/index'; 

interface UserHistoryCardProps {
    id: any,
    code: any,
    name: any,
    date: any,
    deleteSession: any,
}
 
const UserHistoryCard: React.FC<UserHistoryCardProps> = ({id, code, name, date, deleteSession}) => {

    const formatedDate = calculateDate(date)
    const hour = calculateHour(date)

    return ( 
        <>
            <div className="session-row">
                <a href={`/session-panel/${code}`} >
                    <div className="desktop-history">
                        <div className="date"><p>{formatedDate}</p></div>
                        <div className="hour"><p>{hour}</p></div>
                    </div>
                    <div className="code"><p>{code}</p></div>
                    <div className="name"><p>{name}</p></div>
                </a>
                <BinIcon onClick={() => deleteSession(id)}/>
            </div>
        </>
     );
}
 
export default UserHistoryCard;