import {useState, useContext, useEffect} from 'react';
import { useHistory } from 'react-router';
import UserService from '../../../service/users.service';
import UIContext from '../../../context/UIContext';
import UserContext from '../../../context/UserContext';
import ModalContext from '../../../context/ModalContext';
import { versionNumber } from '../../shared/version/VersionNumber';

import Modal from '../../shared/modal/Modal';

import { IonHeader, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButton, IonRouterLink} from '@ionic/react';
import { Form } from 'react-bootstrap'
import './account.css'
export interface AccountProps {
    user: any
    signIn: any
}

const Account: React.FC<AccountProps> = ({user, signIn}) => {
    
    const userService = new UserService();
    const {setDashboardState} = useContext(UIContext);
    const {userId, isUnsubscribe} = useContext(UserContext);
    const {modalMessage, modalContain, showModal, setShowModal} = useContext(ModalContext);
    const [changeProfileInfo, setChangeProfileInfo] = useState<any>({
        email: user?.email,
        name: user?.name,
        password: user?.password,
        id: user?.id
    })
    const [changePassword, setChangePassword] = useState<any>({
        active: false,
        newPassword: ''
    })
    const [errorMessage, setErrorMessage] = useState<Boolean>(false)

    const history = useHistory();

    const handleChange = (e:any) => setChangeProfileInfo({ ...changeProfileInfo, [e.target.name]: e.target.value })
    const handleChangeNewPassword = (e:any) => setChangePassword({ ...changePassword, [e.target.name]: e.target.value })
    const handleSubmit = (e:any) => {
        e.preventDefault();
        const {email, name, password, id} = changeProfileInfo
        if(changePassword.newPassword === password){ 
            userService
                .editUser({email, name, password, id})
                .then(res => {
                    modalMessage({...modalContain, type: 'success', modalTitle: "Contraseña cambiada", modalSubtitle: "Contraseña cambiada con éxito"})
                    setShowModal(true)
                    setChangePassword({...changePassword, active: !changePassword.active, newPassword: ''})
                })
                .catch(err => console.error(err))
        }
        else{
            setErrorMessage(true)
            setTimeout(() => {
                setErrorMessage(false)
            }, 3000);
        }
    }

    
    const checkPlan = (plan:any) => {
        if(plan === 'free' || plan === null){
            return 'Plan Gratuito 30 días'
        }
        else if(plan === 'year'){
            return 'Mentapp PRO Anual'
        }
        else if(plan === 'month'){
            return 'Mentapp PRO Mensual'
        }
    }

    const checkPlanPrice = (subscription:any) => {
        console.log(subscription)
        if(subscription === 'free' || subscription === null){
            return 'Gratis'
        }
        else if(subscription === 'month'){
            return '15,00€/mes'
        }
        else if(subscription === 'year'){
            return '144,00€/año'
        }
    }

    const unsubscribeModal = () => {
        modalMessage({type: 'unsubscribe', modalTitle: "Darse de baja", modalSubtitle: "¿Está seguro de que desea darse de baja? Perderás información de las sesiones guardadas."})
    }

    useEffect(() => {
        if(isUnsubscribe){
            userService
                .deleteUser(userId)
                .then(res => {
                    console.log(res)
                    sessionStorage.clear();
                    modalMessage({type: 'success-unsubscribe', modalTitle: "Baja gestionada correctamente", modalSubtitle: "Gracias por utilizar Mentapp. Nos vemos pronto"})
                    setTimeout(()=>{
                        signIn('', '', false, '')
                        history.push('/')
                    },2000);
                })
                .catch(err => console.error(err))
        }
    }, [isUnsubscribe])

    function padTo2Digits(num:any) {
        return num.toString().padStart(2, '0');
    }
      
    function formatDate(date:any) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    const sendForm = (e:any) => {
        if(changePassword.newPassword.length > 0){
            handleSubmit(e)
        }
        else{
            setChangePassword({...changePassword, active: false})
        }
    }

    return ( 
        <>
        <div className="account-content">
            {showModal && <Modal />}
            <IonHeader>
                <h1>Mi cuenta</h1>
            </IonHeader>
                <Form onSubmit={handleSubmit}>
                <div className={`profile ${changePassword.active}`}>
                    <div className='profile-header'>
                        <h3>Perfil</h3>
                        <IonButton
                            fill="clear"
                            className={`button button-secondary`}
                            onClick={(e) => {
                                !changePassword.active
                                    ?
                                setChangePassword({...changePassword, active: true})
                                    :
                                sendForm(e)
                                }}
                        >{!changePassword.active ? "Cambiar contraseña" : "Hecho"}</IonButton>
                    </div>
                        <div className="form-group email">
                            <h4>Email</h4>
                            <p>{user?.email}</p>
                        </div>
                        <div className="form-group email">
                            <h4>Nombre y apellidos</h4>
                            <p>{user?.name}</p>
                        </div>
                        <div className="form-group email input-group">
                            <h4>{!changePassword.active ? 'Contraseña' : 'Nueva contraseña'}</h4>
                            <Form.Control className='input'
                                type='password'
                                name='password'
                                autoComplete="off"
                                placeholder="***********"
                                onChange={ handleChange }
                            />
                        </div>
                        {changePassword.active
                            &&
                        <div className="form-group email input-group">
                            <h4>Repetir contraseña</h4>
                            <Form.Control className='input'
                                type='password'
                                name='newPassword'
                                autoComplete="off"
                                placeholder="***********"
                                onChange={ handleChangeNewPassword }
                            />
                            {errorMessage && <p className='error-message'>Las contraseñas no coinciden</p>}
                        </div>
                        }
                </div>
                <div className="plan">
                    <div className='plan-header'>
                        <h3>Detalles suscripcion</h3>
                        <div className="btn">
                            <IonButton
                                fill="clear"
                                className={`button button-secondary`}
                                onClick={() => unsubscribeModal()}
                            >Darme de baja</IonButton>
                            {
                                (user?.subscription === 'free'  || user?.subscription === null)
                                &&
                                <IonRouterLink routerLink="/account-plan">
                                    <IonButton
                                        fill="clear"
                                        className={`button button-accent`}
                                    >Cambiar Plan</IonButton>
                                </IonRouterLink>    
                            }
                        </div>
                    </div>
                    <div>
                        <div className='plan-content-info first'>
                            <h4>Plan</h4>
                            <p>{checkPlan(user?.subscription)}</p>
                        </div>
                        <div className='plan-content-info'>
                            <h4>Precio</h4>
                            <p>{checkPlanPrice(user?.subscription)}</p>
                        </div>
                        <div className='plan-content-info'>
                            <h4>Fecha de alta</h4>
                            <p>{formatDate(new Date(user?.dateStart))}</p>
                        </div>
                        <div className='plan-content-info'>
                            <h4>Próxima renovación</h4>
                            <p>{formatDate(new Date(user?.dateEnd))}</p>
                        </div>
                    </div>
                </div>
            </Form>
            <div className='version-code'>
                <p>Versión {versionNumber()}</p>
            </div>
        </div>
        </>
     );
}
 
export default Account;