import {useRef} from 'react';
import { IonContent } from '@ionic/react';
interface AnimalsModeProps {
    animalMode: Boolean,
    animalImages: any,
    sandboxElementClass: any,
    setSandboxImage: any
}
 
const AnimalsMode: React.FC<AnimalsModeProps> = ({animalMode, animalImages, sandboxElementClass, setSandboxImage}) => {

    const {bird, butterfly, dogcat, hummingbird, penguin, pig} = animalImages

    const sandboxScroll = useRef<HTMLIonContentElement>(null);

    const scrollBy = (amount:number) => {
        if(sandboxScroll.current !== null){
            sandboxScroll.current.scrollByPoint(amount, amount, 1000)
        }
    }

    return ( 
        <>
        <IonContent className={animalMode ? 'sandbox-menu expanded animals' : 'sandbox-menu collapsed'} ref={sandboxScroll}>
            <div className="top-arrow" onClick={()=>scrollBy(-600)}>&raquo;</div>
            <div className="bottom-arrow" onClick={()=>scrollBy(600)}>&raquo;</div>
            <div className={`components ${animalMode}`}>
                <div className="images">
                    <div className={sandboxElementClass(bird)}>
                        <img src={bird} 
                            alt="bird" 
                            draggable="false"
                            onClick={() => setSandboxImage(bird)}
                        />
                    </div>
                    <div className={sandboxElementClass(butterfly)}>
                        <img src={butterfly} 
                            alt="butterfly" 
                            draggable="false"
                            onClick={() => setSandboxImage(butterfly)}
                        />
                    </div>
                    <div className={sandboxElementClass(dogcat)}>
                        <img src={dogcat} 
                            alt="dogcat" 
                            draggable="false"
                            onClick={() => setSandboxImage(dogcat)}
                        />
                    </div>
                    <div className={sandboxElementClass(hummingbird)}>
                        <img src={hummingbird} 
                            alt="hummingbird" 
                            draggable="false"
                            onClick={() => setSandboxImage(hummingbird)}
                        />
                    </div>
                    <div className={sandboxElementClass(penguin)}>
                        <img src={penguin} 
                            alt="penguin" 
                            draggable="false"
                            onClick={() => setSandboxImage(penguin)}
                        />
                    </div>
                    <div className={sandboxElementClass(pig)}>
                        <img src={pig} 
                            alt="pig" 
                            draggable="false"
                            onClick={() => setSandboxImage(pig)}
                        />
                    </div>
                </div>
            </div>
        </IonContent>
        </>
     );
}
 
export default AnimalsMode;