import * as React from 'react';

interface UrbanModeProps {
    urbanMode: Boolean,
    urbanImages: any,
    sandboxElementClass: any,
    setSandboxImage: any
}
 
const UrbanMode: React.FC<UrbanModeProps> = ({urbanMode, urbanImages, sandboxElementClass, setSandboxImage}) => {

    const {ambulance, bridge, car} = urbanImages

    return ( 
        <div className={urbanMode ? 'sandbox-menu expanded height-auto' : 'sandbox-menu collapsed'}>
            <div className="components">
                <div className="images">
                    <div className={sandboxElementClass(ambulance)}>
                        <img src={ambulance} 
                            alt="ambulance" 
                            draggable="false"
                            onClick={() => setSandboxImage(ambulance)}
                        />
                    </div>
                    <div className={sandboxElementClass(bridge)}>
                        <img src={bridge} 
                            alt="bridge" 
                            draggable="false"
                            onClick={() => setSandboxImage(bridge)}
                        />
                    </div>
                    <div className={sandboxElementClass(car)}>
                        <img src={car} 
                            alt="car" 
                            draggable="false"
                            onClick={() => setSandboxImage(car)}
                        />
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default UrbanMode;