import { useContext } from 'react';
import ToolsContext from '../../../../context/ToolsContext';
import UIContext from '../../../../context/UIContext';
import {SquareIcon, CircleIcon, TriangleIcon, SquareIconFill, CircleIconFill, TriangleIconFill} from '../../../../assets/index'; 
import './shapesModes.css'

export interface ShapesModesProps {
    shapeModes : Boolean;
    setShapeModes: any;
}
 
const ShapesModes: React.FC<ShapesModesProps> = ({ shapeModes, setShapeModes }) => {

    const {setShapeState, setErase} = useContext(ToolsContext)
    const {setIconState} = useContext(UIContext)

    const changeShape = (shape:string) => {
        setIconState(shape)
        setShapeModes(!shapeModes)
        setShapeState(shape)
        setErase(false) 
    }

    return ( 
        <div className={shapeModes ? 'shapes-modes-expanded' : 'shapes-modes-collapsed'}>
            <SquareIcon 
                onClick={() => {changeShape('square')}}
                fill='#363636'
            />
            <CircleIcon 
                onClick={() => {changeShape('circle')}}
                fill='#363636'
            />
            <TriangleIcon 
                onClick={() => {changeShape('triangle')}}
                fill='#363636'
            />
            <SquareIconFill 
                onClick={() => {changeShape('squareFill')}}
                fill='#363636'
            />
            <CircleIconFill 
                onClick={() => {changeShape('circleFill')}}
                fill='#363636'
            />
            <TriangleIconFill 
                onClick={() => {changeShape('triangleFill')}}
                fill='#363636'
            />
        </div>
     );
}
 
export default ShapesModes;