import {useContext} from 'react';
import UIContext from '../../../../context/UIContext';
import ToolsContext from '../../../../context/ToolsContext';

import ToySelectComponent from './ToySelectComponent';
import ToyColorSelect from './ToyColorSelect';

import './leftMenuToy.css'
import { HairButton, PantsButton, StairsIcon, ToyIcon, TshirtButton, ColorIcon, NeutralFace } from '../../../../assets';
import UserToolsContext from '../../../../context/UserToolsContext';

interface LeftMenuToyProps {
    onUpdate:any,
    sharedData: any
}
 
const LeftMenuToy: React.FC<LeftMenuToyProps> = ({ onUpdate, sharedData }) => {

    const {iconState, changeToyIcon, toyIconActive, hairIconActive, thirtIconActive, pantsIconActive, faceIconActive, skinIconActive, setIconState} = useContext(UIContext)
    const { changeToyTexture, skinColor, setChangeToyTexture, setSkinColor} = useContext(ToolsContext)
    const {stair, setStair} = useContext(UserToolsContext)

    const changeToyState = (mode:string) => {
        setIconState(mode)
        onUpdate(mode)
        changeToyIcon(mode)
    }

    const changeStair = () => {
        const newStair = !stair;
        setStair(newStair);
        onUpdate({stair:newStair});
    }

    return ( 
        <>
            <div className="left-menu-container">
                <ToyIcon 
                    onClick={() => changeToyState('toyicon')}
                    fill={toyIconActive ? '#79CE67' : '#363636'}
                />
                    {toyIconActive && <ToySelectComponent onUpdate={onUpdate}/>}
                <StairsIcon 
                    onClick={() => {
                        changeStair()
                    }}
                  fill={stair ? '#79CE67' : '#363636'}
                  className='stairs-icon'
                />
                <HairButton 
                     onClick={() => {
                         changeToyState('hair')
                         setChangeToyTexture({...changeToyTexture, part: 'hair'})
                        }}
                     fill={hairIconActive ? '#79CE67' : '#363636'}
                />
                <TshirtButton 
                     onClick={() => {
                         changeToyState('tshirt')
                         setChangeToyTexture({...changeToyTexture, part: 'body'})
                        }}
                     fill={thirtIconActive ? '#79CE67' : '#363636'}
                />
                <PantsButton 
                     onClick={() => {
                         changeToyState('pants')
                         setChangeToyTexture({...changeToyTexture, part: 'feet'})
                        }}
                     fill={pantsIconActive ? '#79CE67' : '#363636'}
                />
                <NeutralFace 
                        onClick={() => {
                        changeToyState('face')
                        setChangeToyTexture({...changeToyTexture, part: 'skin'})
                        }}
                    fill={faceIconActive ? '#79CE67' : '#363636'}
                />
                <ColorIcon 
                     onClick={() => {
                         changeToyState('skin')
                         setChangeToyTexture({...changeToyTexture, part: 'skin'})
                        }}
                     fill={skinColor}
                />
                    {
                        ((hairIconActive || thirtIconActive || pantsIconActive || faceIconActive || skinIconActive) && (iconState !== 'stairs' && iconState !== 'toyicon'))
                        &&
                        <ToyColorSelect skinColor={skinColor} setSkinColor={setSkinColor} iconState={iconState} onUpdate={onUpdate} sharedData={sharedData}/>
                    }
            </div>
        </>
     );
}
 
export default LeftMenuToy;