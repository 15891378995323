import {useContext, useState} from 'react';
import UIContext from '../../context/UIContext';
import ToolsContext from '../../context/ToolsContext';
import {ToolsTherapistMenu, CardsIcon, ClicksIcon, DiceIcon, PaintIcon, SandboxIcon, TeddyIcon, VideocallIcon} from '../../assets/index';
import UserContext from '../../context/UserContext';


interface PatientsBottomToolsMenuProps {
    tools: any[]
    onSelectionChange: any,
    showMenus: any
}
 
const PatientsBottomToolsMenu: React.FC<PatientsBottomToolsMenuProps> = ({tools, onSelectionChange, showMenus}) => {

    const {
        hideBottomMenu,
        iconActive, 
        modeState,
        showToyPannel,
        setHideToolsMenu,
        setIconActive,
        setIconState, 
        setShowToyPannel} = useContext(UIContext);
        const {token} = useContext(UserContext);
    const { setShowSelectCard, setShowTeddyTool, setShowClicTool} = useContext(ToolsContext)

    const changeMode = (mode:any) => {
        onSelectionChange(mode)
        setIconState(mode)
    }

    const [openMenu, setOpenMenu] = useState(true)



    return ( 
        <div className={!hideBottomMenu ? 'tools-menu-container' : 'tools-menu-container-none'}>
        <div className={openMenu ? 'tools-menu-icon-expanded' : 'tools-menu-icon-collapsed'}>
            <ToolsTherapistMenu 
                fill={!iconActive ? '#79CE67' : '#363636'}
                onClick={() => {
                    setOpenMenu(!openMenu)
                    setIconActive(!iconActive)
                }}
            />
        </div>
        <div className={openMenu ? 'tools-menu-container expanded' : 'tools-menu-container collapsed'}>
           {(tools?.includes('cards') || token !== null)
                && 
            <CardsIcon 
                onClick={() => {
                    changeMode('card');
                    setHideToolsMenu(true)
                    setShowSelectCard(true)
                    showMenus(true, 'cards', false)
                }}
                fill={modeState === 'card' ? '#79CE67' : '#363636'}
            />
            }
            {(tools?.includes('paint') || token !== null)
                &&
            <PaintIcon 
                onClick={() => {
                    changeMode('draw');
                    setHideToolsMenu(false)
                }}
                fill={modeState === 'draw' ? '#79CE67' : '#363636'}
            />
            }
            {(tools?.includes('dice') || token !== null)
                &&
            <DiceIcon 
                onClick={() => {
                    setHideToolsMenu(true)
                    changeMode('dice');
                }}
                fill={modeState === 'dice' ? '#79CE67' : '#363636'}
            />
            }
            {(tools?.includes('clicks') || token !== null)
                &&
            <ClicksIcon 
                onClick={() => {
                    changeMode('toy');
                    setShowClicTool(true);
                    setShowToyPannel(!showToyPannel)
                    setHideToolsMenu(false)
                }}
                fill={modeState === 'toy' ? '#79CE67' : '#363636'}
            />
            }
            {(tools?.includes('sandbox') || token !== null)
                &&
            <SandboxIcon 
                onClick={() => {
                    changeMode('sandbox');
                    setHideToolsMenu(false)
                }}
                fill={modeState === 'sandbox' ? '#79CE67' : '#363636'}
            />
            }
            {(tools?.includes('teddy') || token !== null)
                &&
            <TeddyIcon 
                onClick={() => {
                    changeMode('teddy');
                    setHideToolsMenu(true)
                    setShowTeddyTool(true)
                    showMenus(true, 'teddy', false)

                }}
                fill={modeState === 'teddy' ? '#79CE67' : '#363636'}
            />
            }
            {(tools?.includes('videocall') || token !== null)
                &&
            <VideocallIcon 
                onClick={() => {
                    changeMode('videocall');
                    setHideToolsMenu(true)
                    showMenus(true, 'videocall', false)
                }}
                fill={modeState === 'videocall' ? '#79CE67' : '#363636'}
            />
            }
        </div>
    </div>
     );
}
 
export default PatientsBottomToolsMenu;