import React, {useState, useRef, useEffect, useContext, Suspense, useLayoutEffect} from 'react';
import UserToolsContext from '../../../../../context/UserToolsContext';
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import Playmobil from './Playmobil'
import Family from './Family'
import './Mentapp3D.css';
import { Line, RGBA_ASTC_10x10_Format, TextureLoader, Vector3 } from 'three';
import { LineSegmentsGeometry } from 'three/examples/jsm/lines/LineSegmentsGeometry';
import { Plane, Box } from '@react-three/drei';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
//import { OrbitControls } from 'three-orbitcontrols-ts';
import UIContext from '../../../../../context/UIContext';
import ToolsContext from '../../../../../context/ToolsContext';

interface SocketProps {
    onUpdate:(cards:any) => void,
    sharedData: any,
    showMenus: any
}
let controls:any;

const CameraController = (props:any) => {
    const { camera, gl } = useThree();


    useEffect( () => {
            controls = new OrbitControls(camera, gl.domElement);
            controls.target = new Vector3(0, 0, -16);
            controls.minDistance = 0;
            controls.maxDistance = 20;
            controls.maxPolarAngle = 1.9;
            controls.keys = {
                LEFT: 'ArrowLeft',
                UP: 'ArrowUp',
                RIGHT: 'ArrowRight',
                BOTTOM: 'ArrowDown'
            }
            controls.enabled = props.enabled;
            controls.addEventListener( 'change', changedControls );
            return () => {
                controls.dispose();
            };
        },
        [camera, gl]
    );
    
    useEffect(
        () => {
            controls.enabled = props.enabled;
        },[ props.enabled])
    
    useEffect(
        () => {
            controls.object.position.set(props.orbit.p.x, props.orbit.p.y, props.orbit.p.z);
            controls.object.rotation.set(props.orbit.r.x, props.orbit.r.y, props.orbit.r.z);
            //controls.object.position = new Vector3(props.orbit.p.x, props.orbit.p.y, props.orbit.p.z);
        },[ props.orbit])
    const changedControls = (data:any) => {
        const p = data.target.object.position;
        const r = data.target.object.rotation;
        const orbit = {orbit:{p:{x:p.x, y:p.y, z:p.z}, r:{x:r.x, y:r.y, z:r.z}}};
        props.onUpdate(orbit)
    }
    return null;
}

const Mentapp3D: React.FC<SocketProps> = (props) => {
    const {toysState, setToysState, stair, setStair} = useContext(UserToolsContext) 
    const {changeToyTexture} = useContext(ToolsContext)
    const {panning} = useContext(UIContext)
    const [move, setMove] = useState({drag:false, x:0, y:0});

    const [orbit,setOrbit] = useState({p:{x:0, y:0, z:0}, r:{x:0, y:0, z:0}})
    
    // const [isDragging, setIsDragging] = useState(-1);
    // const [clickOffset, setClickOffset] = useState({x:0,y:0});
    // const [lastSentEvent, setLastSentEvent] = useState(new Date().getTime());

    const familyEls = [useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)];

    useEffect(()=>{
        console.log(props.sharedData)
        if (props.sharedData.toys !== undefined){
            // setToys(props.sharedData.toys)
            setToysState(props.sharedData.toys)
        }
        if (props.sharedData.orbit !== undefined){
            setOrbit(props.sharedData.orbit)
        }
    }, [props.sharedData]);
    
    useEffect(()=>{
        if (props.sharedData.stair !== undefined){
            setStair(props.sharedData.stair)
        }
    }, [props.sharedData.stair]);

    useEffect(() => {
        updateTexture(changeToyTexture.part)
    }, [changeToyTexture.change])

    const propagateMove = (x:number,y:number) => {
        if (familyEls){
            for (let index in familyEls){
                if (familyEls[index] != null){
                    if (familyEls[index].current != null){
                        familyEls[index].current.move(x,y);
                    }
                }
            }
        }
    }

    const propagateRotate = (rad:number) => {
        if (familyEls){
            for (let index in familyEls){
                if (familyEls[index] != null){
                    if (familyEls[index].current != null){
                        familyEls[index].current.rotate(rad);
                    }
                }
            }
        }
    }

    const removeToy = (index:number)=>{
        const newToysSet = toysState.filter((toy:any) => toy.id !== index);
        changeToys(newToysSet);
    }

    const calcToyHeight = (x:number, z:number, legs:number, type:string) => {
        const heigthtype = (type==='boy' || type==='girl')?1.3:2.3;
        const diff = (legs===1 || legs===undefined)? 0:-heigthtype;
        if(stair){
            if (z > -7 || z < -18){
                return diff-6.5;
            }
            return diff + Math.min(Math.floor((x+4)/4)-0.5,3.5);
        } else {
            return diff;
        }
    }

    const updateTexture = (part:string) => {
        if (familyEls){
            for (let index in familyEls){
                if (familyEls[index] != null){
                    if (familyEls[index].current != null){
                        familyEls[index].current.changeTexture(changeToyTexture.color, part);
                    }
                }
            }
        }
    }
    const changeToys = (ntoys:any) => {
        setToysState(ntoys);
        props.onUpdate({toysState:ntoys, stair});
    }
    const startDrag = (e:any) => {
        const nativeEvent = e.nativeEvent;
        const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent;
        setMove({drag:true, x:clientX, y:clientY});
        //console.log(move);
    }
    const endDrag = (e:any) => {
        setMove({drag:false, x:0, y:0});
        //console.log(move);
    }
    const drag = (e:any) => {
        if (move.drag){
            const nativeEvent = e.nativeEvent;
            // e.preventDefault();
            //console.log(move);
            const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent;
            if (Math.abs(move.x - clientX)>40){
                if (move.x - clientX > 0){
                    propagateMove(-1,0)
                } else {
                    propagateMove(1,0)
                }
                setMove({...move, x:clientX});
            }
            if (Math.abs(move.y - clientY)>40){
                if (move.y - clientY > 0){
                    propagateMove(0,-1)
                } else {
                    propagateMove(0,1)
                }
                setMove({...move, y:clientY});
            }
        }

    }

  return (
    <div className="toys" onMouseDown={startDrag} onTouchStart={startDrag} onMouseUp={endDrag} onTouchEnd={endDrag} onMouseMove={drag} onTouchMove={drag}>
        <>
        <Canvas shadows>
            <CameraController enabled={!panning} orbit={orbit} onUpdate={props.onUpdate}/>
            <Suspense fallback={null}>
                <ambientLight 
                intensity={1} />
                <directionalLight
                    castShadow
                    position={[3,10,3]}
                    intensity={1.5}
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                    shadow-camera-far={1000}
                    shadow-camera-left = {-100}
                    shadow-camera-right = {100}
                    shadow-camera-top = {100}
                    shadow-camera-bottom = {-100}
                />
                {toysState.map((toy:any) =>{
                    return <Family  key={toy.id} 
                        index={toy.id}
                        position={[toy.posX, -5, -10]}
                        type={toy.type} 
                        colors={toy.colors}
                        movement={[toy.x, toy.y + calcToyHeight(toy.x, toy.z, toy.legs, toy.type), toy.z, toy.r, toy.larm, toy.rarm, toy.legs]}
                        selectToy = {toy.selected}
                        toys={toysState}
                        changeToys={changeToys}
                        ref={familyEls[toy.id]}
                        onRemove={()=> removeToy(toy.id) }/>
                })}
                { !stair && <>
                <Plane
                    receiveShadow
                    castShadow
                    rotation={[-Math.PI / 2, 0, 0]}
                    position={[0, -7.95, -22]}
                    args={[33, 18]}
                >
                    <meshStandardMaterial attach="material" key={texture.uuid} map={texture}/>
                   
                </Plane>
                <Plane
                    receiveShadow
                    rotation={[-Math.PI / 2, 0, 0]}
                    position={[0, -8.0, -22]}
                    args={[100, 100]}
                >
                    <meshStandardMaterial attach="material" color="#ffffff" />
                </Plane>
                </>
                }
                { stair && <>
                    { 
                        [0,1,2,3,4,5,6,7,8,9].map((x)=>{
                            return <Box key={x} receiveShadow castShadow position={[x*2 -2.5, -14+x, -22]} args={[40 - x*4+4, 1, 10]}>
                                <meshStandardMaterial attach="material" color="#a89683" />
                                </Box>
                        } )
                    }
                    <Plane
                        receiveShadow
                        rotation={[-Math.PI / 2, 0, 0]}
                        position={[0, -14.6, -22]}
                        args={[100, 100]}
                    >
                        <meshStandardMaterial attach="material" color="#ffffff" />
                    </Plane>
                    </>
                }
            </Suspense>
        </Canvas>
        <div className="button-controls">
            <button className="btn-move top" onClick={(event) => propagateMove(0,-2)}>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4732 4.80417L7.15649 0.4875C6.50649 -0.1625 5.45649 -0.1625 4.80649 0.4875L0.489821 4.80417C-0.560179 5.85417 0.189821 7.65417 1.67315 7.65417L10.3065 7.65417C11.7898 7.65417 12.5232 5.85417 11.4732 4.80417Z" fill="#363636"/>
                </svg>
            </button>
            <button className="btn-move bottom" onClick={(event) => propagateMove(0,2)}>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.494129 2.85013L4.8108 7.1668C5.4608 7.8168 6.5108 7.8168 7.1608 7.1668L11.4775 2.85013C12.5275 1.80013 11.7775 0.000130139 10.2941 0.000130203L1.66079 0.000130581C0.177462 0.000130646 -0.555872 1.80013 0.494129 2.85013Z" fill="#363636"/>
                </svg>
            </button>
            <button className="btn-move left" onClick={(event) => propagateMove(-2,0)}>
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.48337 0.51659L1.1667 4.83326C0.516699 5.48326 0.516699 6.53326 1.1667 7.18326L5.48337 11.4999C6.53337 12.5499 8.33337 11.7999 8.33337 10.3166V1.68326C8.33337 0.199923 6.53337 -0.53341 5.48337 0.51659Z" fill="#363636"/>
                </svg>
            </button>
            <button className="btn-move right" onClick={(event) => propagateMove(2,0)}>
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.51663 11.4834L7.8333 7.16674C8.4833 6.51674 8.4833 5.46674 7.8333 4.81674L3.51664 0.500075C2.46664 -0.549925 0.666636 0.200074 0.666635 1.68341L0.666635 10.3167C0.666635 11.8001 2.46663 12.5334 3.51663 11.4834Z" fill="#363636"/>
                </svg>
            </button>
            <button className="btn-move rotate right" onClick={(event) => propagateRotate(-Math.PI/5)}>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.83 2.82973L8.7 0.699727C8.08 0.0797271 7 0.519727 7 1.40973V2.06973C3.06 2.55973 0 5.91973 0 9.99973C0 13.6397 2.43 16.7097 5.77 17.6797C6.39 17.8597 7 17.3597 7 16.7197V16.6897C7 16.2597 6.73 15.8697 6.32 15.7497C3.82 15.0297 2 12.7297 2 9.99973C2 7.02973 4.16 4.56973 7 4.08973V5.61973C7 6.50973 8.07 6.94973 8.7 6.32973L10.83 4.24973C11.23 3.86973 11.23 3.22973 10.83 2.82973ZM15.67 7.75973C15.51 7.20973 15.29 6.67973 15.01 6.16973C14.7 5.59973 13.91 5.50973 13.45 5.96973L13.44 5.97973C13.13 6.28973 13.06 6.75973 13.27 7.13973C13.47 7.50973 13.63 7.89973 13.75 8.29973C13.87 8.71973 14.26 8.99973 14.69 8.99973H14.71C15.36 8.99973 15.86 8.37973 15.67 7.75973ZM9 16.6797V16.6997C9 17.3497 9.62 17.8397 10.24 17.6597C10.79 17.4997 11.32 17.2797 11.83 16.9997C12.4 16.6897 12.49 15.8997 12.03 15.4397L12.01 15.4197C11.7 15.1097 11.23 15.0397 10.85 15.2497C10.48 15.4597 10.09 15.6197 9.69 15.7397C9.28 15.8597 9 16.2497 9 16.6797ZM13.44 14.0297C13.9 14.4897 14.69 14.3997 15 13.8297C15.28 13.3197 15.5 12.7897 15.67 12.2397C15.85 11.6197 15.36 10.9997 14.71 10.9997H14.69C14.25 10.9997 13.87 11.2797 13.75 11.6997C13.63 12.0997 13.47 12.4897 13.27 12.8697C13.06 13.2497 13.14 13.7297 13.44 14.0297Z" fill="#323232"/>
                </svg>
            </button>
            <button className="btn-move rotate left" onClick={(event) => propagateRotate(Math.PI/5)}>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.55984 5.98026C2.09984 5.52026 1.30984 5.60026 0.999839 6.17026C0.719839 6.68026 0.499839 7.20026 0.329839 7.75026C0.139839 8.38026 0.639839 9.00026 1.28984 9.00026H1.29984C1.72984 9.00026 2.11984 8.72026 2.23984 8.30026C2.35984 7.90026 2.51984 7.51026 2.71984 7.13026C2.93984 6.76026 2.86984 6.29026 2.55984 5.98026ZM1.30984 11.0003H1.28984C0.639839 11.0003 0.139839 11.6203 0.329839 12.2503C0.489839 12.7903 0.709839 13.3203 0.989839 13.8303C1.29984 14.4003 2.09984 14.4903 2.55984 14.0303C2.85984 13.7203 2.93984 13.2603 2.72984 12.8803C2.52984 12.5103 2.36984 12.1203 2.24984 11.7203C2.12984 11.2803 1.73984 11.0003 1.30984 11.0003ZM4.15984 17.0203C4.66984 17.3003 5.19984 17.5203 5.74984 17.6803C6.36984 17.8603 6.98984 17.3603 6.98984 16.7203V16.6903C6.98984 16.2603 6.70984 15.8703 6.28984 15.7503C5.88984 15.6303 5.50984 15.4703 5.13984 15.2703C4.75984 15.0603 4.27984 15.1303 3.97984 15.4403L3.94984 15.4703C3.49984 15.9203 3.58984 16.7103 4.15984 17.0203ZM8.99984 2.07026V1.41026C8.99984 0.520262 7.91984 0.0702616 7.28984 0.700262L5.16984 2.83026C4.76984 3.23026 4.76984 3.87026 5.16984 4.26026L7.29984 6.34026C7.92984 6.96026 8.99984 6.51026 8.99984 5.62026V4.09026C11.8398 4.57026 13.9998 7.03026 13.9998 10.0003C13.9998 12.7303 12.1798 15.0203 9.67984 15.7503C9.26984 15.8703 8.99984 16.2603 8.99984 16.6903V16.7103C8.99984 17.3603 9.60984 17.8503 10.2298 17.6703C13.5698 16.7103 15.9998 13.6403 15.9998 10.0003C15.9998 5.92026 12.9498 2.56026 8.99984 2.07026Z" fill="#323232"/>
                </svg>
            </button>
        </div>
        </>
    </div>
  );
};

const texture = new TextureLoader().load(`/assets/textures/rug.jpg`)
export default Mentapp3D;
