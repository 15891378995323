import React, {useState} from 'react';

type Props = {
    children: JSX.Element
}
interface UserToolsContextInterface{
    session: any,
    cardState: any,
    drawState: any,
    showJohari: Boolean,
    sandboxState: any,
    teddyState: any,
    teddyStyleTransform: any,
    teddyElementsArr: any,
    teddyPos: any,
    teddyMessage: any,
    toysState: any,
    stair: Boolean,
    dices: any,
    dicesState: any,
    dicesFirstTime: Boolean,
    setSession: any,
    setCardState: any,
    setDrawState: any,
    setShowJohari: any,
    setSandboxState: any,
    setTeddyState: any,
    setTeddyStyleTransform: any,
    setTeddyElementsArr: any,
    setTeddyPos: any,
    setTeddyMessage: any
    setToysState: any,
    setStair: any,
    setDices: any,
    setDicesState: any,
    setDicesFirtsTime: any,
}

export const UserToolsContextDefault = {
    session: {},
    cardState: [],
    drawState: '',
    showJohari: false,
    sandboxState: [{}],
    teddyState: null,
    teddyStyleTransform: {},
    teddyElementsArr: [
        {piece: 0, pos: 0},
        {piece: 0, pos: 0},
        {piece: 0, pos: 0},
        {piece: 0, pos: 0}
    ],
    teddyPos: {
        px: 500,
        py: 200
    },
    teddyMessage: {
        think: false,
        thinkMessage: '',
        say: false,
        sayMessage: ''
    },
    toysState: [],
    stair: false,
    dices: [
        {id: 0, pos: [-4, -4, -15], rot: [Math.PI*3, 5.5*Math.PI, Math.PI*3]},
        {id: 1, pos: [-9.0, 0.74, -15], rot: [Math.PI*3, 5.5*Math.PI, Math.PI*3]},
        {id: 2, pos: [-2.2, 3, -15], rot: [Math.PI*3, 5.5*Math.PI, Math.PI*3]},
        {id: 3, pos: [1.9, -1.84, -15], rot: [Math.PI*3, 5.5*Math.PI, Math.PI*3]},
        {id: 4, pos: [6.3, 3, -15], rot: [Math.PI*3, 5.5*Math.PI, Math.PI*3]},
        {id: 5, pos: [8, -6, -15], rot: [Math.PI*3, 5.5*Math.PI, Math.PI*3]},
    ],
    dicesState: [],
    dicesFirstTime: true,
    setSession: {},
    setCardState: [],
    setDrawState: ',',
    setShowJohari: false,
    setSandboxState: [{}],
    setTeddyState: null,
    setTeddyStyleTransform: {},
    setTeddyElementsArr: [],
    setTeddyPos: {},
    setTeddyMessage: '',
    setToysState: [],
    setStair: false,
    setDices: [],
    setDicesState: [],
    setDicesFirtsTime: ''
}

const UserToolsContext = React.createContext<UserToolsContextInterface>(UserToolsContextDefault)

export function UserToolsContextProvider ({children}: Props) {

    const [session, setSession] = useState(UserToolsContextDefault.session)

    const [cardState, setCardState] = useState(UserToolsContextDefault.cardState)
    const [drawState, setDrawState] = useState(UserToolsContextDefault.drawState);
    const [showJohari, setShowJohari] = useState(UserToolsContextDefault.showJohari);
    const [sandboxState, setSandboxState] = useState(UserToolsContextDefault.sandboxState);
    const [teddyState, setTeddyState] = useState(UserToolsContextDefault.teddyState);
    const [teddyStyleTransform, setTeddyStyleTransform] = useState(UserToolsContextDefault.teddyStyleTransform);
    const [teddyElementsArr, setTeddyElementsArr] = useState(UserToolsContextDefault.teddyElementsArr);
    const [teddyPos, setTeddyPos] = useState(UserToolsContextDefault.teddyPos);
    const [teddyMessage, setTeddyMessage] = useState(UserToolsContextDefault.teddyMessage);
    const [toysState, setToysState] = useState(UserToolsContextDefault.toysState);
    const [stair, setStair] = useState(UserToolsContextDefault.stair);

    const [dices, setDices] = useState(UserToolsContextDefault.dices);

    const [dicesState, setDicesState] = useState(UserToolsContextDefault.dicesState);
    const [dicesFirstTime, setDicesFirtsTime] = useState(UserToolsContextDefault.dicesFirstTime);

    return (
        <UserToolsContext.Provider 
            value={{
                session,
                cardState,
                drawState,
                showJohari,
                sandboxState,
                teddyState,
                teddyStyleTransform,
                teddyElementsArr,
                teddyPos,
                teddyMessage,
                toysState,
                dices,
                dicesState,
                dicesFirstTime,
                stair,
                setSession,
                setCardState,
                setDrawState,
                setShowJohari,
                setSandboxState,
                setTeddyState,
                setTeddyStyleTransform,
                setTeddyElementsArr,
                setTeddyPos,
                setTeddyMessage,
                setToysState,
                setStair,
                setDices,
                setDicesState,
                setDicesFirtsTime,
            }}>
            {children}
        </UserToolsContext.Provider>)

}

export default UserToolsContext

