import './spinner.css'

const LoaderSpinner: React.FC= () => {
    return ( 
        <div className="spinner-container">
            <div className="spinner"></div>
        </div>
     );
}
 
export default LoaderSpinner;