import {useState, useContext, useEffect} from 'react';
import UserService from '../../../service/users.service';
import UIContext from '../../../context/UIContext';
import UserContext from '../../../context/UserContext';
import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
import Sidebar from '../../layout/Sidebar';
import SessionPannelDashboard from '../sessions-dashboard/SessionPanelDashboard';
import UserHistory from '../history/UserHistory';
import Account from '../account/Account';

import { calculateRemainingDays } from '../../shared/dates-utils/AccountUtils';

import './Dashboard.css'
import NewSession from '../new-session/NewSessions';

const { Subscriptions, StatusBar } = Plugins;

export interface DashboardProps {
    signIn: any
}

const Dashboard: React.FC<DashboardProps> = ({signIn}) => {

    const {dashboardState} = useContext(UIContext)

    const userService = new UserService();

    const {userId, token} = useContext(UserContext);
    const [user, setUser] = useState<any>({})
    let remainingDays = calculateRemainingDays(user?.dateEnd)

    const getUser = () => {
        userService
        .getUser(userId, token)
        .then((res:any) => {
            setUser(res.data.user[0])
            remainingDays = calculateRemainingDays(res.data.user[0]?.dateEnd)
            if (remainingDays<0){
                // should remove access to dashboard
                window.location.href = '/account-plan';
            }
        })  
        .catch((err:any) => {
            console.error(err)
        })
    }
    
    useEffect(() => {
        getUser()
        StatusBar.setStyle({
            style: StatusBarStyle.Light,
        });
        StatusBar.setOverlaysWebView({
            overlay: true,
        });
    }, [])

    const pannel = () => {
        if(dashboardState === 'session'){
           return <SessionPannelDashboard />
        }
        if(dashboardState === 'history'){
            return <UserHistory /> }
        if(dashboardState === 'account'){
            return <Account user={user} signIn={signIn}/> 
        }
          if(dashboardState === 'new-session'){
            return <NewSession /> 
        }
    }
    
    return(    
        <>
        <Sidebar remainingDays={remainingDays} userPlan={user?.subscription}/>
            {pannel()}
        </>
    )
}

export default Dashboard;