import axios from "axios";

export default class AuthService {
  constructor() {
    this.apiHandler = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      withCredentials: true,
    });
  }

  signIn = (credentials) =>
    this.apiHandler.post("api/auth/register", credentials);

  signInEholo = (credentials) =>
    this.apiHandler.post("api/auth/registereholo", credentials);

  validateMail = (id) => this.apiHandler.put("api/auth/validate-email", id);

  logIn = (credentials) => this.apiHandler.post("api/auth/login", credentials);

  forgottenPassword = (credentials) =>
    this.apiHandler.post("api/auth/forgot-password", credentials);

  resetPassword = (password) =>
    this.apiHandler.post("api/auth/password-change", password);
}
