import React, {useState, useEffect, useContext, useRef} from 'react';

import {CARDS, CARDS_IMGS} from '../../../../shared/cards-utils/CardsImages'
import CardComponent from './CardComponent';
import CardComponentSelected from './CardComponentSelected';

import ToolsContext from '../../../../../context/ToolsContext';
import UserToolsContext from '../../../../../context/UserToolsContext';
import UIContext from '../../../../../context/UIContext';
import './MentappCards.css';

import { CloseIcon } from '../../../../../assets';
import { IonContent } from '@ionic/react';

interface SocketProps {
    onUpdate:(cards:any) => void,
    sharedData: any
    showMenus: (value: Boolean, component:string, zoom: Boolean) => void
}
  

const MentappCards: React.FC<SocketProps> = ({sharedData, onUpdate, showMenus})  => {

    const {showSelectCard, setShowSelectCard} = useContext(ToolsContext)
    const {cardState, setCardState} = useContext(UserToolsContext)
    const {setHideBottomMenu} = useContext(UIContext)
    const [isDragging, setIsDragging] = useState(-1);
    const [clickOffset, setClickOffset] = useState({x:0,y:0});
    const [zIndex, setZIndex] = useState(2);
    const cardsScroll = useRef<HTMLIonContentElement>(null);
    const [lastSentEvent, setLastSentEvent] = useState(new Date().getTime());
    const [isCardZoomed, setCardZoomed] = useState(false);
    const [cardsRow, setCardsRow] = useState<any>();

    const handleChecked = (event:any) => { 
        if(CARDS){   
            CARDS.forEach((card:any) => {
                if(card.id === event.id){
                    card.sel = event.selected
                    if(card.sel === false){
                        const filteredCards = cardState.filter((cardState:any) => cardState.id !== card.id)
                        setCardState(filteredCards)
                        onUpdate({cards:filteredCards,zIndex});
                    }
                    else{
                        card.px = 30 + (30 * card.id);
                        card.py = 100 + (8 * card.id);
                        card.z = zIndex+1;
                        setZIndex(zIndexPrev => zIndexPrev++);
                        const filteredCards = [...cardState, card]
                        onUpdate({cards:filteredCards, zIndex: card.z});
                        setCardState(filteredCards)
                    }
                } 
            }) 
        }
    }

    useEffect(()=>{
        if (sharedData.cards){
            setCardState(sharedData.cards)
            CARDS.forEach((card:any) => {
                const cardExists = sharedData.cards.find((c:any) => c.id === card.id)
                card.sel = cardExists !== undefined ? true : false
            })
        }
    }, [sharedData.cards, setCardState]);

    useEffect(()=>{
        if (sharedData.zIndex && !isNaN(sharedData.zIndex)){
            setZIndex(sharedData.zIndex + 1)
        }
    }, [sharedData.zIndex]);

    useEffect(() => {
        if (sharedData.singleCard){
            const newCardsState = [...cardState]
            newCardsState[sharedData.singleCard.id] = sharedData.singleCard
            setCardState(newCardsState)
        }
    }, [sharedData.singleCard, setCardState, cardState])

    useEffect(()=>{
        if(sharedData.showCardMenu){
            setShowSelectCard(sharedData.showCardMenu)
        }
    }, [sharedData, setShowSelectCard]);

    useEffect(()=>{
        checkSelectedCards() 
    }, []);

    const checkSelectedCards = () => {
        const idCardsState = cardState.map((cards:any) => cards.id)
        const allCards = CARDS.map(cards => cards.id)
        for (let value of idCardsState) {
            if(allCards.includes(value)){
                CARDS.forEach(card => { 
                    if(card.id === value){
                        card.sel = true
                    }
                })
            }
        }
        setCardsRow(CARDS)
    }

    const startDrag = (e:any, indexCard:number)=>{  
        const nativeEvent = e.nativeEvent;
        // e.preventDefault();
        const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent;
        const newCardsSet=[...cardState];
        for(let index = 0; index<cardState.length; index++){
            if(cardState[index].id === indexCard){
                setClickOffset({x:cardState[index]?.px - clientX, y:cardState[index]?.py - clientY});
                newCardsSet[index] = {...newCardsSet[index], z :  zIndex };
                setCardState(newCardsSet)
                setIsDragging(indexCard);
                break;
            }
        }
        setZIndex(prevZ => isNaN(prevZ)?10 : prevZ+1)
    }

    const drag = (e:any, indexCard:number)=>{
        const nativeEvent = e.nativeEvent;
        if (isDragging >= 0){
            const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent
            const newCardsSet=[...cardState];
            for(let index in newCardsSet){
                if(newCardsSet[index].id === indexCard){
                    newCardsSet[index] = {...newCardsSet[index], px : clickOffset.x + clientX, py : clickOffset.y + clientY};
                    setCardState(newCardsSet)
                    break;
                }
            }
            const now = new Date().getTime() 
            if (now - lastSentEvent > 300){
                onUpdate({cards:newCardsSet, zIndex});
                setLastSentEvent(now)
            }
        }
    }

    const endDrag = ({nativeEvent}:any, index:number)=>{
        nativeEvent.preventDefault();
        setIsDragging(-1);
        onUpdate({cards:cardState, zIndex});
    }

    const displayMenus = (value:Boolean) => {
        setShowSelectCard(value)
        setHideBottomMenu(value)
        showMenus(value, 'cards', true)
    }
    const scrollBy = (amount:number) => {
        cardsScroll.current?.scrollByPoint(amount, 0, 400);
    }
    const zoomChanged = (data:boolean) => {
        setCardZoomed(data);
    }

    return (
        <>
            <div className="selected-cards-container">
                {cardState &&
                    cardState.map(((selection:any) => {
                        return <CardComponentSelected key={selection.id} card={selection} select={selection.sel} image={CARDS_IMGS[selection.id]} id={selection.id} onStartDrag={startDrag} onDrag={drag} onEndDrag={endDrag}/>
                    }))
                }
            </div>

            <div className={showSelectCard ? "cards-select" : "cards-select hide" }>
                <CloseIcon onClick={() => {displayMenus(false)}} className={(isCardZoomed)?'hide-by-zoom':''}/>
                <IonContent scrollX={true} scrollY={false} ref={cardsScroll} className={`scroll-content ${ (isCardZoomed)?'ios-overflow':'' }`}>
                <div className="cards-row">
                    {cardsRow?.map((card:any, index:number) => {
                        return <CardComponent key={index} image={CARDS_IMGS[card.id]} id={card.id} selected={card.sel} onZoom={zoomChanged} cardState={cardState} handleChecked={handleChecked}/>
                    })}
                </div>     
                </IonContent>
                <div className={"cards-row-arrow arr-right " + ((isCardZoomed)?'hide-by-zoom':'')} onClick={()=>scrollBy(600)}>&raquo;</div>
                <div className={"cards-row-arrow arr-left " + ((isCardZoomed)?'hide-by-zoom':'')} onClick={()=>scrollBy(-600)}>&laquo;</div>
            </div>
        </>
    );
};

export default MentappCards;
