import {useContext} from 'react';
import ModalContext from '../../../context/ModalContext';
import { CheckCircle, WarningCircle } from '../../../assets';

import './modal.css'
import UserContext from '../../../context/UserContext';

 
const Modal: React.FC = () => {

    const {setIsUnsubscribe} = useContext(UserContext)
    const {modalContain, setShowModal} = useContext(ModalContext);
    const {type, modalTitle, modalSubtitle} = modalContain;

    console.log(type)
    
    return ( 
    <>
            {type !== 'unsubscribe'
            ?
                <div className={type === 'success' ? "modal success" : "modal warning"}>
                    {type === 'success' ? <CheckCircle /> :  <WarningCircle />}
                    <h1>{modalTitle}</h1>
                    <p>{modalSubtitle}</p>
                </div>
            :
                <div className="modal unsubscribe">
                    <h1>{modalTitle}</h1>
                    <p>{modalSubtitle}</p>
                    <div className='unsubscribe-btn'>
                        <button className="button button-primary" 
                            onClick={() => {setIsUnsubscribe(true)}}
                        >Continuar</button>
                        <button className="button button-tertiary" 
                            onClick={() => setShowModal(false)}
                        >Cancelar</button>
                    </div>
                </div>
            }
    </>
       
     );
}
 
export default Modal;