import {useState} from 'react';
import { useHistory } from 'react-router';
import FeedbackService from '../../../service/feedback.service';

import {  IonButton} from '@ionic/react';
import { Form } from 'react-bootstrap';
import {FaStar} from 'react-icons/fa';
import { LogoNegative, ArrowBack } from '../../../assets';
import './feedback.css'

 
const FeedBack: React.FC = () => {

    const history = useHistory();

    const [feedback, setFeedback] = useState<any>({
        name: '',
        email: '',
        telephone: '',
        review: '',
        rating: 0
    })

    const [hoverValue, setHoverValue] = useState<any>(undefined)

    const [thanksMessage, setThanksMessage] = useState<Boolean>(false)
    const [error, setError] = useState<any>({
        active: false,
        errorMessage: ''
    })

    const stars = Array(5).fill(0)
    const handleMouseOver = (value:any) => {
        setHoverValue(value)
    }
    const handleMouseLeave = () => {
        setHoverValue(undefined)
    }

    const handleChange = (e:any) => setFeedback({ ...feedback, [e.target.name]: e.target.value })
    const handleClick = (rating: any) => setFeedback({ ...feedback, rating: rating })

    const handleSubmit = (e:any) => {
        e.preventDefault();

        const feedbackService = new FeedbackService();
        
        feedbackService
            .sendFeedback(feedback)
            .then((res:any) => {
                setThanksMessage(true)
                setTimeout(() => {
                    setThanksMessage(false)
                    history.push('/')
                }, 3000);    
            })
            .catch((err:any) => {
                setError({...error, active: true, errorMessage: err.response.data.error})
                setTimeout(() => {
                    setError({...error, active: false, errorMessage: ''})
                }, 3000);
            })
    }

    return ( 
        <div className="feedback">
            <div className="feedback-form">
                <div className="back-icons">
                    <ArrowBack /><a href="/">Volver atrás</a>
                </div>
            {
                !thanksMessage
                    ?
                    <>
                        <h1>Valora Mentapp</h1>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId='name'>
                                <Form.Control 
                                    type='text'
                                    name='name'
                                    placeholder="Nombre"
                                    onChange={ handleChange }
                                    autoComplete="off"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId='email'>
                                <Form.Control 
                                    type='email'
                                    name='email'
                                    placeholder="Email"
                                    onChange={ handleChange }
                                    autoComplete="off"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId='telephone'>
                                <Form.Control 
                                    type='text'
                                    name='telephone'
                                    placeholder="Teléfono"
                                    onChange={ handleChange }
                                    autoComplete="off"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId='review'>
                                <Form.Control 
                                    as='textarea'
                                    name='review'
                                    placeholder="Déjanos tu opinión"
                                    onChange={ handleChange }
                                    autoComplete="off"
                                    required
                                    />
                            </Form.Group>
                            <div className="rating-star">
                            <h1>Puntuación</h1>
                                {
                                    stars.map((star, index) => {
                                        return (
                                        <FaStar 
                                            key={index}
                                            size={24}
                                            style={{
                                                
                                            }}
                                        color={(hoverValue || feedback.rating) > index ? '#FFBA5A' : '#a9a9a9'}
                                        onClick={() => handleClick(index + 1)}
                                        onMouseOver={() => handleMouseOver(index + 1)}
                                        onMouseLeave={handleMouseLeave}
                                        />
                                        )
                                    })
                                }
                            </div>
                            {error.active && <p className="error-message">{error.errorMessage}</p>}
                            <IonButton fill="clear" type="submit" className="button button-primary no-ripple">Enviar valoración</IonButton>
                        </Form>
                    </>
                    :
                <h1>Gracias por tu valoración</h1>
            }
            </div>
            <div className="footer-login">
                    <LogoNegative />
                </div>
        </div>
     );
}
 
export default FeedBack;