import {useState, useContext, useEffect} from 'react';
import SessionService from '../../service/sessions.service';
import { IonInput, IonButton, IonLabel} from '@ionic/react';
import UserContext from '../../context/UserContext';

import WaitingRoom from './WaitingRoom';

import { LogoNegative, ArrowBack } from '../../assets';
import './login.css'

import { Plugins } from '@capacitor/core';
const JitsiMeet = Plugins.Jitsi;

export interface Props{
    signIn: any
}
const Login : React.FC<Props> = ({signIn}) => {

    const {setUserId, setToken} = useContext(UserContext)

    const [sessionPermission, setSessionPermission] = useState<any>({
        sessionCode: '',
        privacyChecked: false,
        authorization: false,
    });
    const [session, setSession] = useState<any>({});
    const [error, setError] = useState<any>({
        active: false,
        errorMessage: ''
    });

    useEffect( () => {
        setUserId(null);
        setToken(null)
        window.sessionStorage.setItem('jwt', '')
        window.sessionStorage.setItem('userID', '')
        JitsiMeet.leaveConference();
    }, []);

    const checkAuthorization = () => {  
        if(!sessionPermission.privacyChecked){
            setError({...error, active: true, errorMessage: 'Debes aceptar los términos y política de privacidad'})
        }
        const sessionService = new SessionService(); 
        sessionService
            .getSessionByCode(sessionPermission.sessionCode)
            .then((res:any) => {
                setSession(res.data.session)
                signIn(null, true)
                sessionPermission.privacyChecked && setSessionPermission({...sessionPermission, authorization : true})
            })
            .catch((err:any) => {
                setError({...error, active: true, errorMessage: err.response.data.message})
            }) 
            
        setTimeout(() => {
            setError({...error, active: false, errorMessage: ''})
        }, 2000);
    }

    const handleChecked = (e:any) => {
        setSessionPermission({...sessionPermission, privacyChecked : !sessionPermission.privacyChecked})
    }

    return (
        <> 
        {!sessionPermission.authorization
            ? 
            <div className="login" scroll-y="false">
                    <div className="session-form">
                        <div className='back hidde'>
                            <div className="back-icons">
                                <ArrowBack /><a href="/">Volver atrás</a>
                            </div>
                        </div>           
                        <h1>¡Bienvenido!</h1>
                        <p>Introduce tu código de sesión</p>
                            <IonInput className={error.active ? 'error-input': ''} value={sessionPermission.sessionCode} placeholder="Ejemplo: XC32K2" onIonChange={e => setSessionPermission({...sessionPermission, sessionCode: e.detail.value!})}></IonInput>
                        <div className="privacy-policy-checkbox">
                            <input
                                name="privacy"
                                type="checkbox"
                                onChange={handleChecked}
                            />
                            <IonLabel><p>Acepto <a href="/terminos">los términos de uso</a> y la <a href="/politica-privacidad">política de privacidad</a>.</p></IonLabel>
                        </div>
                        {error.active && <p className="error-message">{error.errorMessage}</p>}                
                        <IonButton 
                            fill="clear"
                            className="button button-primary" 
                            onClick={checkAuthorization}
                        >Accede como paciente</IonButton>
                            <div className="separation">
                                <p className="lineBreak">o</p>
                                <div className="line"></div>
                            </div>
                            <a className="button button-tertiary therapist-access" href="/acceso">Accede como terapeuta</a>
                            <div className="create-account-container">
                                <p className="create-account">¿No tienes una cuenta de terapeuta?</p><a className="link-signin" href="/registro">Regístrate</a>
                            </div>
                    </div>
                <div className="footer-login">
                    <LogoNegative />
                </div>
            </div>
            :
            <WaitingRoom sessionCode = {session[0].code}/>
        }
        </>
    );
}
 
export default Login;