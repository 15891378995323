import { useEffect } from 'react';
import { useParams } from 'react-router';

import AuthService from '../../service/auth.service';
import { useHistory } from 'react-router-dom';
import { LogoNegative } from '../../assets';

type QuizParams = {
    id: string;
};  


const ConfirmEmail: React.FC = () => {

    const history = useHistory();

    const {id} = useParams<QuizParams>();
    const authService = new AuthService();

    useEffect(() => {
        authService
            .validateMail({id})
            .then((res:any) => {
                setTimeout(() => {
                history.push('/acceso')
            }, 5000)
            })
            .catch((err:any)=> {
                console.error(err)
            })
    }, [])      // eslint-disable-line

    return ( 
        <div className="login">
            <div className="session-form">
                <div className="signup">
                    <h2>Registro completado</h2>
                    <p>Hemos confirmado tu registro.</p>
                    <p>En unos segundos serás redirigido a la ventana de acceso</p>
                    <p>¡Bienvenido a Mentapp!</p>
                </div>

            </div>
                <div className="footer-login">
                    <LogoNegative />
                </div>
        </div>
    );
}
 
export default ConfirmEmail;