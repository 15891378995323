import React, { useState } from 'react';
import { ExpandIcon } from '../../../../../assets';
import './CardComponent.css'
interface CardProps {
  image: any,
  id: number,
  selected: Boolean
  handleChecked: any,
  cardState: any,
  onZoom:any
}

const CardComponent: React.FC<CardProps> = ({image, id, selected, handleChecked, cardState, onZoom})  => {

  const selectedCardsId = cardState.map((cardsId:any) => cardsId.id)
  const checkedSelectedCards = selectedCardsId.includes(id)

  const [zoom, setZoom] = useState(false)

  const zoomIn = (e:any)=>{
    e.preventDefault();
    e.stopPropagation();
    setZoom(true);
    onZoom(true);
  }
  const clicked = (ev:any) => {
    if (zoom){
      setZoom(false);
      onZoom(false);
    } else {
      handleChecked({e:ev.e, id:ev.id, selected:!selected})
      onZoom(false);
    }
  }
  
  return (
    <>
      <div className={(!selected ? 'card' : 'card selected') + ' ' + (zoom ? 'zoomed-in' : '')} onClick={(e) => clicked({e, id, selected:!selected})}>
        <img draggable="false" src={image} alt="card"/>
        <input 
          type="checkbox"
          checked={checkedSelectedCards && true}
          value={id}
          name="card"
          className="card-checkbox"
          readOnly
        />
        {!zoom && 
          <ExpandIcon className="zoom" onClick={zoomIn} />
        }
      </div>
    </>
  );
};

export default CardComponent;
