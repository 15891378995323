import {useState, useEffect, useContext, CSSProperties} from 'react';
import './teddyComponent.css';
import ToolsContext from '../../../../../context/ToolsContext';
import UserToolsContext from '../../../../../context/UserToolsContext';
import { ThinkImage, TalkImage} from '../../../../../assets';
import TeddyBody from './../../../../../assets/img/teddy/body/teddy-body.png'
import TeddyEars from './../../../../../assets/img/teddy/body/teddy-ears.png'
import TeddyFace from './../../../../../assets/img/teddy/body/teddy-face.png'
import TeddyPaws from './../../../../../assets/img/teddy/body/teddy-paws.png'
interface TeddyComponentProps {
    onUpdate:(teddy:any) => void,
    sharedData: any,
    showMenus:(value:Boolean, component:string, zoom:Boolean) => void
}
 
const TeddyComponent: React.FC<TeddyComponentProps> = ({onUpdate, sharedData, showMenus}) => {

    const {setMessageSend, setShowTeddyText} = useContext(ToolsContext)
    const {teddyPos, teddyMessage, setTeddyMessage, setTeddyPos, teddyElementsArr, setTeddyElementsArr} = useContext(UserToolsContext)


    const [isDragging, setIsDragging] = useState(-1);

    useEffect(() => {
        sharedData.teddy && setTeddyElementsArr(sharedData.teddy)
    }, [sharedData.teddy])

    useEffect(() => {
        if(sharedData.teddyMovement !== undefined){
            setTeddyPos(sharedData.teddyMovement)
        }
    }, [sharedData.teddyMovement])

    useEffect(() => {
        if(sharedData.teddyMessage !== undefined){
            setMessageSend(true)
            setShowTeddyText(false)
            setTeddyMessage(sharedData.teddyMessage)
        }
    }, [sharedData.teddyMessage])

    const style:CSSProperties = {
        position: 'absolute',
        width: '300px',
        height: '372px',
        top: teddyPos?.py,
        left: teddyPos?.px,
    }

    const sayMessageStyle:CSSProperties = {
        top: teddyPos?.py - 70,
        left: teddyPos?.px - 100
    }

    const thinkMessageStyle:CSSProperties = {
        top: teddyPos?.py - 70,
        left: teddyPos?.px + 200
    }

    const startDrag = ({nativeEvent}:any)=>{
        setIsDragging(1);
    }

    const drag = ({nativeEvent}:any)=>{
        if(isDragging >= 0){
            const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent;
            setTeddyPos({px:clientX - 170, py:clientY - 150});
            onUpdate({teddyMovement: teddyPos});
        }
    }

    const endDrag = ({nativeEvent}:any)=>{
        setIsDragging(-1);
        setTeddyPos(teddyPos)
        onUpdate({teddyMovement: teddyPos});
    }

    return ( 
        <>

            <div 
                id="teddy"
                style={style}
                onMouseDown={startDrag}
                onTouchStart={startDrag}
                onMouseMove={drag}
                onTouchMove={drag}
                onMouseUp={endDrag}
                onTouchEnd={endDrag}
                onMouseOut={endDrag}
            > 
                <div id="teddy-element-body">
                    <div id="teddy-body" style={{transform: `translateX(${teddyElementsArr[2]?.pos}px)`}}>
                        <img draggable="false" src={TeddyBody} alt="teddy" />
                    </div>
                </div>
                <div id="teddy-element-ears">
                    <div id="teddy-ears" style={{transform: `translateX(${teddyElementsArr[0]?.pos}px)`}}>
                        <img draggable="false" src={TeddyEars} alt="teddy" />
                    </div>
                </div>
                <div id="teddy-element-face">
                    <div id="teddy-face" style={{transform: `translateX(${teddyElementsArr[1]?.pos}px)`}}>
                        <img draggable="false" src={TeddyFace} alt="teddy" />
                    </div>
                </div>
                <div id="teddy-element-paws">
                    <div id="teddy-paws" style={{transform: `translateX(${teddyElementsArr[3]?.pos}px)`}}>
                        <img draggable="false" src={TeddyPaws} alt="teddy" />
                    </div>
                </div>
            </div>

            {
                teddyMessage.say && teddyMessage.sayMessage
                &&
                <div className="teddy-message say" style={sayMessageStyle}>
                    <TalkImage />
                    <p>{teddyMessage.sayMessage}</p>
                </div>
            }
            {
                teddyMessage.think && teddyMessage.thinkMessage
                &&
                <div className="teddy-message think" style={thinkMessageStyle}>
                    <ThinkImage />
                    <p>{teddyMessage.thinkMessage}</p>
                </div>
            }

        </>
     );
}
 
export default TeddyComponent;

