import {useContext, useEffect, useState} from 'react';
import ToolsContext from '../../../../context/ToolsContext';

import {AngryFace, ColorIcon, HappyFace, SadFace, NeutralFace} from '../../../../assets/index'; 

interface ToyColorSelectProps {
    iconState: string,
    skinColor: any,
    setSkinColor: any,
    onUpdate: any,
    sharedData: any
}
 
const ToyColorSelect: React.FC<ToyColorSelectProps> = ({skinColor, iconState, setSkinColor, onUpdate, sharedData}) => {

    const {changeToyTexture, setChangeToyTexture} = useContext(ToolsContext)
    const [face, setFace] = useState('neutral')

    useEffect(() => {
        if(sharedData.toyFace !== undefined){
            if(sharedData.toyFace.face !== undefined){
                setFace(sharedData.toyFace.face)
            }
            if(sharedData.toyFace.color){
                setSkinColor(sharedData.toyFace.color)
            }
        }
    }, [sharedData.toyFace])

    return ( 
        <div className={`color-menu expanded ${iconState}`}>
            <div className="colors">
            
            {iconState !== 'face'
                ?
                iconState !== 'skin'
                    ?
                    <>
                <ColorIcon 
                    fill='#FF0000'
                    onClick={() => setChangeToyTexture({...changeToyTexture, color: 'red', change: !changeToyTexture.change})
                    }
                />
                <ColorIcon fill='#FFFF00'
                    onClick={() => setChangeToyTexture({...changeToyTexture, color: 'yellow', change: !changeToyTexture.change})
                    }
                />
                <ColorIcon fill='#8D847F'
                    onClick={() => setChangeToyTexture({...changeToyTexture, color: 'gray', change: !changeToyTexture.change})
                    }
                />
                <ColorIcon fill='#CCA978'
                    onClick={() => setChangeToyTexture({...changeToyTexture, color: 'brown', change: !changeToyTexture.change})
                    }
                />
                <ColorIcon fill='#FFAA00'
                    onClick={() => setChangeToyTexture({...changeToyTexture, color: 'orange', change: !changeToyTexture.change})
                    }
                />
                <ColorIcon fill='#FCFCFC'
                    onClick={() => setChangeToyTexture({...changeToyTexture, color: 'white', change: !changeToyTexture.change})
                    }
                />
                <ColorIcon fill='#F9EEEE'
                    onClick={() => setChangeToyTexture({...changeToyTexture, color: 'pink', change: !changeToyTexture.change})
                    }
                />
                <ColorIcon fill='#0E4BEF'
                    onClick={() => setChangeToyTexture({...changeToyTexture, color: 'blue', change: !changeToyTexture.change})
                    }
                />
                </>
                :
                <>
                <ColorIcon fill='#F9EEEE'
                    onClick={() => {
                        setSkinColor('#F9EEEE')
                        onUpdate({toyFace: {color: '#F9EEEE'}})
                        face === 'neutral' 
                            &&
                            setChangeToyTexture({...changeToyTexture, color: 'wn', change: !changeToyTexture.change}) 
                        face === 'happy' 
                            &&  
                        setChangeToyTexture({...changeToyTexture, color: 'wh', change: !changeToyTexture.change}) 
                        face === 'sad' 
                            &&  
                        setChangeToyTexture({...changeToyTexture, color: 'ws', change: !changeToyTexture.change}) 
                        face === 'angry' 
                            &&  
                        setChangeToyTexture({...changeToyTexture, color: 'wa', change: !changeToyTexture.change})  
                    }}
                />
                <ColorIcon fill='#CCA978'
                    onClick={() => 
                        {
                        setSkinColor('#CCA978')
                        onUpdate({toyFace: {color: '#CCA978'}})
                        face === 'neutral' 
                            &&
                        setChangeToyTexture({...changeToyTexture, color: 'bn', change: !changeToyTexture.change}) 
                        face === 'happy' 
                            &&  
                        setChangeToyTexture({...changeToyTexture, color: 'bh', change: !changeToyTexture.change})
                        face === 'sad' 
                            &&  
                        setChangeToyTexture({...changeToyTexture, color: 'bs', change: !changeToyTexture.change}) 
                        face === 'angry' 
                            &&  
                        setChangeToyTexture({...changeToyTexture, color: 'ba', change: !changeToyTexture.change})  
                    }}
                />

                </>
                
                :
                <div className="faces-icons">
                    <NeutralFace 
                        onClick={() => {
                            setFace('neutral')
                            onUpdate({toyFace: {face: face}})
                            skinColor === '#F9EEEE'
                                ?
                            setChangeToyTexture({...changeToyTexture, color: 'wn', change: !changeToyTexture.change})
                                :
                            setChangeToyTexture({...changeToyTexture, color: 'bn', change: !changeToyTexture.change})
                        }}
                        fill={face === 'neutral' ? '#79CE67' : '#3E3E3E'}
                    />
                    <HappyFace 
                        onClick={() => {
                            setFace('happy')
                            onUpdate({toyFace: {face: face}})
                            skinColor === '#F9EEEE'
                            ?
                            setChangeToyTexture({...changeToyTexture, color: 'wh', change: !changeToyTexture.change})
                            :
                            setChangeToyTexture({...changeToyTexture, color: 'bh', change: !changeToyTexture.change})
                        }}
                        fill={face === 'happy' ? '#79CE67' : '#3E3E3E'}
                    />
                    <SadFace 
                        onClick={() => {
                            setFace('sad')
                            onUpdate({toyFace: {face: face}})
                            skinColor === '#F9EEEE'
                                ?
                            setChangeToyTexture({...changeToyTexture, color: 'ws', change: !changeToyTexture.change})
                                :
                            setChangeToyTexture({...changeToyTexture, color: 'bs', change: !changeToyTexture.change})
                        }}
                        fill={face === 'sad' ? '#79CE67' : '#3E3E3E'}

                    />
                    <AngryFace 
                        onClick={() => {
                            setFace('angry')
                            onUpdate({toyFace: {face: face}})
                            skinColor === '#F9EEEE'
                                ?
                            setChangeToyTexture({...changeToyTexture, color: 'wa', change: !changeToyTexture.change})
                                :
                            setChangeToyTexture({...changeToyTexture, color: 'ba', change: !changeToyTexture.change})
                        }}
                        fill={face === 'angry' ? '#79CE67' : '#3E3E3E'}
                    />
                </div>
               } 
            </div>
        </div>
     );
}
 
export default ToyColorSelect;