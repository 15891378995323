import React, { Suspense, useEffect, useRef, useState, useContext} from 'react';
import { Canvas, useFrame } from '@react-three/fiber'
import { TextureLoader, Mesh } from 'three';
import { Plane } from '@react-three/drei';

import UserToolsContext from '../../../../../context/UserToolsContext';

import './dicesComponent.css'

interface DicesComponentProps {
    onUpdate:(sandbox:any) => void,
    sharedData: any,
}
interface DiceProps {
    pos: any,
    rot: any,
    index: number,
    onClick: any
}

const textures = [
    ['01', '02', '03', '04', '05', '06'].map(name => new TextureLoader().load(`/assets/textures/${name}.png`) ),
    ['11', '12', '13', '14', '15', '16'].map(name => new TextureLoader().load(`/assets/textures/${name}.png`) ),
    ['21', '22', '23', '24', '25', '26'].map(name => new TextureLoader().load(`/assets/textures/${name}.png`) ),
    ['31', '32', '33', '34', '35', '36'].map(name => new TextureLoader().load(`/assets/textures/${name}.png`) ),
    ['41', '42', '43', '44', '45', '46'].map(name => new TextureLoader().load(`/assets/textures/${name}.png`) ),
    ['51', '52', '53', '54', '55', '56'].map(name => new TextureLoader().load(`/assets/textures/${name}.png`) ),
];


const Dice: React.FC<DiceProps>  = (props) =>{
    const cube = useRef<Mesh>()
    useFrame(() => {
        cube.current!.rotation.x += (props.rot[0] - cube.current!.rotation.x) * 0.05;
        cube.current!.rotation.y += (props.rot[1] - cube.current!.rotation.y) * 0.06;
        cube.current!.rotation.z += (props.rot[2] - cube.current!.rotation.z) * 0.04;
    });


    return (
      <mesh position={props.pos} ref={cube} castShadow onClick={props.onClick}>
        <boxBufferGeometry args={[3.5,3.5,3.5]} attach="geometry" />
        {textures && textures[props.index] && textures[props.index].map(texture => (
            <meshPhongMaterial attachArray="material" key={texture.uuid} map={texture} bumpMap={texture}/>
        ))}
      </mesh>
    )
}
 
const DicesComponent: React.FC<DicesComponentProps> = ({onUpdate, sharedData}) => {

    const {dices, setDices, setDicesState} = useContext(UserToolsContext)

    const depth = 15;
    
    const faceMultipliers = [[0,0,0],[1,0,4],[2,0,2],[3,0,0],[2,1,2],[3,3,3]];
    const rollTime = 4*Math.PI;

    const randomize = (index:number) => {
        const newDices = [...dices];
        const face = Math.floor(Math.random()*6);
        const factor = Math.floor(Math.random()*3)
        newDices[index].rot = [faceMultipliers[face][0] * Math.PI/2 + rollTime*factor,faceMultipliers[face][1] * Math.PI/2 + rollTime*factor,faceMultipliers[face][2] * Math.PI/2 + rollTime*factor]
        setDicesState(newDices)
        onUpdate({dices:newDices});
    }

    useEffect(() => {
        if(sharedData !== undefined){
            setDices(sharedData)
            setDicesState(sharedData)
        }
    },[sharedData])

    return ( 

        <div className="dices">
            <Canvas shadows>         
                <Suspense fallback={null}>
                    <ambientLight 
                    intensity={1} />
                    <directionalLight
                        castShadow
                        position={[6,7,10]}
                        intensity={1.5}
                        shadow-mapSize-width={1024}
                        shadow-mapSize-height={1024}
                        shadow-camera-far={1000}
                        shadow-camera-left = {-100}
                        shadow-camera-right = {100}
                        shadow-camera-top = {100}
                        shadow-camera-bottom = {-100}
                    />
                    {dices.map((dice:any) => (
                        <Dice index={dice.id} key={dice.id} pos={dice.pos} rot={dice.rot} onClick={(event:any) => randomize(dice.id)} />
                    ))}
                    <Plane
                        receiveShadow
                        rotation={[0, 0, -Math.PI / 2]}
                        position={[0, 0, -depth -0.8]}
                        args={[1000, 1000]}
                    >
                        <meshStandardMaterial attach="material" color="rgb(121, 206, 103);" />
                    </Plane>
                </Suspense>
            </Canvas>
        </div>

     );
}
 
export default DicesComponent;