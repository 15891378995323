import { useContext } from 'react';
import { IonGrid, IonRow } from '@ionic/react';
import ToolsContext from '../../../../context/ToolsContext';
import {ColorIcon} from '../../../../assets/index'; 

import './colorPicker.css'

export interface ColorPickerProps {
    onUpdate: any,
    colorActive: Boolean;
    setColorActive: any
}
 
const ColorPicker: React.FC<ColorPickerProps> = ({onUpdate, colorActive, setColorActive})  => {

    const {changeColor} = useContext(ToolsContext)

    const updateColor = (color:any) => {
        changeColor(color)
        setColorActive(false)
        onUpdate({color: color});
    }

    return (

        <div className={colorActive ? 'color-expanded' : 'color-collapsed'}>
            <IonGrid>
                <IonRow>
                    <ColorIcon
                        fill='#FBE415'
                        onClick={() => {updateColor('#FBE415')}}   
                    />
                    <ColorIcon
                        fill='#F89854'
                        onClick={() => {updateColor('#F89854')}}   
                    />
                    <ColorIcon
                        fill='#C06A2B'
                        onClick={() => {updateColor('#C06A2B')}}   
                    />
                    <ColorIcon
                        fill='#F35B5B'
                        onClick={() => {updateColor('#F35B5B')}}   
                    />
                    <ColorIcon
                        fill='#984FB2'
                        onClick={() => {updateColor('#984FB2')}}   
                    />
                </IonRow>
                <IonRow>
                    <ColorIcon
                        fill='#608DE3'
                        onClick={() => {updateColor('#608DE3')}}   
                    />
                    <ColorIcon
                        fill='#79CE67'
                        onClick={() => {updateColor('#79CE67')}}   
                    />
                    <ColorIcon
                        fill='#30B155'
                        onClick={() => {updateColor('#30B155')}}   
                    />
                    <ColorIcon
                        fill='#323232'
                        onClick={() => {updateColor('#323232')}}   
                    />
                    <ColorIcon
                        fill='#C4C4C4'
                        onClick={() => {updateColor('#C4C4C4')}}   
                    />
                </IonRow>
            </IonGrid>
        </div>
    );
}
 
export default ColorPicker;

