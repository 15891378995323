import { useContext } from 'react';
import ToolsContext from '../../../../context/ToolsContext';
import UIContext from '../../../../context/UIContext';
import {TextHighIcon, TextSmallIcon} from '../../../../assets/index'; 
import './textModes.css'

export interface TextModesProps {
    textModes : Boolean;
    setTextModes: any;
}
 
const TextModes: React.FC<TextModesProps> = ({ textModes, setTextModes }) => {

    const {setTextState, setShowTextModal} = useContext(ToolsContext)
    const {setIconState} = useContext(UIContext)


    const selectTextState = (value:string) => {
        setIconState(value)
        setTextState(value)
        setTextModes(!textModes)
        setShowTextModal(true)
    }

    return ( 
        <div className={textModes ? 'text-modes-expanded' : 'text-modes-collapsed'}>
            <TextHighIcon 
                onClick={() => {
                    selectTextState('high')
                }}
                fill='#363636'
            />
            <TextSmallIcon 
                onClick={() => {
                    selectTextState('small')
                }}
                fill='#363636'
            />
        </div>
     );
}
 
export default TextModes;