import {useContext, useRef} from 'react';
import { IonContent } from '@ionic/react';

import UserToolsContext from '../../../../context/UserToolsContext';

import MaleClic from './../../../../assets/img/clicks-menu/male-clic.png'
import FemaleClick from './../../../../assets/img/clicks-menu/female-clic.png'
import BoyClic from './../../../../assets/img/clicks-menu/boy-clic.png'
import GirlClic from './../../../../assets/img/clicks-menu/girl-clic.png'

interface ToySelectComponentProps {
    onUpdate:any
}
 
const ToySelectComponent: React.FC<ToySelectComponentProps> = ({onUpdate}) => {

    const {toysState, setToysState, stair} = useContext(UserToolsContext) 

    const addToy = (id:Number, toyType:string, colorHair:string, colorShirt:string, colorPant:string, colorSkin:string = 'pink') => {
        let toyIndex = toysState.length;
        for (let index=0; index < toysState.length; index++){
            const toy = toysState.find((toy:any) => toy.id === index);
            if (toy === undefined || toy.type === 'none'){
                toyIndex = index;
                break;
            }
        }
        if (toyIndex<8){
            const newToy = {
                id: toyIndex,
                order:toyIndex, 
                type: toyType, 
                posX: toyIndex*3 - 10, 
                posY: toyIndex,
                x: toyIndex*3 - 10,
                y:0,
                z:-10,
                r:0,
                rarm:Math.PI/2,
                larm:Math.PI/2,
                legs:1,
                colors:[colorHair, colorShirt, colorPant, colorSkin],
                selected: false
            };
            const newToys = [...toysState, newToy];
            changeToys(newToys)
            onUpdate({toys:newToys});
        }
    }

    const changeToys = (ntoys:any) => {
        setToysState(ntoys);
        onUpdate({toys:ntoys, stair});
    }

    const toySelectScroll = useRef<HTMLIonContentElement>(null);

    const scrollBy = (amount:number) => {
        if(toySelectScroll.current !== null){
            toySelectScroll.current.scrollByPoint(amount, amount, 1000)
        }
    }


    return ( 
        <div>
            <IonContent className='toy-menu expanded' ref={toySelectScroll}>
            <div className="top-arrow" onClick={()=>scrollBy(-600)}>&raquo;</div>
            <div className="bottom-arrow" onClick={()=>scrollBy(600)}>&raquo;</div>
                <div className="images">
                    <div>
                        <img src={MaleClic} 
                            alt="man" 
                            draggable="false"
                            onClick={() => {addToy(0, 'male','yellow','gray','red','wh',)}}
                        />
                    </div>
                    <div>
                        <img src={FemaleClick} 
                            alt="female" 
                            draggable="false"
                            onClick={() => {addToy(1,'female','brown','yellow','gray','wh')}}
                        />
                    </div>
                    <div>
                        <img src={BoyClic} 
                            alt="boy" 
                            draggable="false"
                            onClick={() => {addToy(2, 'boy', 'gray','brown', 'gray','wh', )}}
                        />
                    </div>
                    <div >
                        <img src={GirlClic} 
                            alt="girl" 
                            draggable="false"
                            onClick={() => {addToy(3,'girl', 'gray', 'yellow', 'red', 'wh')}}
                        />
                    </div>
                </div>
            </IonContent>
        </div>
     );
}
 
export default ToySelectComponent;