import React, {useState} from 'react';

type Props = {
    children: JSX.Element
}
interface UIContextInterface{
    dashboardState: String,
    hideMobileMenu: Boolean,
    hideDashboard: Boolean,
    hideLeftMenu: Boolean,
    hideBottomMenu: Boolean,
    hideToolsMenu: Boolean,
    iconActive: Boolean,
    iconState: string,
    modeState: string,
    panning: boolean,
    iconToSocket: string,
    showToyPannel: Boolean,
    loadingPannel: Boolean,
    zoom: number,
    dragging: number,
    toyIconActive: Boolean,
    hairIconActive: Boolean,
    thirtIconActive: Boolean,
    pantsIconActive: Boolean,
    faceIconActive: Boolean,
    skinIconActive: Boolean,
    changeToyIcon: any,
    changeTeddyIcon: any,
    teddyBodyActive: Boolean,
    teddyEarsActive:Boolean,
    teddyFaceActive: Boolean,
    teddyPawsActive: Boolean,
    thinkIconActive: Boolean,
    sayIconActive: Boolean,
    setDashboardState: any,
    setHideMobileMenu: any,
    setHideDashboard: any,
    setHideLeftMenu: any,
    setHideBottomMenu: any,
    setHideToolsMenu: any,
    setIconActive: any,
    setIconState: any,
    setModeState: any,
    setPanning: any,
    setIconToSocket: any,
    setShowToyPannel: any
    setLoadingPannel: any,
    setZoom: any,
    setDragging: any,
    setThinkIconActive: any,
    setSayIconActive: any
}

export const UIContextDefault = {
    dashboardState: 'session',
    hideMobileMenu: false,
    hideDashboard: false,
    hideLeftMenu: false,
    hideBottomMenu: false,
    hideToolsMenu: false,
    iconActive: false,
    iconState: 'videocall',
    modeState: 'videocall',
    panning: true,
    iconToSocket: '',
    showToyPannel: true,
    loadingPannel: false,
    zoom: 1,
    dragging: -1,
    toyIconActive: false,
    hairIconActive: false,
    thirtIconActive: false,
    pantsIconActive: false,
    faceIconActive: false,
    skinIconActive: false,
    changeToyIcon: false,
    changeTeddyIcon: false,
    teddyBodyActive: false,
    teddyFaceActive: false,
    teddyEarsActive: false,
    teddyPawsActive: false,
    thinkIconActive: false,
    sayIconActive: false,
    setDashboardState: 'session',
    setHideMobileMenu: false,
    setHideDashboard: false,
    setHideLeftMenu: false,
    setHideBottomMenu: false,
    setHideToolsMenu: false,
    setIconActive: false,
    setIconState: 'paint',
    setModeState: 'draw',
    setPanning: true,
    setIconToSocket: '',
    setShowToyPannel: true,
    setLoadingPannel: false,
    setZoom: 1,
    setDragging: -1,
    setThinkIconActive: false,
    setSayIconActive: false,
}

const UIContext = React.createContext<UIContextInterface>(UIContextDefault)

export function UIContextProvider ({children}: Props) {

    const [dashboardState, setDashboardState] = useState(UIContextDefault.dashboardState);
    const [hideMobileMenu, setHideMobileMenu] = useState(UIContextDefault.hideMobileMenu);
    const [hideDashboard, setHideDashboard] = useState(UIContextDefault.hideDashboard);
    const [hideLeftMenu, setHideLeftMenu] = useState(UIContextDefault.hideLeftMenu);
    const [hideBottomMenu, setHideBottomMenu] = useState(UIContextDefault.hideBottomMenu);
    const [hideToolsMenu, setHideToolsMenu] = useState(UIContextDefault.hideToolsMenu);
    const [iconActive, setIconActive] = useState(UIContextDefault.iconActive);
    const [iconState, setIconState] = useState(UIContextDefault.iconState);
    const [modeState, setModeState] = useState(UIContextDefault.modeState);
    const [panning, setPanning] = useState(UIContextDefault.panning);
    const [iconToSocket, setIconToSocket] = useState(UIContextDefault.iconToSocket)
    const [showToyPannel, setShowToyPannel] = useState(UIContextDefault.showToyPannel)
    const [loadingPannel, setLoadingPannel] = useState(UIContextDefault.loadingPannel)

    const [zoom, setZoom] = useState(UIContextDefault.zoom)
    const [dragging, setDragging] = useState(UIContextDefault.dragging)

    const [toyIconActive, setToyIconActive] = useState(UIContextDefault.toyIconActive)
    const [hairIconActive, setHairIconActive] = useState(UIContextDefault.hairIconActive)
    const [thirtIconActive, setThirtIconActive] = useState(UIContextDefault.thirtIconActive)
    const [pantsIconActive, setPantsIconActive] = useState(UIContextDefault.pantsIconActive)
    const [faceIconActive, setFaceIconActive] = useState(UIContextDefault.faceIconActive)
    const [skinIconActive, setSkinIconActive] = useState(UIContextDefault.skinIconActive)

    const [teddyBodyActive, setTeddyBodyActive] = useState(UIContextDefault.teddyBodyActive)
    const [teddyFaceActive, setTeddyFaceActive] = useState(UIContextDefault.teddyFaceActive)
    const [teddyEarsActive, setTeddyEarsActive] = useState(UIContextDefault.teddyEarsActive)
    const [teddyPawsActive, setTeddyPawsActive] = useState(UIContextDefault.teddyPawsActive)
    const [thinkIconActive, setThinkIconActive] = useState(UIContextDefault.thinkIconActive)
    const [sayIconActive, setSayIconActive] = useState(UIContextDefault.sayIconActive)

    const changeToyIcon = (part:string) => {
        switch(part){
            case 'toyicon' : 
                const toyIcon = !toyIconActive
                setToyIconActive(toyIcon)
                setFaceIconActive(false)
                setHairIconActive(false)
                setThirtIconActive(false)
                setPantsIconActive(false)
                setSkinIconActive(false)
                break;
            case 'hair' : 
                const hairIcon = !hairIconActive
                setHairIconActive(hairIcon)
                setFaceIconActive(false)
                setToyIconActive(false)
                setThirtIconActive(false)
                setPantsIconActive(false)
                setSkinIconActive(false)
                break;
            case 'tshirt' : 
                const tshirtIcon = !thirtIconActive
                setThirtIconActive(tshirtIcon)
                setFaceIconActive(false)
                setToyIconActive(false)
                setHairIconActive(false)
                setPantsIconActive(false)
                setSkinIconActive(false)
                break;
            case 'pants' : 
                const pantsIcon = !pantsIconActive
                setPantsIconActive(pantsIcon)
                setFaceIconActive(false)
                setToyIconActive(false)
                setHairIconActive(false)
                setThirtIconActive(false)
                setSkinIconActive(false)
                break;
            case 'face' : 
                const faceIcon = !faceIconActive
                setFaceIconActive(faceIcon)
                setSkinIconActive(false)
                setToyIconActive(false)
                setHairIconActive(false)
                setThirtIconActive(false)
                setPantsIconActive(false)
            break; 
            case 'skin' : 
                const skinIcon = !skinIconActive
                setSkinIconActive(skinIcon)
                setFaceIconActive(false)
                setToyIconActive(false)
                setHairIconActive(false)
                setThirtIconActive(false)
                setPantsIconActive(false)
                break;      
        }
    }

    const changeTeddyIcon = (icon:string) => {
        switch(icon){
            case 'body' : 
                const teddyBody= !teddyBodyActive
                setTeddyBodyActive(teddyBody)
                setTeddyEarsActive(false)
                setTeddyFaceActive(false)
                setTeddyPawsActive(false)
                setThinkIconActive(false)
                setSayIconActive(false)
            break;
            case 'face' : 
                const teddyFace = !teddyFaceActive
                setTeddyFaceActive(teddyFace)
                setTeddyEarsActive(false)
                setTeddyBodyActive(false)
                setTeddyPawsActive(false)
                setThinkIconActive(false)
                setSayIconActive(false)
            break;
            case 'ears' : 
                const teddyEars = !teddyEarsActive
                setTeddyEarsActive(teddyEars)
                setTeddyFaceActive(false)
                setTeddyBodyActive(false)
                setTeddyPawsActive(false)
                setThinkIconActive(false)
                setSayIconActive(false)
                break;
            case 'paws' : 
                const teddyPaw = !teddyPawsActive
                setTeddyPawsActive(teddyPaw)
                setTeddyFaceActive(false)
                setTeddyEarsActive(false)
                setTeddyBodyActive(false)
                setThinkIconActive(false)
                setSayIconActive(false)
            break;
            case 'say' : 
                const sayIcon = !sayIconActive
                setSayIconActive(sayIcon)
                setTeddyPawsActive(false)
                setTeddyFaceActive(false)
                setTeddyEarsActive(false)
                setTeddyBodyActive(false)
                setThinkIconActive(false)
                break;
            case 'think' : 
                const thinkIcon = !thinkIconActive
                setThinkIconActive(thinkIcon)
                setTeddyPawsActive(false)
                setTeddyFaceActive(false)
                setTeddyEarsActive(false)
                setTeddyBodyActive(false)
                setSayIconActive(false)
            break;
        }
    }

    return (
        <UIContext.Provider 
            value={{
                dashboardState,
                hideMobileMenu,
                hideDashboard,
                hideLeftMenu,
                hideBottomMenu,
                hideToolsMenu,
                iconActive,                
                iconState,
                modeState,
                panning,
                iconToSocket,
                showToyPannel,
                loadingPannel,
                zoom,
                dragging,
                toyIconActive,
                hairIconActive,
                thirtIconActive,
                pantsIconActive,
                faceIconActive,
                skinIconActive,
                teddyEarsActive,
                teddyFaceActive,
                teddyBodyActive,
                teddyPawsActive,
                thinkIconActive,
                sayIconActive,
                setDashboardState,
                changeToyIcon,
                changeTeddyIcon,
                setHideMobileMenu,
                setHideDashboard,
                setHideLeftMenu,
                setHideBottomMenu,
                setHideToolsMenu,
                setIconActive,
                setIconState,
                setModeState,
                setPanning,
                setIconToSocket,
                setShowToyPannel,
                setLoadingPannel,
                setZoom,
                setDragging,
                setThinkIconActive,
                setSayIconActive
            }}>
            {children}
        </UIContext.Provider>)

}

export default UIContext

