import {useRef} from 'react';
import { IonContent } from '@ionic/react';

interface PlantModeProps {
    plantMode: Boolean,
    plantImages: any,
    sandboxElementClass: any,
    setSandboxImage: any
}
 
const PlantMode: React.FC<PlantModeProps> = ({plantMode, plantImages, sandboxElementClass, setSandboxImage}) => {

    const {cactus, flowers, mushroom, plant} = plantImages;

    const sandboxScroll = useRef<HTMLIonContentElement>(null);

    const scrollBy = (amount:number) => {
        if(sandboxScroll.current !== null){
            sandboxScroll.current.scrollByPoint(amount, amount, 1000)
        }
    }

    return ( 
        <IonContent className={plantMode ? 'sandbox-menu expanded' : 'sandbox-menu collapsed'} ref={sandboxScroll}>
            <div className="top-arrow" onClick={()=>scrollBy(-600)}>&raquo;</div>
            <div className="bottom-arrow" onClick={()=>scrollBy(600)}>&raquo;</div>
            <div className={`components ${plantMode}`}>
                <div className="images">
                    <div className={sandboxElementClass(cactus)}>
                        <img src={cactus} 
                            alt="cactus" 
                            draggable="false"
                            onClick={() => setSandboxImage(cactus)}
                        />
                    </div>
                    <div className={sandboxElementClass(flowers)}>
                        <img src={flowers} 
                            alt="flowers" 
                            draggable="false"
                            onClick={() => setSandboxImage(flowers)}
                        />
                    </div>
                    <div className={sandboxElementClass(mushroom)}>
                        <img src={mushroom} 
                            alt="mushroom" 
                            draggable="false"
                            onClick={() => setSandboxImage(mushroom)}
                        />
                    </div>
                    <div className={sandboxElementClass(plant)}>
                        <img src={plant} 
                            alt="plant" 
                            draggable="false"
                            onClick={() => setSandboxImage(plant)}
                        />
                    </div>
                </div>
            </div>
        </IonContent>
     );
}
 
export default PlantMode;