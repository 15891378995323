import {useRef} from 'react';
import { IonContent } from '@ionic/react';
import { CloseIcon, ArrowLeft  } from '../../assets';
import './privacyPolicy.css'

 
const PrivacyPolicy: React.FC = () => {

    const textScroll = useRef<HTMLIonContentElement>(null);

    const scrollBy = (amount:number) => {
        if(textScroll.current !== null){
        textScroll.current.scrollByPoint(amount, amount, 1000)
        }
    }

    return ( 
            <div className="privacy-container">
                <div className="privacy-policy">
                    <div className="scroll-buttons">
                        <div className="top-arrow" onClick={()=>scrollBy(-600)}>&laquo;</div>
                        <div className="bottom-arrow" onClick={()=>scrollBy(600)}>&laquo;</div>
                    </div>
                    <CloseIcon onClick={() => window.history.back()} />
                    <IonContent className="privacy-policy-text" ref={textScroll}>
                        <h1>Política de privacidad y cookies Mentapp</h1>
                        <br></br>
                        <br></br>
                        <h3>INFORMACIÓN BÁSICA</h3>
                        <p>Información básica sobre Protección de Datos con respecto a los usuarios
                        <br></br>
                        <br></br>
                        Responsable: CASARIEGORIVAS S.L. (en adelante, "Mentapp") 
                        <br></br>
                        <br></br>
                        Finalidad: Prestación del servicio contratado.
                        <br></br> 
                        <br></br>   
                        Legitimación: Ejecución de un contrato en el que el interesado es parte, aplicación a petición de este de medidas precontractuales y/o consentimiento del interesado. 
                        <br></br>
                        <br></br>
                        Destinatarios: Entidades colaboradoras necesarias para la prestación del servicio, Partners (empresas y profesionales que comercializan productos electrónicos) y destinatarios que el usuario haya consentido explícitamente.
                        <br></br>
                        <br></br>
                        Derechos: Derecho de información y de acceso a los datos personales, de rectificación y supresión, derecho a la limitación del tratamiento, derecho de oposición, al testamento digital y a no ser objeto de una elaboración de perfiles.
                        <br></br>
                        <br></br>
                        Información adicional: Puede consultar la información adicional y detallada sobre Protección de Datos en nuestra página web: <a href="https://mentapp.es/politica-de-privacidad.html">https://mentapp.es/politica-de-privacidad.html </a>
                        <br></br>
                        <br></br>
                        Procedencia: Los propios interesados.</p>
                        <h3>INFORMACIÓN ADICIONAL</h3>
                        <p>Información adicional sobre Protección de Datos
                        <br></br>
                        <br></br>
                        ¿Quién es el responsable del tratamiento de sus datos?
                        <br></br>
                        <br></br>
                        CASARIEGORIVAS S.L. con CIF B67881086 y domicilio en Calle Costabrava 39, piso 7, puerta A, Escalera Centro, 28034 Madrid  
                        <br></br>
                        <br></br>
                        ¿Quién es el Delegado de Protección de Datos y cómo puede ponerse en contacto con él?
                        <br></br>
                        <br></br>
                        El Delegado de Protección de Datos es la persona encargada de supervisar el cumplimiento de lo dispuesto en el Reglamento General de Protección de Datos. El Delegado de Protección de Datos de Mentapp es la entidad jurídica privada EUROPEAN DATA PROTECTION OFFICERS, S.L., CIF: B88047220. Se podrá contactar con él en la siguiente dirección de correo electrónico: contacto@delegadopd.com
                        <br></br>
                        <br></br>
                        ¿Con qué finalidad tratamos sus datos y por cuánto tiempo los conservaremos?
                        <br></br>
                        <br></br>
                        Tratamos los datos personales que nos facilitan nuestros usuarios (Terapeutas) con el fin de prestar el servicio contratado cuyas condiciones puede consultar aquí y entre las finalidades se incluyen: la prestación del servicio Mentapp; contestar a sus preguntas y brindar informaciones en respuesta a su consulta; para finalidades de marketing y publicidad sobre servicios que le podrían interesar; personalizar la información proporcionada y su experiencia de usuario; entender cómo el usuarios accede y utiliza el servicio prestado; administrar, monitorear y mejorar el servicio prestado y para otros fines de búsqueda y análisis; proteger a los usuarios, investigando fraudes, acosos y otros tipos de actividades ilegales. Con estas finalidades, se podrán tomar decisiones basadas únicamente en el tratamiento automatizado de sus datos, incluida la elaboración de perfiles. 
                        <br></br>
                        <br></br>
                        Los datos brindados se conservarán al menos mientras se mantenga la relación comercial y, en el caso de que el tratamiento se base en el consentimiento del interesado, hasta que el interesado retire su consentimiento o, posteriormente, durante los plazos legalmente exigidos.
                        <br></br>
                        <br></br>
                        Asimismo Mentapp puede actuar como encargado de tratamiento respecto a los datos de los Pacientes de los Terapeutas para la puesta a disposición de las Herramientas en modelo SaaS y siempre de acuerdo a las instrucciones del Responsable de tratamiento. 
                        <br></br>
                        <br></br>
                        ¿Cuál es la legitimación para el tratamiento de sus datos?
                        <br></br>
                        <br></br>
                        La legitimación principal es la necesidad del tratamiento para la ejecución de un contrato en el que el interesado es parte o para la aplicación a petición de este de medidas precontractuales y en algunos casos el consentimiento del usuario para el tratamiento de sus datos personales para uno o varios fines específicos, incluida la creación de perfiles.
                        <br></br>
                        <br></br>
                        El tratamiento de los datos personales de un menor de edad únicamente podrá fundarse en su consentimiento cuando sea mayor de catorce años. En el caso de que sea menor de catorce años, el tratamiento fundado en el consentimiento sólo será lícito si consta el del titular de la patria potestad o tutela.     
                        <br></br>
                        <br></br>
                        ¿A qué destinatarios se comunicarán sus datos?
                        <br></br>
                        <br></br>
                        Los datos personales únicamente se comunicarán a terceros, en concreto a las entidades que colaboran para la prestación del servicio, en calidad de encargados de tratamiento.
                        <br></br>
                        <br></br>
                        Al facilitarnos su información personal, nos autoriza expresamente a tratar y compartir su información con dichos colaboradores, en la medida que están directamente implicados en la prestación del servicio contratado.
                        <br></br>
                        <br></br>
                        Mentapp únicamente cederá tus datos a terceros en el caso de que des tu consentimiento explícito para ello. 
                        <br></br>
                        <br></br>
                        Aseguramos que estas entidades colaboradoras con las que compartimos su información personal garanticen un nivel de protección adecuado, según lo dispuesto en el Reglamento General de Protección de Datos.
                        <br></br>
                        <br></br>
                        Registrándose en Mentapp el usuario consiente y acepta que se podrán transmitir sus datos personales fuera del territorio del Espacio Económico Europeo para la correcta prestación del servicio contratado. Estas transferencias se pueden hacer a países como Suiza o Estados Unidos con los cuales existe una decisión de adecuación adoptada por la Comisión Europea (Privacy Shield). En ausencia de una decisión de adecuación, solo se podrá transmitir datos personales a un tercer país u organización internacional si se hubiera ofrecido garantías adecuadas y a condición de que los interesados cuenten con derechos exigibles y acciones legales efectivas.       
                        <br></br>
                        <br></br>
                        ¿Cuáles son sus derechos cuando nos facilite sus datos?
                        <br></br>
                        <br></br>
                        Derecho de información: cuando el interesado nos facilite sus datos personales, en el momento o con anterioridad en que estos se obtengan, le facilitaremos toda la información pertinente, según lo dispuesto en el Reglamento General de Protección de Datos.  
                        <br></br>
                        <br></br>
                        Derecho de acceso: el interesado que nos facilita sus datos tendrá derecho a obtener confirmación de si se están tratando o no datos personales que le conciernan y, en tal caso, derecho de acceso a los datos y a la información pertinente.
                        <br></br>
                        <br></br>
                        Derecho de rectificación: la persona interesada tendrá derecho a obtener sin dilación indebida la rectificación de los datos personales inexactos que le conciernan y derecho a que se completen los datos que sean incompletos, inclusive mediante una declaración adicional.
                        <br></br>
                        <br></br>
                        Derecho de supresión: el interesado tendrá derecho a obtener sin dilación indebida la supresión de los datos personales que le conciernan cuando, entre otros motivos, los datos ya no sean necesarios en relación con los fines para los que fueron recogidos o tratados.
                        <br></br>
                        <br></br>
                        Derecho a la limitación del tratamiento: en determinadas circunstancias, el interesado podrá solicitar la limitación del tratamiento de los datos personales que le conciernan, en cuyo caso únicamente los trataremos con el consentimiento del interesado o para la formulación, el ejercicio o la defensa de reclamaciones, o con miras a la protección de los derechos de otra persona física o jurídica o por razones de interés público importante.
                        <br></br>
                        <br></br>
                        Derecho de oposición: la persona interesada podrá oponerse en cualquier momento, por motivos relacionados con su situación particular, al tratamiento de sus datos personales basado en lo dispuesto en el artículo 6, apartado 1, letras e) o f), incluida la elaboración de perfiles sobre la base de dichas disposiciones. Dejaremos de tratar dichos datos, salvo por motivo legítimos imperiosos que prevalezcan sobre los intereses, los derechos y las libertades del interesado, o para la formulación, el ejercicio o la defensa de reclamaciones.
                        <br></br>
                        <br></br>
                        Derecho a no ser objeto de una elaboración de perfiles: el interesado tendrá derecho a no ser objeto de una  decisión basada únicamente en  el tratamiento automatizado, incluida la elaboración de perfiles. Cuando la decisión se base únicamente en el tratamiento automatizado, el interesado tendrá derecho a obtener intervención humana por parte del responsable, a expresar su punto de vista y a impugnar la decisión, si el tratamiento  es necesario para la celebración o la ejecución de un contrato entre el interesado y el responsable o se basa en el consentimiento explícito del interesado. No se aplicará tal derecho cuando la decisión automática se base en el consentimiento explícito del interesado, sea necesaria para la celebración o la ejecución de un contrato entre el responsable y el interesado o se base en un tratamiento fundamentado en la legislación vigente.
                        <br></br>
                        <br></br>
                        Derecho al testamento digital: el acceso a contenidos digitales sobre personas fallecidas se regirá de la siguiente forma: las personas vinculadas al fallecido (familiares o de hecho) podrán acceder al contenido digital del fallecido e impartir instrucciones en relación con el mismo (utilización, destino o supresión). Dicha posibilidad se encontrará vetada, en el caso de que el fallecido haya indicado lo contrario expresamente. En caso de existir testamento, podrá realizar lo anterior el albacea testamentario o la persona que haya designado el fallecido. Cuando la persona fallecida sea menor de edad o persona con discapacidad, las facultades podrán ser ejercidas por sus representantes legales o por el Ministerio Fiscal.
                        <br></br>
                        <br></br>
                        Cualesquiera de los derechos descritos con anterioridad deberán notificarse por escrito debidamente firmado a la siguiente dirección de correo electrónico, acreditando su identidad: info@mentapp.es.
                        <br></br>
                        <br></br>
                        Si como interesado considera que hay un problema con la forma en que estamos manejando sus datos, puede dirigir sus reclamaciones a nuestro Delegado de Protección de Datos (contacto@delegadopd.com) o a la autoridad de control en España, es decir la Agencia Española de Protección de Datos.
                        <br></br>
                        <br></br>
                        ¿Qué categorías de datos personales tratamos y cómo hemos obtenido sus datos?
                        <br></br>
                        <br></br>
                        Las categorías de datos que tratamos son las siguientes: datos de identificación, datos de contacto (teléfono, dirección postal y dirección electrónica), datos de geolocalización, datos de, imagen y perfiles en redes sociales, datos comportamentales.
                        <br></br>
                        <br></br>
                        Los datos personales que tratamos han sido facilitados por el interesado.</p>
                        <h3>Política de Cookies</h3>
                        <p>Mentapp hace uso de cookies, bien a través de medios propios, bien a través de terceros contratados para la prestación de servicios de medición, con relación a los usuarios que navegan en el Portal. Las cookies son ficheros enviados al navegador, mediante un servidor web, con la finalidad de registrar las actividades de los Usuarios durante su tiempo de navegación. El uso de las cookies permite que el servidor de Mentapp reconozca el navegador web utilizado por el Usuario con la finalidad de que la navegación sea más sencilla, permitiendo medir la audiencia y parámetros del tráfico, controlar el progreso y número de entradas, así como las operaciones más relevantes de los Usuarios. El Usuario tiene la posibilidad de configurar su navegador para ser avisado de la recepción de cookies e impedir su instalación en su navegador. Por favor, consulte las instrucciones y manuales de su navegador para ampliar esta información.
                        <br></br>
                        <br></br>
                        El conjunto de cookies utilizado se clasifica en las siguientes tipologías:
                        </p>
                        <ul>
                            <li>Técnica: permiten navegar o utilizar algunos servicios o procesos del sitio web</li>
                            <li>De personalización: definen algunas configuraciones regionales, como el idioma, y definen el tipo de navegador utilizado</li>
                            <li>De análisis: proporcionan información estadística sobre la actividad de los usuarios en nuestro sitio web con el fin de introducir mejoras, como modificar procesos, introducir información sobre búsquedas frecuentes, etc.</li>
                            <li>Publicitaria: son las que gestionan los espacios publicitarios y definen el contenido y la frecuencia de los anuncios, por ejemplo para que no se repitan los mismos</li>
                        </ul>
                        <p>Utilizamos el siguiente conjunto de cookies en nuestro website:</p>
                        <table>
                            <tr>
                                <td>A</td>
                                <td>B</td>
                                <td>C</td>
                            </tr>
                            <tr>
                                <td>Proveedor de la cookie</td>
                                <td>Descripción (función y propósito de la cookie)</td>
                                <td>Duración máxima de almacenamiento</td>
                            </tr>
                            <tr>
                                <td>facebook.com</td>
                                <td>Estos cookies se utilizan para que podamos ofrecer a los clientes de Mentapp los servicios que mejor satisfagan sus necesidades en este momento. Estos cookies se usan para configurar las campañas publicitarias en facebook.com y medir su efectividad. Toda la información sobre los usuarios es impersonal y es imposible identificar a los usuarios por ella.</td>
                                <td>La cookies puede eliminarse automáticamente después de que se cierra el navegador, la sesión se interrumpe o almacena durante hasta 3 meses.</td>
                            </tr>
                            <tr>
                                <td>Google Analytics</td>
                                <td></td>
                                <td>Estas cookies se almacenan de 1 minuto a 2 años. Asimismo, Google ofrece un “Complemento de inhabilitación para navegadores de Google Analytics” que puede descargarse en la siguiente dirección: https://tools.google.com/dlpage/gaoptout?hl=es</td>
                            </tr>
                        </table>
                        <p>Ten en cuenta que para poder utilizar determinados servicios y funcionalidades que te ofrecemos en Mentapp es necesario que tengas habilitadas las cookies en tu navegador de Internet. En concreto, mediante estas cookies técnicas, Mentapp, S.L. podrá identificarte como usuario registrado cada vez que accedas a Mentapp, permitiéndote así el acceso y uso a dichos servicios y funcionalidades.
                            <br></br><br></br>No obstante, recuerda que puedes permitir, bloquear o eliminar estas cookies cuando quieras a través de las opciones de configuración de tu dispositivo o terminal, así como de tu navegador de internet.
                            <br></br><br></br>Recuerda siempre acceder a la información de soporte más actualizada de dichos navegadores para conocer el modo en que puedes eliminar las cookies, dado que el modo puede diferir entre distintas versiones y sistemas operativos.
                            <br></br><br></br>En el caso de que utilices cualquier otro navegador de Internet, te recomendamos que consultes las distintas opciones de gestión de cookies que pudiesen encontrarse disponibles en el mismo en cada momento.
                            <br></br><br></br>Puedes consultar como hacerlo en cada navegador siguiendo los siguientes links:</p>
                        <ul>
                        <li>Si utilizas Google Chrome desde: <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a></li>
                        <li>Si utilizas Internet Explorer desde: <a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a></li>
                        <li>Si utilizas Firefox desde: <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></li>
                        <li>Si utilizas Safari desde: <a href="http://support.apple.com/kb/ph5042">http://support.apple.com/kb/ph5042</a></li>
                        </ul> 
                    </IonContent>    
                </div>                        
            </div>
         );
}
 
export default PrivacyPolicy;