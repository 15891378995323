import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
const { App: CapApp } = Plugins;

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    useEffect(() => {
      CapApp.addListener('appUrlOpen', (data: any) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = data.url.split('mentapp.es/j/').pop();
        if (slug) {
            history.push('/session-panel/'+slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, []);
  
    return null;
  };
  
  export default AppUrlListener;