import {useContext} from 'react';
import { Link } from 'react-router-dom';
import {IonCard,IonCardHeader, IonCardTitle, IonCardContent, IonGrid, IonRow, IonText, IonButton, IonRouterLink} from '@ionic/react';
import UIContext from '../../context/UIContext';
import {Logo, LogOut, StarRating, SessionMenu, HistoryMenu, AccountMenu, MenuCollapsedIcon, MenuCloseIcon} from '../../assets/index';

import './Sidebar.css'
import { useEffect } from 'react';

export interface SidebarProps {
    remainingDays: Number,
    userPlan: String
}

const Sidebar: React.FC<SidebarProps> = ({remainingDays, userPlan}) => {

    const {dashboardState, hideMobileMenu, setDashboardState, setHideMobileMenu} = useContext(UIContext);

    useEffect(()=>{
        console.log('remainingDays',remainingDays)
        if(remainingDays <= 0){
            console.log("error")
            window.location.href = '/login';
        }
    },[remainingDays]);

    const changeDashboardView = (value:string) => {
        setDashboardState(value)
        setHideMobileMenu(false)
    }

    return(

        <div className='sidebar-menu'>
            <IonGrid>
                <IonRow>
                        <Logo 
                            className="logo"
                            onClick={() => setDashboardState('session')}
                        />
                </IonRow>
                    <div className="sidemenu-icons-container">
                        <Link to="/dashboard">
                            <div 
                                className="sidemenu-icons"
                                onClick={() => setDashboardState('session')}
                            >
                                <SessionMenu 
                                    className='session-menu'
                                    fill={dashboardState === 'session' ? '#79CE67' : '#323232' }    
                                />
                                <IonText>
                                    <p>Sesiones</p>
                                </IonText>
                            </div>
                        </Link>
                            <div 
                                className="sidemenu-icons"
                                onClick={() => setDashboardState('history')}
                            >
                                <HistoryMenu 
                                    className='session-menu'
                                    fill={dashboardState === 'history' ? '#79CE67' : '#323232' }    
                                />
                                <IonText>
                                    <p>Historial</p>
                                </IonText>
                            </div>
                            <div 
                                className="sidemenu-icons"
                                onClick={() => setDashboardState('account')}
                            >
                                <AccountMenu 
                                    className='session-menu'
                                    fill={dashboardState === 'account' ? '#79CE67' : '#323232' }    
                                />
                                <IonText>
                                    <p>Mi cuenta</p>
                                </IonText>
                            </div>

                        <Link to="/feedback" >
                            <div className="sidemenu-icons valoration">
                                    <StarRating />
                                    <p>Valora Mentapp</p>
                            </div>
                        </Link>
                    </div>

                <div className="bottom-sidebar">
                    {(userPlan === 'free' || userPlan === null)
                        &&
                        <IonCard className="hide-if-short">
                            <IonCardHeader>
                                <IonCardTitle>{remainingDays} días <p>restantes de la Prueba Gratis</p></IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>Al finalizar este periodo no podrás acceder a tus sesiones</IonCardContent>
                            {/* <IonCardContent>Pasado el periodo de prueba no podrá acceder a las sesiones creadas</IonCardContent> */}
                            <IonButton fill="clear" className="button button-accent" href="/account-plan">Cambiar Plan</IonButton>
                        </IonCard>
                    }

                    <div className="logout">
                        <IonButton fill="clear"
                            href='/login'
                        >
                            <LogOut className='logout-menu'/>
                            <p>Log out</p>
                        </IonButton>
                    </div>
                </div>
            </IonGrid>
            
            <div className="menu-sidebar-mobile">
                {!hideMobileMenu ? <MenuCollapsedIcon onClick={() => setHideMobileMenu(true)}/> : <MenuCloseIcon onClick={() => setHideMobileMenu(false)}/> }
                {hideMobileMenu 
                    &&
                    <div className="menu-mobile-list">
                        <button onClick={() => changeDashboardView('session')}>Sesiones</button>
                        <button onClick={() => changeDashboardView('history')}>Historial</button>
                        <button onClick={() => changeDashboardView('account')}>Mi Cuenta</button>
                        <a href="/feedback">Valora Mentapp</a>
                        <a href="/login">Log out</a>
                    </div>
                }
            </div>

        </div>

    )
}

export default Sidebar;