import React, { useState, useEffect, useContext, CSSProperties} from 'react';
import { IonHeader, IonPage, IonFooter, isPlatform } from '@ionic/react';
import UIContext from '../../../context/UIContext';
import ToolsContext from '../../../context/ToolsContext';

import SessionService from '../../../service/sessions.service';
import SocketService from '../../../service/socket.service';

import TopSessionBar from '../../layout/TopSessionBar';
import LeftSessionBar from '../../layout/left-menu/LeftSessionBar';
import BottomToolsMenu from '../../layout/BottomToolsMenu';
import MentappDraw from '../app-tools/ToolsComponents/draw/MentappDraw';
import MentappCards from '../app-tools/ToolsComponents/cards/MentappCards';
import Mentapp3D from '../app-tools/ToolsComponents/clicks/Mentapp3D';
import SandboxComponent from '../app-tools/ToolsComponents/sandbox/SandboxComponent';
import DicesComponent from '../app-tools/ToolsComponents/dices/DicesComponent';
import TeddyComponent from '../app-tools/ToolsComponents/teddy/TeddyComponent';

import { versionNumber } from '../../shared/version/VersionNumber';

import {CursorIcon, MoveIcon, WarningCircle, ZoomInIcon, ZoomOutIcon} from './../../../assets/index';
import './sessionPanel.css';

import { Plugins } from '@capacitor/core';
import 'capacitor-jitsi-meet';
import Jitsi from 'react-jitsi';

// import { JitsiMeetAPI } from 'react-jitsi/dist/types';
import UserToolsContext from '../../../context/UserToolsContext';
import LoaderSpinner from '../../shared/spinner/Spinner';
import UserContext from '../../../context/UserContext';
import PatientsBottomToolsMenu from '../../layout/PatientsBottomToolsMenu';

const JitsiMeet = Plugins.Jitsi;
//const jitsiVideoDomain = 'meet.jit.si';
const jitsiVideoDomain = 'jitsi.mentapp.es';

const SessionPanel: React.FC= () => {

  const {session, setSession} = useContext(UserToolsContext)
  const {zoom, dragging, setZoom, setDragging} = useContext(UIContext)

  const [mode, setMode] = useState<string>('');
  const [isAuthorized, setIsAuthorized] = useState(false)
  //const [lastCursorSent, setLastCursorSent] = useState<number>(0);
  // const [dragging, setDragging] = useState(-1);
  const [sharedData, setSharedData] = useState<any>({});
  const [meetIframe, setMeetIframe] = useState<boolean>(false);
  const [roomName, setRoomName] = useState<string>('mentapp_123');
  const cursorBall = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    token ? setIsAuthorized(true): setIsAuthorized(false)
  }, [])

  useEffect(() => {
    let url = window.location+'';
    let code = url.split("/");
    let codeId = code[code.length-1];
    const rname = 'mentapp_'+codeId.toLowerCase();
    setRoomName(rname);
    hideLayouts()
    callSession()
    setLoadingPannel(true)
    socketService.setup( socketEventReceived, rname );
    setTimeout(()=>{ startCall(rname) }, 1000);
  }, []);

  useEffect(()=>{
    if (token === null && (mode==='' || mode === 'none')){
      endCall();
    }
    if (!(isPlatform('desktop') || isPlatform('mobileweb'))) {
      if (mode === 'videocall'){
        JitsiMeet.fullscreen({mode:'full'})
      } else {
        JitsiMeet.fullscreen({mode:'mini'})
      }
    }
  }, [mode])

  useEffect(()=>{
    if (sharedData.zoom !== undefined){
      setZoom(sharedData.zoom)
    }
  }, [sharedData.zoom])

  useEffect(()=>{
    if (sharedData.state !== undefined){
      statusLock(sharedData.state)
    }
  }, [sharedData.state])

  const startCall = async (codeId:string) => {
    if (isPlatform('desktop') || isPlatform('mobileweb')) {
      return startIframeCall(codeId);
    }
    const result = await JitsiMeet.joinConference({
       roomName: codeId, // room identifier for the conference
       url: 'https://'+jitsiVideoDomain, // endpoint of the JitsiMeet Meet video bridge,
       email: 'info@mentapp.es', // user's email
       startWithAudioMuted: false, // start with audio muted
       displayName: 'Mentapp', // user's display name
       startWithVideoMuted: false, // start with video muted
       chatEnabled: false, // enable Chat feature
       inviteEnabled: false, // enable Invitation feature
       callIntegrationEnabled: true,
       pipEnabled: true 
    });
    window.addEventListener('onConferenceJoined', (data) => {
        // do things here
        console.log('conf join', data)
    });
    window.addEventListener('onConferenceLeft', () => {
        // do things here
    });  
  }
  const endCall = async () => {
    await JitsiMeet.leaveConference();
  }
  const startIframeCall = (codeId:string) => {
    // activate component

    setRoomName(codeId);
    setMeetIframe(true);
    return true;
  }

  const {
    panning, 
    loadingPannel,
    setHideBottomMenu, 
    setHideToolsMenu, 
    setHideDashboard, 
    setShowToyPannel,
    setModeState, 
    setPanning, 
    setIconState,
    setLoadingPannel} = useContext(UIContext);
  const {setToolsMode, setIsDrawing, setShowSelectCard, setShowTeddyTool, setShowClicTool, setSandboxImage} = useContext(ToolsContext);
  const {setCardState, setDrawState, setDices, setSandboxState, setTeddyPos, setTeddyMessage, setToysState, setTeddyElementsArr} = useContext(UserToolsContext)
 
  const {token} = useContext(UserContext);
  const sessionService = new SessionService(); 
  const socketService = new SocketService(); 

  const hideLayouts = () => {
    setHideDashboard(true)
  }

  const callSession = () => {
    let url = window.location+'';
    let code = url.split("/");
    let codeId = code[code.length-1].split("?")[0];
    sessionService
      .getSessionByCode(codeId)
      .then((res:any) => {
          setSession(res.data.session)
          addSessionMode(res.data.session[0].tools)
           if(res.data.session[0].state){
              setLoadingPannel(false)
             loadSessionState(res.data.session[0].state)
           }
      })
      .catch((err:any) => {
          console.error("Error de conexión:", err)
      })
  }

  const loadSessionState = (savedState:any) => {
    if(savedState.card.cardState !== null){
      setCardState(savedState.card.cardState)
    }
    setDrawState(savedState.draw.drawState)
    setSandboxState(savedState.sandbox.sandBoxState)
    setTeddyPos(savedState.teddy.position)
    setTeddyElementsArr(savedState.teddy.elements)
    setTeddyMessage(savedState.teddy.message)
    setToysState(savedState.toy.toyState)
    setDices(savedState.dices.dicesState)
    setLoadingPannel(true)
  }

  const addSessionMode = (session:any[]) => {
    // session.includes('paint') ? setMode('draw') : setMode(session[0])
    setMode('videocall');
  } 

  const socketEventReceived = (data:any) => {
    //console.log(data)
    if (data.mode !== undefined && data.mode !== mode){
      setModeState(data.mode);
      setMode(data.mode)
    }
    if(data.state !== undefined){
      setSharedData({...sharedData, state: data.state});
    }
    if(data.icon !== undefined){
      setIconState(data.icon)
      setSharedData({...sharedData, icon: data.icon});
    }
    if(data.color !== undefined){
      setSharedData({...sharedData, color: data.color});
    }
    if (data.curve !== undefined){
      setSharedData({...sharedData, curve: data.curve, style: data.style});
    }
    if(data.clean){
      setSharedData({...sharedData, clean: true});
    }
    if (data.johari !== undefined){
      setSharedData({...sharedData, johari: data.johari});
    }
    if (data.zoom !== undefined){
      setSharedData({...sharedData, zoom: data.zoom});
    }
    if (data.position !== undefined){
      setSharedData({...sharedData, position: data.position});
    }
    if (data.cards !== undefined){
      setSharedData({...sharedData, cards: data.cards});
    }
    if (data.zIndex !== undefined){
      setSharedData({...sharedData, zIndex: data.zIndex });
    }
    if (data.singleCard !== undefined){
      setSharedData({...sharedData, singleCard: data.singleCard});
    }
    if (data.toys !== undefined){
      setSharedData({...sharedData, toys: data.toys});
    }
    if (data.toysState !== undefined){
      setSharedData({...sharedData, toys: data.toysState});
    }
    if (data.stair !== undefined){
      setSharedData({...sharedData, stair: data.stair});
    }
    if (data.toysMovement !== undefined){
      setSharedData({...sharedData, toysMovement: data.toysMovement});
    }
    if (data.toyFace !== undefined){
      setSharedData({...sharedData, toyFace: data.toyFace});
    }
    if (data.png !== undefined){
      setSharedData({...sharedData, png: data.png});
    }
    if (data.sandbox !== undefined){
      setSharedData({...sharedData, sandbox: data.sandbox});
    }
    if (data.teddy !== undefined){
      setSharedData({...sharedData, teddy: data.teddy});
    }
    if (data.playableTeddy !== undefined){
      setSharedData({...sharedData, playableTeddy: data.playableTeddy});
    }
    if (data.teddyMovement !== undefined){
      setSharedData({...sharedData, teddyMovement: data.teddyMovement});
    }
    if (data.teddyMessage !== undefined){
      setSharedData({...sharedData, teddyMessage: data.teddyMessage});
    }
    if (data.dices !== undefined){
      setSharedData({...sharedData, dices: data.dices});
    }
    if (data.orbit !== undefined){
      setSharedData({...sharedData, orbit: data.orbit});
    }
    if (data.cursor !== undefined){
      if (cursorBall.current !== null){
        cursorBall.current.style.left = data.cursor.x+'px';
        cursorBall.current.style.top = data.cursor.y+'px';
      }
    }
    if (data.value !== undefined){
      if(data.component === 'teddy') 
      {
        setShowTeddyTool(data.value)
      }
      if(data.component === 'cards'){
        setShowSelectCard(data.value)
        setHideBottomMenu(data.value)
      }
      if(data.component === 'toy'){
        setShowClicTool(data.value)
        setHideBottomMenu(data.value)
        setShowToyPannel(false)
      }
      setHideToolsMenu(data.zoom)
    }
    if (data.checkToy !== undefined){
      setSharedData({...sharedData, checkToy: data.checkToy});
    }
    if (data.doneToy !== undefined){
      setSharedData({...sharedData, doneToy: data.doneToy});
    }
  }

  const onUpdate = (newData:any)=>{
    socketService.sendMessage('event', {...newData, gid:roomName, mode});
  }
    
  const containerChanged = (mode:string) => {
    socketService.sendMessage('event', {mode, gid:roomName})
    setMode(mode);
    setModeState(mode)
  }

  const statusLock = (mode:string) => {
    setSession((s:any)=>{
      let newSession = [...s];
      newSession[0].status = mode;
      return newSession;
    })
  }

  const changeIcon = (icon:any) => {
    socketService.sendMessage('event', {icon, gid:roomName})
  }
  
  const showMenus = (value:Boolean, component:string, zoom:Boolean) => {
    socketService.sendMessage('event', {value, gid:roomName, component, zoom})
  }

  const handleJitsiAPI = (api:any)=>{
    api.addListener('videoConferenceLeft', (ev:any)=>{
      setMeetIframe(false);
    })
    api.addListener('participantJoined', (ev:any)=>{
      let currentParticipantsCount = api.getNumberOfParticipants()
      // avoid more than 2 user in meeting
      if(currentParticipantsCount > 2){
        console.log('KICKING....', ev)
        api.executeCommand('kickParticipant', ev.id)
      }
    })
  }

  let lastCursorSent:number = 0;
  const mouseMoved = (e:any) => {
    if (lastCursorSent < new Date().getTime() - 180) {
      lastCursorSent = new Date().getTime();
      socketService.sendMessage('event', {cursor:{x:e.nativeEvent.clientX, y:e.nativeEvent.clientY}, gid:roomName})
    }
  }
  
  const applyZoomIn = () => {
    if(zoom <= 1.8){
        setZoom(zoom + 0.2)
        onUpdate({zoom: zoom + 0.2})
      }  
    }

  const applyZoomOut = () => {
    if(zoom >= 0.4){
      setZoom(zoom - 0.2)
      onUpdate({zoom:zoom - 0.2})
    }
  }

  const changeDragging = () => {
    const nd = dragging === 1 ? -1 : 1
    const np = dragging === 1 ? true : false
    setDragging(nd);
    setPanning(np);
  }

  const canvasStyle : CSSProperties = {
    transform: `scale(${zoom})`
  }

  return (
    <>
    { 
      ((!isAuthorized && (session[0]?.status === 'Finalizada')) || (mode === 'none' && !isAuthorized))
        ?
        <div className="final-notice">
          <h2>La sesión ha finalizado</h2>
          <p><a href='/'>Volver al inicio</a></p>
        </div>
        :
      mode!=='none'
        &&
        <>
      <div ref={cursorBall} className="ball-cursor">
        <CursorIcon />
      </div>
            <>
              <IonPage onMouseMove={(e)=>mouseMoved(e)}>
                {(mode!=='none' || (isAuthorized && session[0]?.status !== 'Finalizada')) &&
                  <IonHeader className="session-header">
                    <TopSessionBar endCall={endCall} sessionCode={session[0]?.code} sessionId={session[0]?.id} sessionUser={session[0]?.userId} status={session[0]?.status} onSelectionChange={containerChanged} onStatusLock={statusLock} onUpdate={onUpdate} />
                  </IonHeader>
                }
                  {((mode !== 'cards') && (mode !== 'videocall')) && <LeftSessionBar onSelectionChange={containerChanged} onUpdate={onUpdate} mode={mode} iconChange={changeIcon} sharedData={sharedData} /> }
                  {(mode === 'draw' ||  mode === 'sandbox')
                      &&
                      <div className="zoom-tools">
                        <ZoomInIcon onClick={() => {
                          applyZoomIn()
                        }}/>
                        <ZoomOutIcon onClick={() =>{ 
                          applyZoomOut()
                        }}/>
                      </div>
                  }
                        <div className="advert-mobile-screen"><WarningCircle /><p>Pon el dispositivo en posición horizontal para usar las herramientas</p></div>
                          <div className="box" style={canvasStyle}>
                          {!loadingPannel ? <LoaderSpinner /> :
                              <>
                                { mode === 'draw' && <MentappDraw zoom={zoom} dragging={dragging} setDragging={setDragging} onUpdate={onUpdate} sharedData={sharedData}/>}
                                { mode === 'sandbox' && <SandboxComponent  zoom={zoom} dragging={dragging} setDragging={setDragging}  onUpdate={onUpdate} sharedData={sharedData} />}
                              </>                   
                          }
                            </div>        
                        <div>
                            { mode === 'card' && <MentappCards onUpdate={onUpdate} sharedData={sharedData} showMenus={showMenus}/>}
                            { mode === 'toy' && <Mentapp3D onUpdate={onUpdate} sharedData={sharedData} showMenus={showMenus}/>}
                            { mode === 'dice' && <DicesComponent onUpdate={onUpdate} sharedData={sharedData.dices} />}
                            { mode === 'teddy' && <TeddyComponent onUpdate={onUpdate} sharedData={sharedData} showMenus={showMenus}/>}
                        </div>
                {(mode!=='none' || (token !== null && session[0]?.status !== 'Finalizada')) &&
                  <IonFooter className="session-panel-footer">

                      {token !== null
                        ?
                        <BottomToolsMenu tools={session[0]?.tools} showMenus={showMenus} onSelectionChange={containerChanged}/>
                        :
                        <PatientsBottomToolsMenu tools={session[0]?.tools} showMenus={showMenus} onSelectionChange={containerChanged}/>
                      }

                      {(mode === 'draw' || mode === 'toy' || mode==='sandbox')
                        &&
                      <div className="zoom-tools">
                        <MoveIcon className="move-icon" 
                            onClick={() => {
                              setIsDrawing(false)
                              setToolsMode('')
                              setSandboxImage('')
                              setIconState('draggable')
                              changeDragging()
                            }}
                            fill={panning ? '#363636' : '#79CE67'}
                        />
                      </div>
                      }
                  </IonFooter>
                }
                { meetIframe && mode!=='none' && 
                <div className={(mode==='videocall')?'videocall-active':''}><Jitsi roomName={roomName}
                displayName="Mentapp"
                onAPILoad={handleJitsiAPI} 
                domain={jitsiVideoDomain}
                  config={{ 
                    enableDisplayNameInStats:false, 
                    enableWelcomePage: false, 
                    enableCalendarIntegration: false, 
                    defaultLanguage:'es',
                    liveStreamingEnabled: false,
                  }} 
                  interfaceConfig={{
                    SHOW_BRAND_WATERMARK: false,
                    SHOW_JITSI_WATERMARK: false,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    SHOW_POWERED_BY: false,
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    TOOLBAR_TIMEOUT: 1000,
                    INITIAL_TOOLBAR_TIMEOUT: 0,
                    TOOLBAR_ALWAYS_VISIBLE: false,
                    SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                    TOOLBAR_BUTTONS: ['microphone', 'camera', 'hangup'],
                    VIDEO_QUALITY_LABEL_DISABLED: true,
                    DEFAULT_BACKGROUND: '#ffffff',
                    filmStripOnly: true,
                    LANG_DETECTION: false,
                  }}
                  /></div> }
              </IonPage>
            </>

            <div className='version-code-session'>
                <p>V. {versionNumber()}</p>
            </div>
      </>
    } 

    
    </>
  );
};

export default SessionPanel;
