import './theme/main-styles.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';

import {useState} from 'react';
import { Route, Redirect } from 'react-router-dom';

import {ToolsContextProvider} from '../src/context/ToolsContext';
import { UIContextProvider } from './context/UIContext';
import { UserContextProvider } from './context/UserContext';
import { UserToolsContextProvider } from './context/UserToolsContext';
import {ModalContextProvider} from './context/ModalContext';

import { IonApp, IonRouterOutlet, IonNav } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Login from './components/auth/Login';
import SignIn from './components/auth/Signin';
import SignInEholo from './components/auth/SigninEholo';
import SignUp from './components/auth/Signup';
import LinkingEholo from './components/auth/LinkingEholo';
import ForgottenPassword from './components/auth/ForgottenPassword';
import PrivacyPolicy from './components/auth/PrivacyPolicy';
import PrivacyTerms from './components/auth/PrivacyTerms';
import ResetPassword from './components/auth/ResetPassword';
import VerifyEmail from './components/auth/VerifyEmail';
import ConfirmEmail from './components/auth/ConfirmEmail';
import AccountPlan from './components/auth/AccountPlan';
import Dashboard from './components/pages/dashboard/Dashboard';
import SessionPanel from './components/pages/session/SessionPanel';
import WaitingRoom from './components/auth/WaitingRoom';
import FeedBack from './components/shared/feedback/Feedback';
import AppUrlListener from './AppUrlListener';

const App: React.FC = () => {

 const [state, setState] = useState<any>({
   token: window.sessionStorage.getItem('jwt'),
   userId: window.sessionStorage.getItem('userID'),
   login: window.sessionStorage.getItem('jwt') ? true : false,
   verify: window.sessionStorage.getItem('verUser') ? true : false,
   verifySuscription: window.sessionStorage.getItem('verSus') ? true : false
 })
 
 const signIn = (token:any, state:any, verify: Boolean, verifySuscription: any) => {
    setState({...state, 
        token: token, 
        login : state, 
        verify: verify, 
        verifySuscription: verifySuscription
    })
 }
 
 let allowAppAccess = (state.verify && state.login && state.verifySuscription) ? true : false

  return(

    <IonApp>
      <IonNav>
        <UserContextProvider>
          <UIContextProvider>
            <ToolsContextProvider>
              <UserToolsContextProvider>
              <ModalContextProvider>
                  <IonReactRouter>
                    <AppUrlListener></AppUrlListener>
                    <IonRouterOutlet>
                      <Route exact path='/' render={() => !state.token ? <Login signIn={signIn}/> : <Redirect to='/dashboard' />} />
                      <Route exact path='/login'  render={() =>  <Login signIn={signIn}/> } />
                      <Route exact path='/acceso'  render={() =>  <SignIn signIn={signIn}/> } />
                      <Route exact path='/acceso-eholo'  render={() =>  <SignInEholo signIn={signIn}/> } />
                      <Route exact path='/linking-eholo'  render={() =>  <LinkingEholo/> } />
                      <Route exact path='/reset-password'  render={() =>  <ForgottenPassword /> } />
                      <Route exact path='/registro'  render={() =>  <SignUp signIn={signIn}/> } />
                      <Route exact path='/verificar-email' component={ VerifyEmail } />
                      <Route exact path='/verificacion/:id' component={ ConfirmEmail } />
                      <Route exact path='/politica-privacidad' component={ PrivacyPolicy } />
                      <Route exact path='/terminos' component={ PrivacyTerms } />
                      <Route exact path='/reset-password/:token' component={ ResetPassword } />
                      <Route exact path='/waiting-room' component={ WaitingRoom } />
                      <Route exact path='/feedback' component={ FeedBack } />
                      <Route exact path="/session-panel/:id" component={SessionPanel}></Route>
                      <Route exact path='/account-plan'  render={() => state.login && state.verify ? <AccountPlan signIn={signIn}/> : <Redirect to='/login' />} />
                        <>
                          {allowAppAccess
                              ?
                              <>
                                <div id="dashboard-container">
                                    <Route exact path='/dashboard' render={() =>  <Dashboard signIn={signIn}/> } />
                                </div>
                              </>
                              : 
                          <Redirect to='/login' />
                          }
                        </>
                    </IonRouterOutlet>
                  </IonReactRouter>
              </ModalContextProvider>
              </UserToolsContextProvider>
            </ToolsContextProvider>
          </UIContextProvider>
        </UserContextProvider>
      </IonNav>
    </IonApp>
    
  )

};

export default App;
