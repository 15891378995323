import {useRef} from 'react';
import { IonContent } from '@ionic/react';

interface BuildingsModeProps {
    buildingMode: Boolean,
    buildingImages: any,
    sandboxElementClass: any,
    setSandboxImage: any
}
 
const BuildingsMode: React.FC<BuildingsModeProps> = ({buildingMode, buildingImages, sandboxElementClass, setSandboxImage}) => {

    const {building, caramelCastle, house, sandCastle, skyscraper} = buildingImages;

    const sandboxScroll = useRef<HTMLIonContentElement>(null);

    const scrollBy = (amount:number) => {
        if(sandboxScroll.current !== null){
            sandboxScroll.current.scrollByPoint(amount, amount, 1000)
        }
    }

    return ( 
        <IonContent className={buildingMode ? 'sandbox-menu expanded' : 'sandbox-menu collapsed'} ref={sandboxScroll}>
            <div className="top-arrow" onClick={()=>scrollBy(-600)}>&raquo;</div>
            <div className="bottom-arrow" onClick={()=>scrollBy(600)}>&raquo;</div>
            <div className={`components ${buildingMode}`}>
                <div className="images">
                    <div className={sandboxElementClass(building)}>
                        <img src={building} 
                            alt="building" 
                            draggable="false"
                            onClick={() => {
                                setSandboxImage(building)
                                sandboxElementClass(building)
                            }}
                        />
                    </div>
                    <div className={sandboxElementClass(caramelCastle)}>
                        <img src={caramelCastle} 
                            alt="caramel-castle" 
                            draggable="false"
                            onClick={() => setSandboxImage(caramelCastle)}
                        />
                    </div>
                    <div className={sandboxElementClass(house)}>
                        <img src={house} 
                            alt="house" 
                            draggable="false"
                            onClick={() => setSandboxImage(house)}
                        />
                    </div>
                    <div className={sandboxElementClass(sandCastle)}>
                        <img src={sandCastle} 
                            alt="sand-castle" 
                            draggable="false"
                            onClick={() => setSandboxImage(sandCastle)}
                        />
                    </div>
                    <div className={sandboxElementClass(skyscraper)}>
                        <img src={skyscraper} 
                            alt="skyscraper" 
                            draggable="false"
                            onClick={() => setSandboxImage(skyscraper)}
                        />
                    </div>
                </div>
            </div>
        </IonContent>
     );
}
 
export default BuildingsMode;