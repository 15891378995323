import {useContext} from 'react';
import UIContext from './../../../context/UIContext'
import LeftMenuDraw from './leftMenuDraw/LeftMenuDraw';
import LeftMenuDices from './leftMenuDices/LeftMenuDices';
import LeftMenuToy from './leftMenuToy/LeftMenuToy';
import LeftMenuSandbox from './leftMenuSandbox/LeftMenuSandbox';
import LeftMenuTeddy from './leftMenuTeddy/LeftMenuTeddy';

import './leftSessionBar.css';
export interface LeftBarProps {
    onSelectionChange: any,
    onUpdate: any,
    iconChange: any,
    sharedData: any
    mode: any,
}

const LeftSessionBar: React.FC<LeftBarProps> = ({onSelectionChange, onUpdate, iconChange, sharedData, mode}) => {

    const {hideLeftMenu} = useContext(UIContext)
    
    return(
        <>
            <div className={!hideLeftMenu ? `left-bar-container ${mode}` : 'left-bar-container-none'}>
                { mode === 'draw'  
                    && 
                <LeftMenuDraw onSelectionChange={onSelectionChange} onUpdate={onUpdate} sharedData={sharedData}/> 
                }
                { mode === 'dice' 
                    && 
                <LeftMenuDices onUpdate={onUpdate} /> 
                }
                { mode === 'toy' 
                    && 
                <LeftMenuToy  onUpdate={onUpdate} sharedData={sharedData}/> 
                }
                { mode === 'sandbox' 
                    && 
                <LeftMenuSandbox iconChange={iconChange}/> 
                }
                { mode === 'teddy' 
                    && 
                <LeftMenuTeddy iconChange={iconChange} onUpdate={onUpdate}/> 
                }
            </div>
        </>
    )
}

export default LeftSessionBar;