export const calculateDate = (date:any) => {

    let finalDate
    const updateDate = new Date (date)
    const day = updateDate.getDate()
    const month = updateDate.getMonth() + 1
    const year = updateDate.getFullYear()
    return finalDate = `${day}/${month}/${year}`

}

export const calculateHour = (date: any) => {

    let finalHour
    const updateDate = new Date (date)
    const hour = updateDate.getUTCHours();
    const minutes = (updateDate.getMinutes()<10?'0':'') + updateDate.getMinutes();
    return finalHour = `${hour}:${minutes}`
}