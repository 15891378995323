import axios from 'axios'

export default class SessionService {
    constructor() {
        this.apiHandler = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            withCredentials: true
        })
    }

    createSession = credentials => this.apiHandler.post('api/sessions/create-session', credentials)
    
    getUserSessions = (userId, token) => this.apiHandler.get(`api/sessions/${userId}/dashboard`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })

    getSessionByCode = (code) => this.apiHandler.get(`api/sessions/${code}/get-session`)

    getSessionByStatus = (status) => this.apiHandler.get(`api/sessions/${status}/get-session-status`)

    updateSession = async (id, state) => this.apiHandler.put(`api/sessions/${id}/update-session`, state)

    deleteSession = (sessionId, userId, token) => this.apiHandler.delete(`api/sessions/${sessionId}/delete-session/${userId}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }       
    })

}