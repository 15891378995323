import * as React from 'react';
import AuthService from '../../service/auth.service';

import {IonButton} from '@ionic/react';
import { ArrowBack } from '../../assets';
import { Form } from 'react-bootstrap'
import { useState } from 'react';


const PasswordForget: React.FC = () => {

    const [email, setEmail] = useState<string>('')

    const [error, setError] = useState<any>({
        active: false,
        errorMessage: ''
    })

    const [response, setResponse] = useState<any>({
        restorePassword: false,
        responseMessage: ''
    })

    const handleChange = (e:any) => setEmail(e.target.value);

    const handleSubmit = ( e : any) => {
        e.preventDefault();
        const authService = new AuthService();
        authService
            .forgottenPassword({email})
            .then((res:any) => {
                setResponse({restorePassword: true, responseMessage: res.data.message})
            })
            .catch((err:any)=> {
                console.error(err)
            })
    }

    return (
        <div className="login" scroll-y="false">
            <div className="session-form">
                <div className="signin">
                    <div className="back-icons">
                        <ArrowBack /><a href="/">Volver atrás</a>
                    </div>
                {!response.restorePassword 
                    ?
                    <>
                            <h2>Introduce tu email y te mandaremos un enlace para restaurar tu contraseña</h2>
                            <Form onSubmit={handleSubmit} className="signin-form">                    
                            <Form.Group controlId='email'>
                                <Form.Control 
                                    type='email'
                                    name='email'
                                    value={email} 
                                    placeholder="Correo electrónico"
                                    onChange={ handleChange }
                                    autoComplete="off"
                                    required
                                />
                            </Form.Group>

                            {error.active && <p className="error-message">{error.errorMessage}</p>}
                            <IonButton fill="clear" type="submit" className="button button-primary">Restaurar contraseña</IonButton>
                            </Form>
                    </>
                    :
                    <>
                        <h3>{response.responseMessage}</h3>
                    </>
                }
                </div>
            </div>
        </div>
     );
}
 
export default PasswordForget;