import React, { useState } from 'react';
import './CardComponent.css';
interface CardProps {
  card: any
  select: Boolean,
  image: any,
  id: number,
  onStartDrag: (e:any, index:number)=>void,
  onDrag: (e:any, index:number)=>void,
  onEndDrag: (e:any, index:number)=>void,
}

const CardComponent: React.FC<CardProps> = ({card, select, image, id, onStartDrag, onDrag, onEndDrag})  => {

  const [isDragging, setIsDragging] = useState(false)

    const callStartDrag = (e:any)=>{
      onStartDrag(e, id)
      setIsDragging(true)
    }
    const callDrag = (e:any)=>{
      onDrag(e, id)
    }
    const callEndDrag = (e:any)=>{
      onEndDrag(e, id)
      setIsDragging(false)
    }

  return (
    <>
      {select &&
        <div 
            className={isDragging ? 'card dragging' : 'card'}
            style={{position: 'absolute', top:card.py, left:card.px, zIndex:card.z}} 
            onMouseDown={callStartDrag}
            onTouchStart={callStartDrag}
            onMouseUp={callEndDrag}
            onTouchEnd={callEndDrag}
            onMouseMove={callDrag}
            onTouchMove={callDrag}
            onMouseOut={callEndDrag}
          >
          <img draggable="false" src={image} alt="card"/>
        </div>
      }
    </>
  );
};

export default CardComponent;
