import {useState, useContext} from 'react';
import UserContext from '../../../context/UserContext';
import SessionService from '../../../service/sessions.service';
import { Form } from 'react-bootstrap'
import { IonCol, IonRow, IonText, IonButton } from '@ionic/react';

import {    
    CardsIcon,
    ClicksIcon,
    DiceIcon,
    SandboxIcon,
    PaintIcon,
    TeddyIcon,
    VideocallIcon
} from '../../../assets/index';

import './nextSessionForm.css'

interface NextSessionFormProps {
    setAddNextSession:any
    setCounter: any,
    counter: any
}
 
const NextSessionForm: React.FC<NextSessionFormProps> = ({setAddNextSession, setCounter, counter}) => {

    const {userId} = useContext(UserContext)
    const generateSessionCode = () => {
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result= '';
        for ( let i = 0; i < 6; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
            result.trim()
        }
        return result
    }  
    const [state, setState] = useState<any>({
        code: generateSessionCode(),
        name: '',
        date: '',
        time: '',
        tools: [],
    });
    
    const handleChange = (e: React.FormEvent<HTMLInputElement>): void  => setState({ ...state, [e.currentTarget.name]: e.currentTarget.value }); 
    
    const handleSubmit = (e:any) => {
        e.preventDefault();
        const sessionService = new SessionService();  
        const {code, name, date, time, tools} = state;
        sessionService
            .createSession({code, name, date, time, tools, userId})
            .then((res:any) => console.log(res))
            .catch((err:any) => {console.error(err)})
        setCounter(counter + 1)
    }

    const addTool = (tool: any) => {
        if(!state.tools.includes(tool)){
            setState({...state, tools : [...state.tools, tool] })
        }
        else{
            let filteredTools = state.tools.filter((e:any) => e !== tool)
            setState({...state, tools : [...filteredTools] })
        }
    }

    return ( 
        <>
        <Form onSubmit={handleSubmit}>
        <div className="next-session">
                    <div className="next-sessions-form-codes">
                        <div className="next-session-form-codes">
                                <div className="form-group name">
                                    <p>Nombre de la sesión</p>
                                    <Form.Control className='input'
                                        type='text'
                                        name='name'
                                        autoComplete="off"
                                        placeholder="Nombre sesión"
                                        onChange={ (e) =>  handleChange((e as any)) }
                                    />
                                </div>
                        </div>

                        <div className="next-session-form-codes">
                                <div className="form-group date">
                                    <p>Fecha</p>
                                    <Form.Control className='input'
                                        type='date'
                                        name='date'
                                        placeholder='fecha'
                                        autoComplete="off"
                                        onChange={ (e) =>  handleChange((e as any)) }
                                    />
                                </div>
                                <div className="form-group time">
                                <p>Hora</p>
                                <Form.Control className='input'
                                    type='time'
                                    name='time'
                                    autoComplete="off"
                                    onChange={ (e) =>  handleChange((e as any)) }
                                    />
                                </div>
                        </div>
                    </div>
                        <div className="next-session-tools-container">
                            <IonText>Selecciona las herramientas que deseas que pueda controlar el paciente.</IonText>
                            <IonRow>
                                <IonCol size="12">
                                            <CardsIcon className="iconButton"
                                                onClick={() => addTool('cards')}
                                                fill={state.tools.includes('cards') ? '#363636' : '#36363625' }

                                            />
                                            <PaintIcon className="iconButton"
                                                onClick={() => addTool('paint')}
                                                fill={state.tools.includes('paint') ? '#363636' : '#36363625' }
                                            />
                                            <DiceIcon className="iconButton"
                                                onClick={() => addTool('dice')}
                                                fill={state.tools.includes('dice') ? '#363636' : '#36363625' }
                                            />
                                            <ClicksIcon className="iconButton"
                                                onClick={() => addTool('clicks')}
                                                fill={state.tools.includes('clicks') ? '#363636' : '#36363625' }
                                            />
                                            <SandboxIcon className="iconButton"
                                                onClick={() => addTool('sandbox')}
                                                fill={state.tools.includes('sandbox') ? '#363636' : '#36363625' }
                                            />
                                            <TeddyIcon className="iconButton"
                                                onClick={() => addTool('teddy')}
                                                fill={state.tools.includes('teddy') ? '#363636' : '#36363625' }
                                            />
                                            <VideocallIcon className="iconButton"
                                                onClick={() => addTool('videocall')}
                                                fill={state.tools.includes('videocall') ? '#363636' : '#36363625' }
                                            />
                                </IonCol>
                            </IonRow>
                        </div>
        </div>
                <div className="next-session-btn">
                    <IonButton
                        disabled={!state.name || !state.date || !state.time} 
                        fill="clear"
                        type="submit"
                        className={`button button-primary`}
                        onClick={ () => setAddNextSession(false)}
                        >Guardar</IonButton>
                    <IonButton
                        fill="clear"
                        className={`button button-accent`}
                        onClick={ () => setAddNextSession(false)}
                        >Cancelar</IonButton>
                </div>
                </Form>
            </>
     );
}
 
export default NextSessionForm;