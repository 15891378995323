import {useState, useContext} from 'react';
import UserToolsContext from '../../../../context/UserToolsContext';
import {RollDicesIcon} from './../../../../assets/index'

interface LeftMenuDicesProps {
    onUpdate: any
}
 
const LeftMenuDices: React.FC<LeftMenuDicesProps> = ({onUpdate}) => {

    const {dices, setDices, setDicesState} = useContext(UserToolsContext)

    const faceMultipliers = [[0,0,0],[1,0,4],[2,0,2],[3,0,0],[2,1,2],[3,3,3]];
    const rollTime = 4*Math.PI;


    const randomizeAllDices = () =>{
        const newDices = [...dices];
        newDices.map(dices => {
            const face = Math.floor(Math.random()*6);
            const factor = Math.floor(Math.random()*3)
            return  dices.rot = [faceMultipliers[face][0] * Math.PI/2 + rollTime*factor,faceMultipliers[face][1] * Math.PI/2 + rollTime*factor,faceMultipliers[face][2] * Math.PI/2 + rollTime*factor]
        });
        setDicesState(newDices)
        onUpdate({dices:newDices});    
    }

    return ( 
        <div className="roll-dices">
            <RollDicesIcon onClick={() => randomizeAllDices()}/>
        </div>
     );
}
 
export default LeftMenuDices;