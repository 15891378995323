import {useState, useEffect, useContext} from 'react';
import SessionService from '../../../service/sessions.service';
import UserContext from '../../../context/UserContext';
import ModalContext from '../../../context/ModalContext';

import Modal from './../../shared/modal/Modal'
import UserHistoryCard from './UserHistoryCard';
import { IonHeader, IonGrid } from '@ionic/react';

import { versionNumber } from '../../shared/version/VersionNumber';

import './userHistory.css'
export interface UserHistoryProps {
    
}
 
const UserHistory: React.FC<UserHistoryProps> = () => {

    const [pastSessions, setPastSessions] = useState<any>([])

    const {token, userId} = useContext(UserContext);
    const {showModal, modalMessage} = useContext(ModalContext);
    const sessionService = new SessionService()

    useEffect(() => {
        sessionService
        .getUserSessions(userId, token)
        .then((res:any) => {
            sortedHistorySessions(res.data.userSessions)
        })
        .catch((err:any) => {
            console.error(err)
        }) 
    }, []);

    const sortedHistorySessions = (sessions:any) => {

        let previousSess:any = [];  
        const actualDate = Date.now();
        const today = new Date(actualDate)
        let dayInMillis=24*3600000;
          
        sessions.forEach((session:any) => {
            let days1=Math.floor(today.getTime()/dayInMillis);
            let days2=Math.floor(new Date(session.date).getTime()/dayInMillis);
            if(days1 > days2){
                previousSess.push(session);
            }
        });    
        const sortedSessions = previousSess.sort((a:any, b:any) => (a.date > b.date) ? 1 : -1)
        setPastSessions(sortedSessions)
    };

    const deleteSession = (id:any) => {
        const sessionService = new SessionService();
        sessionService
            .deleteSession(id, userId, token)
            .then((res:any) => {
                sortedHistorySessions(res.data.userSessions)
                modalMessage({type: 'success', modalTitle: 'Sesión eliminada', modalSubtitle: 'Tu sesión ha sido eliminada correctamente'})
            })
            .catch((err:any) => {
                console.error(err)
            }) 
    }

    return ( 
        <>
        <div className="history-content">
            <IonHeader>
                <h1>Historial de sesiones</h1>
            </IonHeader>
            <div className="sessions-table">
            {pastSessions.length > 0 
                ?
                <IonGrid className="ion-margin">
                        {pastSessions &&
                        pastSessions.map((session:any) => 
                        <UserHistoryCard
                            key={session.id}
                            id={session.id}
                            code={session.code}
                            name={session.name}
                            date={session.date}
                            deleteSession={deleteSession}
                        />
                        )}
                </IonGrid>
                :
            <h1 className="modal">Aún no tienes ninguna sesión en el historial</h1>
            }
                {showModal && <Modal /> }
            </div>
            <div className='version-code'>
                <p>V. {versionNumber()}</p>
            </div>
        </div>
        </>
     );
}
 
export default UserHistory;