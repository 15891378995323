import {useState, useContext} from 'react';
import ModalContext from '../../../context/ModalContext'

import { calculateDate, calculateHour } from '../../shared/dates-utils/SessionsCardsUtil';

import { IonButton, IonGrid, IonRow, IonText} from '@ionic/react';
import { google } from "calendar-link";
import { BinIcon, ChevronDown, ChevronUp, CopyIcon, CalendarIcon } from '../../../assets/index'; 

export interface Props{
    id: any,
    code: string,
    name: string,
    date: any,
    deleteSession: any,
}

const TodaySessionsDashboardCard: React.FC<Props>= ({ id, code, name, date, deleteSession}) => {

    console.log(id)

    const [displayLink, setDisplayLink] = useState<Boolean>(false)
    const {modalMessage, modalContain, deleteModal, setDeleteModal, showDeleteModal, setShowDeleteModal} = useContext(ModalContext);

    const calendarDate = new Date(date)
    calendarDate.setHours(calendarDate.getHours() - 1)

    const sessionLink = `https://live.mentapp.es/session-panel/${code}`;
    const event:any = {
        title: name,
        description: `El código para entrar a la sesión es ${code}. Puedes acceder también a través de este link: ${sessionLink}`,
        start: calendarDate,
        duration: [1, "hour"],
    };

    google(event)


    //COPY FUNCTIONALITY
    const copyToClipboard = () => {
        modalMessage({...modalContain, type: 'success', modalTitle: "Enlace copiado", modalSubtitle: "Su enlace se ha copiado con éxito"})
        const aux = document.createElement('input');
        aux.setAttribute("value", sessionLink);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }

    // const copyEvent = () => {
    //     modalMessage({...modalContain, type: 'success', modalTitle: "Invitación copiada", modalSubtitle: "Su invitación se ha copiado con éxito"})
    //     const aux = document.createElement('input');
    //     aux.setAttribute("value", google(event));
    //     document.body.appendChild(aux);
    //     aux.select();
    //     document.execCommand("copy");
    //     document.body.removeChild(aux);
    // }

    const finalDate = calculateDate(date)
    const finalHour = calculateHour (date)

    return (
            <IonGrid className="ion-margin">
                <div className="session-row" onClick={() => {!displayLink ? setDisplayLink(true) : setDisplayLink(false)}}>
                    <div className="desktop-dashboard">
                        <div className="date"><p>{finalDate}</p></div>
                        <div className="hour"><p>{finalHour}</p></div>
                    </div>
                    <div className="code"><p>{code}</p></div>
                    <div className="name"><p>{name}</p></div>
                    <div className="display">
                        {   !displayLink 
                            ?
                            <ChevronDown onClick={() => setDisplayLink(true)}/>
                            :
                            <ChevronUp onClick={() => setDisplayLink(false)}/>
                        }
                    </div>
                </div>
                <IonRow className={displayLink ? "next-session-link open" : "next-session-link close"}>
                    
                    <div className="link">
                            <IonText>
                                {`https://live.mentapp.es/session-panel/${code}`}
                                <CopyIcon className="copy-icon" onClick={() => copyToClipboard()}/>
                            </IonText>
                        <div className="sessions-button">
                            {/* <CalendarIcon className="calendar-icon" onClick={() => copyEvent()}/> */}
                            <a href={`${google(event)}`} target="_blank" rel=" noopener noreferrer"><CalendarIcon /></a>
                            <IonButton fill="clear" className="button button-accent" href={`/session-panel/${code}`}>Entrar en la sesión</IonButton>
                            <BinIcon className="bin-icon" onClick={() => {setDeleteModal({...deleteModal, show: true, id: id})}}/>
                        </div>
                    </div>

                    <div className={(deleteModal.show && deleteModal.id === id) ? 'modal delete': 'modal none'}>
                        <h3>Eliminar la sesión</h3>
                        <p>¿Está seguro de que desea eliminar la sesión?</p>
                        <div className="delete-modals-buttons">
                            <IonButton fill="clear" className="button button-primary" onClick={() => {
                                deleteSession(deleteModal.id)
                                setShowDeleteModal(false)
                            }}>Continuar</IonButton>
                            <IonButton fill="clear" className="button button-tertiary" onClick={() => setDeleteModal({...deleteModal, show: false, id: 0})}>No, gracias</IonButton>
                        </div>
                    </div>
                </IonRow>
            </IonGrid>
     );
}
 
export default TodaySessionsDashboardCard;