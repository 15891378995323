/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { Sphere, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useEffect } from 'react'

import {  TextureLoader } from 'three';

function Model(props, ref)  {

  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/Family.glb')

  const itemsColors = {
    red: nodes.male_feet.material,
    yellow: nodes.girl_body.material,
    gray: nodes.male_hair.material,
    brown: materials.Material__Click_Boy_H,
    orange: materials.Material__Click_Boy_T,
    white: nodes.female_feet.material,
    pink: materials.Material__Click_Adult,
    blue: materials.Material__Click_Boy_L,
    wh: faceTexture[0],
    wa: faceTexture[1],
    wn: faceTexture[2],
    ws: faceTexture[3],
    bh: faceTexture[4],
    ba: faceTexture[5],
    bn: faceTexture[6],
    bs: faceTexture[7]
  };
  // const itemsColorsIndexes = ['red','yellow','gray','brown','orange','white','pink', 'blue'];
  
  const getItemColor = (item) => {
    return itemsColors[props.colors[item]]
  }
  
  const [target, setTarget] = useState({
    x:props.movement[0],
    y:props.movement[1],
    z:props.movement[2],
    r:props.movement[3],
    larm:props.movement[4],
    rarm:props.movement[5],
    legs:props.movement[6]
  });
  const [colors, setColors] = useState({
    hairColor: getItemColor(0),
    bodyColor: getItemColor(1),
    feetColor: getItemColor(2),
    skinMaterial: getItemColor(3),
  });
  const [position, setPosition] = useState({
    x:0,
    y:0,
    z:0,
    r:0,
    legs:1,
    larm:Math.PI / 2,
    rarm:Math.PI / 2,
    colors:colors
  });
  
  const [selected, setSelected] = useState(props.selectToy);
  // const [drag, setDrag] = useState({x:0,y:0,active:false});


  useEffect(() => {
    setTarget({
      x:props.movement[0],
      y:props.movement[1],
      z:props.movement[2],
      r:props.movement[3],
      larm:props.movement[4],
      rarm:props.movement[5],
      legs:props.movement[6]
    })
  }, [props.movement])

  useEffect(() => {
    setColors({
      hairColor: getItemColor(0),
      bodyColor: getItemColor(1),
      feetColor: getItemColor(2),
      skinMaterial: getItemColor(3),
    })
  }, [props.colors])

  useEffect(() => {
    setSelected(props.selectToy)
  }, [props.selectToy])
  

  useImperativeHandle(ref, () => ({
    move (x,y) { move(x,y) },
    rotate (rad) { rotate(rad) },
    rotateArm (l,r) { rotateArm(l,r) },
    changeTexture (iteration, part) { changeTexture(iteration, part) }
  }), [target,position])

  useFrame((state, delta) => {
    const l = position.legs + (target.legs - position.legs)/5
    const nstate = {
      x: position.x + (target.x - position.x)/10,
      y:position.y + (target.y - position.y)/10,
      z:position.z + (target.z - position.z)/10,
      r:position.r + (target.r - position.r)/10,
      larm:position.larm + (target.larm - position.larm)/10,
      rarm:position.rarm + (target.rarm - position.rarm)/10,
      legs:isNaN(l) ? 1 : l,
    }
    setPosition({...state, ...nstate});
  })


  const select = (ev)=>{
    // setTarget({
    //   x:10,
    //   y:0,
    //   z:0,
    //   r:1
    // })
    ev.stopPropagation();
    const newToys = props.toys.map((toy)=> { 
      if(toy.id === props.index){
        return {...toy, selected: !selected }
      } else {
        return {...toy, selected: false }
      }
    })

    props.changeToys(newToys)
  }


  const move = (x,z)=>{
    if(selected){
      const updates = {
        x: Math.min(Math.max(target.x+x,-20),20), 
        z:  Math.min(0,target.z+z)
      }
      setTarget({...target, ...updates });
      const newToys = props.toys.map((toy)=> { 
        if(toy.id === props.index){
          return {...toy, ...updates}
        } else {
          return toy
        }
      })
      props.changeToys(newToys)
    }
  }

  const rotate = (rad)=>{
    if(selected){
      setTarget({...target, r:target.r+rad });
      const newToys = props.toys.map((toy)=> { 
        if(toy.id === props.index){
          return {...toy, r:target.r+rad}
        } else {
          return toy
        }
      })
      props.changeToys(newToys)
    }
  }

  const moveLegs = (ev)=>{
    ev.stopPropagation();
    let currLegs = target.legs;
    if (isNaN(currLegs)) currLegs =  1;
    const updates = {
      legs: (currLegs+1)%3
    }
    setTarget({...target, ...updates});
    const newToys = props.toys.map((toy)=> { 
      if(toy.id === props.index){
        return {...toy, ...updates}
      } else {
        return toy
      }
    })
    props.changeToys(newToys)
  }

  const rotateArm = (ev, l, r)=>{
    ev.stopPropagation();
    const updates = {
      larm:target.larm-l,
      rarm:target.rarm-r,
    }
    setTarget({...target, ...updates});
    const newToys = props.toys.map((toy)=> { 
      if(toy.id === props.index){
        return {...toy, ...updates}
      } else {
        return toy
      }
    })
    props.changeToys(newToys)
  }
  const changeTexture = (newColor, part)=>{
    if(selected){
      // Change color based on part and iteration
      //const index = iteration % itemsColorsIndexes.length;
      let updates = {colors: props.colors};
      switch(part){
        case 'hair':
          updates.colors[0] = newColor
          setColors({...colors, hairColor:itemsColors[newColor]})
          break;
        case 'body':
          updates.colors[1] = newColor
          setColors({...colors, bodyColor:itemsColors[newColor]})
          break;
        case 'feet':
          updates.colors[2] = newColor
          setColors({...colors, feetColor:itemsColors[newColor]})
          break;
        case 'skin':
          updates.colors[3] = newColor
          setColors({...colors, skinMaterial:itemsColors[newColor]})
          break;
        default:
          break;
        }

      const newToys = props.toys.map((toy)=> { 
        if(toy.id === props.index){
          return {...toy, ...updates}
        } else {
          return toy
        }
      })
      props.changeToys(newToys)
    }
  }
  const remove = () => {
    props.onRemove();
  }

  const getLegsRotation = (legs)=>{
    if (isNaN(legs)) return Math.PI/2
    return (2 - legs) * Math.PI/2;
  }

  return (
    <>
    { props.type !== 'none' && 
    <group ref={group} {...props} dispose={null} 
    position={[position.x,position.y-8,position.z-10]}
    rotation={[0, position.r, 0]}>
      { props.type === 'male' &&
      <>
        <mesh castShadow 
          geometry={nodes.male_feet.geometry}
          material={colors.feetColor}
          position={[0.93, 3.14, 0.06]}
          rotation={[getLegsRotation(position.legs), 0, 0]}
          onClick={(event) => moveLegs(event)} 
        />
        <mesh castShadow 
          geometry={nodes.male_core.geometry}
          material={colors.bodyColor}
          position={[7.2, 0, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh castShadow 
          geometry={nodes.male_hair.geometry}
          material={colors.hairColor}
          position={[0.02, 4.89, -0.02]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh castShadow 
          geometry={nodes.male_face.geometry}
          position={[0.02, 4.89, -0.02]}
          rotation={[Math.PI / 2, 0, 0]}
        >
        <meshStandardMaterial attach="material" map={colors.skinMaterial} />
          
          </mesh>
        <mesh castShadow 
          geometry={nodes.male_body.geometry}
          material={colors.bodyColor}
          position={[7.2, 0, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          onClick={(event) => select(event)} 
        />
        <mesh castShadow 
          geometry={nodes.male_right.geometry}
          material={colors.bodyColor}
          position={[-0.59, 5.32, -0.01]}
          rotation={[position.rarm, 0, 0]}
          onClick={(event) => rotateArm(event, 0,Math.PI/5)} 
        />
        <mesh castShadow 
          geometry={nodes.male_left.geometry}
          material={colors.bodyColor}
          position={[0.65, 5.32, -0.01]}
          rotation={[position.larm, 0, 0]}
          onClick={(event) => rotateArm(event, Math.PI/5,0)} 
        />
      </>
  }
  { props.type === 'female' &&
  <>
      <mesh castShadow
        geometry={nodes.female_face.geometry}
        position={[2.4, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        >
        <meshStandardMaterial attach="material" map={colors.skinMaterial} />
      </mesh>
      <mesh castShadow
        geometry={nodes.female_left.geometry}
        material={colors.bodyColor}
        position={[0.63, 5.32, -0.01]}
        rotation={[position.larm, 0, 0]}
        onClick={(event) => rotateArm(event, Math.PI/5,0)} 
      />
      <mesh castShadow
        geometry={nodes.female_right.geometry}
        material={colors.bodyColor}
        position={[-0.62, 5.32, -0.01]}
        rotation={[position.rarm, 0, 0]}
        onClick={(event) => rotateArm(event, 0,Math.PI/5)} 
      />
      <mesh castShadow
        geometry={nodes.female_feet.geometry}
        material={colors.feetColor}
        position={[-0.89, 3.12, 0.07]}
        rotation={[getLegsRotation(position.legs), 0, 0]}
        onClick={(event) => moveLegs(event)} 
      />
      <mesh castShadow
        geometry={nodes.female_core.geometry}
        material={colors.bodyColor}
        position={[2.4, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh castShadow
        geometry={nodes.female_body.geometry}
        material={colors.bodyColor}
        position={[2.4, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        onClick={(event) => select(event)} 
      />
      <mesh castShadow
        geometry={nodes.female_hair.geometry}
        material={colors.hairColor}
        position={[2.4, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      </>
  }
  { props.type === 'boy' &&
  <>
      <mesh castShadow
        geometry={nodes.boy_face.geometry}
        position={[-1.97, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      >
      <meshStandardMaterial attach="material" map={colors.skinMaterial} />
      </mesh>
      <mesh castShadow
        geometry={nodes.boy_core.geometry}
        material={colors.bodyColor}
        position={[-1.97, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh castShadow
        geometry={nodes.boy_hair.geometry}
        material={colors.hairColor}
        position={[-1.97, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh castShadow
        geometry={nodes.boy_right.geometry}
        material={colors.bodyColor}
        position={[-0.54, 3.96, 0]}
        rotation={[position.rarm, 0, 0]}
        onClick={(event) => rotateArm(event, 0, Math.PI/5)} 
      />
      <mesh castShadow
        geometry={nodes.boy_left.geometry}
        material={colors.bodyColor}
        position={[0.55, 3.96, 0]}
        rotation={[position.larm, 0, 0]}
        onClick={(event) => rotateArm(event, Math.PI/5, 0)} 
      />
      <mesh castShadow
        geometry={nodes.boy_feet.geometry}
        material={colors.feetColor}
        position={[-0.77, 2.07, 0.07]}
        rotation={[getLegsRotation(position.legs), 0, 0]}
        onClick={(event) => moveLegs(event)} 
      />
      <mesh castShadow
        geometry={nodes.boy_hand_right.geometry}
        //material={materials.Material__Click_Adult}
        position={[-0.54, 3.96, 0]}
        rotation={[position.rarm, 0, 0]}
        onClick={(event) => rotateArm(event, 0,Math.PI/5)} 
      >
      <meshStandardMaterial attach="material" map={colors.skinMaterial} />
      </mesh>
      <mesh castShadow
        geometry={nodes.boy_hand_left.geometry}
        position={[0.55, 3.96, 0]}
        rotation={[position.larm, 0, 0]}
        onClick={(event) => rotateArm(event, Math.PI/5,0)} 
        >
        <meshStandardMaterial attach="material" map={colors.skinMaterial} />
        </mesh>
      <mesh castShadow
        geometry={nodes.boy_body.geometry}
        material={colors.bodyColor}
        position={[-1.97, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        onClick={(event) => select(event)} 
      />
    </>
  }
  { props.type === 'girl' &&
  <>
      <mesh castShadow
        geometry={nodes.girl_feet.geometry}
        material={colors.feetColor}
        position={[-0.77, 2.07, 0.07]}
        rotation={[getLegsRotation(position.legs), 0, 0]}
        onClick={(event) => moveLegs(event)} 
      />
      <mesh castShadow
        geometry={nodes.girl_face.geometry}
        position={[-6.12, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      >
      <meshStandardMaterial attach="material" map={colors.skinMaterial} />
      </mesh>
      <mesh castShadow
        geometry={nodes.girl_body.geometry}
        material={colors.bodyColor}
        position={[-6.12, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        onClick={(event) => select(event)} 
      />
      <mesh castShadow
        geometry={nodes.girl_hair.geometry}
        material={colors.hairColor}
        position={[-6.12, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh castShadow
        geometry={nodes.girl_core.geometry}
        material={colors.bodyColor}
        position={[-6.12, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <mesh castShadow
        geometry={nodes.girl_right.geometry}
        material={colors.bodyColor}
        position={[-0.53, 3.93, 0]}
        rotation={[position.rarm, 0, 0]}
        onClick={(event) => rotateArm(event, 0,Math.PI/5)} 
      />
      <mesh castShadow
        geometry={nodes.girl_hand_right.geometry}
        material={materials.Material__Click_Adult}
        position={[-0.53, 3.93, 0]}
        rotation={[position.rarm, 0, 0]}
        onClick={(event) => rotateArm(event, Math.PI/5,0)} 
      />
      <mesh castShadow
        geometry={nodes.girl_hand_left.geometry}
        material={materials.Material__Click_Adult}
        position={[0.58, 3.93, 0]}
        rotation={[position.larm, 0, 0]}
        onClick={(event) => rotateArm(event, 0,Math.PI/5)} 
      />
      <mesh castShadow
        geometry={nodes.girl_left.geometry}
        material={colors.bodyColor}
        position={[0.58, 3.93, 0]}
        rotation={[position.larm, 0, 0]}
        onClick={(event) => rotateArm(event, Math.PI/5,0)} 
      />
    </>
  }
  {(selected) && 
  <>
    <Sphere material={itemsColors['brown']} position={[0,8.2,0]} scale={[0.3 , 0.3 , 0.3]}
      onClick={(event) => remove()} 
    >
        <meshPhongMaterial attach="material" color="#79CE67" />
    </Sphere>

    {/* <Sphere position={[0,0,0]} scale={[2.3 , 0.1 , 2.3]}
      onPointerDown={(event) => startDrag(event)}
      onPointerUp={(event) => endDrag(event)}
      onPointerMove={(event) => moveDrag(event)}
      onPointerLeave={(event) => endDrag(event)}
    >
        <meshPhongMaterial attach="material" color="#79CE67" />
    </Sphere>
    */}
  </>
  }
    </group>
}
    </>
  )
}

useGLTF.preload('/assets/Family.glb')

const faceTexture = [];
faceTexture.push(new TextureLoader().load(`/assets/textures/face01.png`));
faceTexture.push(new TextureLoader().load(`/assets/textures/face02.png`));
faceTexture.push(new TextureLoader().load(`/assets/textures/face03.png`));
faceTexture.push(new TextureLoader().load(`/assets/textures/face04.png`));
faceTexture.push(new TextureLoader().load(`/assets/textures/face11.png`));
faceTexture.push(new TextureLoader().load(`/assets/textures/face12.png`));
faceTexture.push(new TextureLoader().load(`/assets/textures/face13.png`));
faceTexture.push(new TextureLoader().load(`/assets/textures/face14.png`));
export default forwardRef( Model )