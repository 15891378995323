import React, {useState} from 'react';

type Props = {
    children: JSX.Element
}
interface ToolsContextInterface{
    toolsMode: string,
    isDrawing: Boolean,
    color: any,
    erase: Boolean,
    drawState: string,
    shapeState: string,
    textState: string,
    showTextModal: Boolean,
    lineWidth: Number,
    startPositions: any,
    canvasCache: any,
    cleanCanvas: Boolean,
    callCleanCanvas: any,
    showSelectCard: Boolean
    sandboxImage: any,
    showTeddyTool: Boolean,
    showClicTool: Boolean,
    checkedToy: any,
    showTeddyText: Boolean,
    messageSend: Boolean,
    changeToyTexture: any,
    skinColor: string,
    setToolsMode: any,
    setIsDrawing: any,
    changeColor: any, 
    setErase: any,
    setDrawState: any,
    setShapeState: any,
    setTextState: any,
    setShowTextModal: any,
    setLineWidth: any,
    setStartPositions: any,
    setCanvasCache: any,
    setCleanCanvas: any,
    setShowSelectCard: any,
    setSandboxImage: any,
    setShowTeddyTool: any,
    setShowClicTool: any,
    setCheckedToy: any,
    setShowTeddyText: any,
    setMessageSend: any,
    setChangeToyTexture: any,
    setSkinColor: any,
}

export const toolsContextDefault = {
    toolsMode: '',
    isDrawing: false,
    color: '#FBE415',
    erase: false,
    drawState: 'paint',
    shapeState: 'square',
    textState: 'none',
    showTextModal: false,
    lineWidth: 4,
    startPositions: {
        startX: 0,
        startY: 0
    },
    canvasCache: {},
    cleanCanvas: false,
    callCleanCanvas: false,
    setToolsMode: '',
    setDrawMode: {},
    setIsDrawing: false,
    changeColor: '#FBE415',
    showSelectCard: false,
    sandboxImage:'',
    showTeddyTool: false,
    showClicTool: false,
    checkedToy: {
        boy: false,
        girl: false,
        girl2: false,
        male: false,
        male2: false,
        female: false,
        female2: false
    },
    changeToyTexture: {
        part: '',
        color: '',
        change: false,
    },
    skinColor: '#F9EEEE',
    showTeddyText: false,
    messageSend: false,
    setErase: false,
    setDrawState: 'paint',
    setShapeState: 'square',
    setTextState: 'none',
    setShowTextModal: false,
    setLineWidth: 5,
    setStartPositions: {},
    setCanvasCache: {},
    setCleanCanvas: false,
    setShowSelectCard: false,
    setSandboxImage: '',
    setShowTeddyTool: false,
    setShowClicTool: false,
    setCheckedToy: {},
    setShowTeddyText: false,
    setMessageSend: false,
    setChangeToyTexture: '',
    setSkinColor: '#F9EEEE',
}

const ToolsContext = React.createContext<ToolsContextInterface>(toolsContextDefault)

export function ToolsContextProvider ({children}: Props) {

    //DRAW
    const [toolsMode, setToolsMode] = useState(toolsContextDefault.toolsMode)
    const [isDrawing, setIsDrawing] = useState(toolsContextDefault.isDrawing)
    const [color, setColor] = useState(toolsContextDefault.color)
    const [erase, setErase] = useState(toolsContextDefault.erase);
    const [drawState, setDrawState] = useState(toolsContextDefault.drawState)
    const [shapeState, setShapeState] = useState(toolsContextDefault.shapeState)
    const [textState, setTextState] = useState(toolsContextDefault.textState)
    const [showTextModal, setShowTextModal] = useState(toolsContextDefault.showTextModal)
    const [lineWidth, setLineWidth] = useState(toolsContextDefault.lineWidth)
    const [startPositions, setStartPositions] = useState(toolsContextDefault.startPositions)
    const [canvasCache, setCanvasCache] = useState(toolsContextDefault.canvasCache)
    const [cleanCanvas, setCleanCanvas] = useState(toolsContextDefault.cleanCanvas)
    const callCleanCanvas = () => {
        setCleanCanvas(true)
        setTimeout(() => {
            setCleanCanvas(false)
        }, 1000);
    }

    //CARDS
    const [showSelectCard, setShowSelectCard] = useState(toolsContextDefault.showSelectCard)

    //SANDBOX
    const [sandboxImage, setSandboxImage] = useState(toolsContextDefault.sandboxImage)

    //TEDDY
    const [showTeddyTool, setShowTeddyTool] = useState(toolsContextDefault.showTeddyTool)
    const [showTeddyText, setShowTeddyText] = useState(toolsContextDefault.showTeddyText)
    const [messageSend, setMessageSend] = useState(toolsContextDefault.messageSend)

    //CLICS
    const [showClicTool, setShowClicTool] = useState(toolsContextDefault.showClicTool)
    const [checkedToy, setCheckedToy] = useState(toolsContextDefault.checkedToy)
    const [changeToyTexture, setChangeToyTexture] = useState(toolsContextDefault.changeToyTexture)
    const [skinColor, setSkinColor] = useState(toolsContextDefault.skinColor)

    const changeColor = (color:any) => {
        setColor(color)
    }
    
    return (
        <ToolsContext.Provider 
            value={{
                toolsMode,
                isDrawing,
                color,
                erase,
                drawState,
                shapeState,
                textState,
                showTextModal,
                lineWidth,
                startPositions,
                canvasCache,
                cleanCanvas,
                showSelectCard,
                sandboxImage,
                showTeddyTool,
                showClicTool,
                checkedToy,
                showTeddyText,
                messageSend,
                changeToyTexture,
                skinColor,
                callCleanCanvas,
                setToolsMode,
                setIsDrawing,
                changeColor,
                setErase,
                setDrawState,
                setShapeState,
                setTextState,
                setShowTextModal,
                setLineWidth,
                setStartPositions,
                setCanvasCache,
                setCleanCanvas,
                setShowSelectCard,
                setSandboxImage,
                setShowTeddyTool,
                setShowClicTool,
                setCheckedToy,
                setShowTeddyText,
                setMessageSend,
                setChangeToyTexture,
                setSkinColor,
            }}>
            {children}
        </ToolsContext.Provider>)

}

export default ToolsContext

