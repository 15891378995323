import { useState, useContext } from 'react';
import ToolsContext from '../../../../context/ToolsContext';
import UIContext from '../../../../context/UIContext';
import DrawModes from './DrawModes';
import ShapesModes from './ShapesModes';
import TextModes from './TextModes'
import ColorPicker from './ColorPicker';
import {
    DrawIcon, 
    TextIcon, 
    SquareIcon,
    SquareIconFill,
    CircleIcon,
    CircleIconFill,
    TriangleIcon, 
    TriangleIconFill,
    EraseIcon, 
    ColorIcon, 
    BrushIcon, 
    MarkerIcon,
    TextHighIcon,
    TextSmallIcon,
    JohariIcon} from './../../../../assets/index'; 

import './leftMenuDraw.css'
import UserToolsContext from '../../../../context/UserToolsContext';
export interface LeftMenuDrawProps {
    onSelectionChange: any,
    onUpdate: any,
    sharedData: any,
}

const LeftMenuDraw: React.FC<LeftMenuDrawProps> = ({onSelectionChange, onUpdate, sharedData}) => {

    const {
        color, 
        drawState, 
        textState, 
        showTextModal,
        shapeState,
        setLineWidth,
        setErase,
        setToolsMode,
        setShowTextModal,
        setTextState,
        callCleanCanvas,
        } = useContext(ToolsContext)
    const {iconState, setIconState, setPanning, setDragging} = useContext(UIContext)
    const {showJohari, setShowJohari} = useContext(UserToolsContext)

    const [textModes, setTextModes] = useState(false)
    const [shapeModes, setShapeModes] = useState(false)
    const [drawModes, setDrawModes] = useState(false)
    const [colorActive, setColorActive] = useState(false)

    const changeMode = (mode:any) => {
        onSelectionChange(mode)
    }

    const changeDrawState = (state:string) => {
        switch(state){
            case 'paint':
                setDrawModes(!drawModes)
                setTextModes(false)
                setTextState('none')
                setShapeModes(false)
                setPanning(true)
                setDragging(-1)
                setErase(false)
                setColorActive(false)
                setShowTextModal(false)
                setToolsMode(state)
                return;
            case 'text':
                setTextModes(!textModes)
                setDrawModes(false)
                setShapeModes(false)
                setPanning(true)
                setDragging(-1)
                setErase(false)
                setColorActive(false)
                setShowTextModal(!showTextModal)
                setToolsMode(state)
                return;
            case 'shapes':
                setShapeModes(!shapeModes)
                setTextModes(false)
                setTextState('none')
                setShowTextModal(false)
                setDrawModes(false)
                setPanning(true)
                setDragging(-1)
                setColorActive(false)
                setErase(false)
                setToolsMode(state)
                setLineWidth(4)
                return;
            case 'erase':
                setIconState('erase')
                setShowTextModal(false)
                setTextModes(false)
                setTextState('none')
                setErase(true)
                setPanning(true)
                setDragging(-1)
                setToolsMode('paint')
                return;
            case 'color':
                setColorActive(!colorActive)
                setPanning(true)
                setDragging(-1)
                setDrawModes(false)
                setTextModes(false)
                setTextState('none')
                setShowTextModal(false)
                setShapeModes(false)
                return;
            // case 'johari':
            //     setDrawModes(false)
            //     setTextModes(false)
            //     setShapeModes(false)
            //     setPanning(true)
            //     setDragging(-1)
            //     setErase(false)
            //     setColorActive(false)
            //     setToolsMode(state)
            //     return;
        }
    }

    const changeJohari = () => {
        const newJohari = !showJohari;
        setShowJohari(newJohari);
        onUpdate({johari:newJohari});
    }

    let latestTouch:any;
    const doubleTouch = () =>  {
        var now = new Date().getTime();
        var timesince = now - latestTouch;
        if((timesince < 600) && (timesince > 0)){
            callCleanCanvas()
        }
        latestTouch = new Date().getTime();
    }

    return(
        <>
            <div className="left-menu-container">
                    {
                        drawState === 'paint' ? 
                            <DrawIcon 
                                onClick={() => {changeDrawState('paint')}}
                                fill={iconState === 'paint' ? '#79CE67' : '#363636'}
                            /> 
                        : drawState === 'brush' ? 
                            <BrushIcon 
                                onClick={() => {changeDrawState('paint')}}
                                fill={iconState === 'brush' ? '#79CE67' : '#363636'}
                            /> 
                        : drawState === 'marker' && 
                            <MarkerIcon 
                                onClick={() => {changeDrawState('paint')}}
                                fill={iconState === 'marker' ? '#79CE67' : '#363636'}
                            />
                    }
                    <DrawModes
                        changeMode={changeMode} 
                        drawModes={drawModes}
                        setDrawModes={setDrawModes}
                    />
                    {
                        textState === 'none' ? 
                            <TextIcon 
                                onClick={() => {changeDrawState('text')}}
                                fill={iconState === 'none' ? '#79CE67' : '#363636'}
                            /> 
                        : textState === 'high' ? 
                            <TextHighIcon 
                                onClick={() => {changeDrawState('text')}}
                                fill={iconState === 'high' ? '#79CE67' : '#363636'}
                            /> 
                        : textState === 'small' && 
                            <TextSmallIcon 
                                onClick={() => {changeDrawState('text')}}
                                fill={showTextModal ? '#79CE67' : '#363636'}
                            />
                    }
                    <TextModes 
                        textModes={textModes}
                        setTextModes={setTextModes}
                    />
                    <ShapesModes 
                        shapeModes={shapeModes}
                        setShapeModes={setShapeModes}
                    />
                        {
                        shapeState === 'square' ? 
                            <SquareIcon 
                                onClick={() => {changeDrawState('shapes')}}
                                fill={iconState === 'square' ? '#79CE67' : '#363636'}
                            /> 
                        : shapeState === 'circle' ? 
                            <CircleIcon 
                                onClick={() => {changeDrawState('shapes')}}
                                fill={iconState === 'circle' ? '#79CE67' : '#363636'}
                            /> 
                        : shapeState === 'triangle' ?
                            <TriangleIcon 
                                onClick={() => {changeDrawState('shapes')}}
                                fill={iconState === 'triangle' ? '#79CE67' : '#363636'}
                            />
                            : shapeState === 'squareFill' ?
                            <SquareIconFill 
                                onClick={() => {changeDrawState('shapes')}}
                                fill={iconState === 'squareFill' ? '#79CE67' : '#363636'}
                            />
                            : shapeState === 'circleFill' ?
                            <CircleIconFill 
                                onClick={() => {changeDrawState('shapes')}}
                                fill={iconState === 'circleFill' ? '#79CE67' : '#363636'}
                            />
                            : shapeState === 'triangleFill' && 
                            <TriangleIconFill 
                                onClick={() => {changeDrawState('shapes')}}
                                fill={iconState === 'triangleFill' ? '#79CE67' : '#363636'}
                            />
                        }
                <EraseIcon 
                    onClick={() => {
                        doubleTouch()
                        changeDrawState('erase') 
                    }}
                    onDoubleClick={() => callCleanCanvas()}
                    fill={iconState === 'erase' ? '#79CE67' : '#363636'}                
                />
                <ColorIcon
                    onClick={() => {changeDrawState('color')}}
                    fill={color}
                />
                <ColorPicker onUpdate={onUpdate} colorActive={colorActive} setColorActive={setColorActive}/>
                <JohariIcon 
                    className="johari-icon"
                    onClick={() => {
                        changeDrawState('johari')
                        changeJohari()
                    }}
                    fill={showJohari ? '#79CE67' : '#363636'}                
                />       
            </div>
        </>
    )
}

export default LeftMenuDraw;