import {useState, useEffect, useContext} from 'react';
import { useHistory } from 'react-router';
import Modal from './../shared/modal/Modal';
import UserContext from '../../context/UserContext';
import ModalContext from '../../context/ModalContext';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButton} from '@ionic/react';
import { Capacitor, Plugins } from '@capacitor/core';
import UserService from '../../service/users.service';

import {LogoNegative, ArrowBack} from '../../assets/index';
import './accountPlan.css'
const { Subscriptions } = Plugins;

export interface AccountPlanProps {
    signIn: any
}

const AccountPlan: React.FC<AccountPlanProps> = ({signIn}) => {

    const { Browser } = Plugins;
    const history = useHistory();
    const userService = new UserService();
    const {userId, userEmail, isUnsubscribe} = useContext(UserContext);
    const {modalMessage, showModal} = useContext(ModalContext);

    const [user, setUser] = useState<any>({})
    const [plan, setPlan] = useState({
        type: '',
        days: 30
    })
    const [waiting, setWaiting] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        show: false,
        message : ''
    })
    const plat = Capacitor.getPlatform()
    const token = window.sessionStorage.getItem('jwt')
    const login = window.sessionStorage.getItem('jwt')
    const verifyUser = window.sessionStorage.getItem('verUser')

    const today = new Date().toISOString()
    let expiratedAccount = user.length > 0 ? (today > user[0].dateEnd ? true : false) : false
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate()-31);
    if (user.length > 0 ){
        const createdDate = Date.parse(user[0].createdAt);
        if (createdDate < thirtyDaysAgo.getTime()){
            expiratedAccount  = true;
        }
    }

    useEffect(()=>{
        getUser()
        const timer = setInterval(iosCheckSubscriptions, 4000);
        return () => clearInterval(timer);
    }, []);

    const getUser = () => {
        userService
        .getUser(userId, token)
        .then((res:any) => {
            setUser(res.data.user)
            const pl = res.data.user[0].subscription
            setPlan({ ...plan, type: pl==='month'? 'mensual': pl==='year'? 'anual': (expiratedAccount)?'mensual':'gratis'})
        })  
        .catch((err:any) => {
            console.error(err)
        })
        

        // verify current payment status
        if (plat === 'ios'){
            setWaiting(true)
            iosCheckSubscriptions();
            /*
            const restore = Subscriptions.restorePurchases({ }).then( (r:any) => {
                console.log("Restoring...", r.data)
            });
            */
            const result = Subscriptions.fetchProducts({ }).then( (r:any) => console.log("Fetch response", r.data));

        }
    }

    const iosCheckSubscriptions = () => {
        if (plat === 'ios'){
            const verify = Subscriptions.verifyReceipt({ }).then( (r:any) => {
                if (r.data && r.data.latest_receipt_info){
                    const expirationDate:number = r.data.latest_receipt_info[0].expires_date_ms
                    const fromDate:number = r.data.latest_receipt_info[0].purchase_date_ms
                    const productId:string = r.data.latest_receipt_info[0].product_id
                    const transactionId:string = r.data.latest_receipt_info[0].transaction_id
                    const type:string = r.data.latest_receipt_info[0].in_app_ownership_type
                    
                    const today:number = Date.now()
                    userService.updateInAppPurchase({userId, userEmail, token, fromDate, expirationDate, productId, transactionId, type}).then((r:any)=>{
                        if(r.data.response !== 'ok'){
                            setErrorMessage({...errorMessage, show: true, message: r.data.response})
                            setTimeout(() => {
                                setErrorMessage({...errorMessage, show: false, message: ''})
                                history.push('/login')
                            }, 7000);
                        }
                        else{
                            setUser( (u:any)=>({...u, dateEnd: expirationDate}))
                            if (today < expirationDate){
                                // access allowed. Go to dashboard again
                                //window.location.href = '/dashboard';
                                history.push('/dashboard')
                            }
                        }
                        setWaiting(false)
                    })
                }
            });
            setWaiting(false)
        }
    }

    const changePlan = async () => {
        setWaiting(true);
        if(plan.type === 'gratis'){
            const userPlan:string = plan.type
                userService
                    .editUserPlan({userPlan, userId})
                    .then(res => {
                        window.sessionStorage.setItem('verSus', 'free');
                        const verifySuscription = true
                        signIn(token, login, verifyUser, verifySuscription)
                        setTimeout(()=>{ history.push('/dashboard') }, 2000);
                    })
                    .catch(err => console.error(err))
        }
        const plat = Capacitor.getPlatform()
        if (plat === 'ios'){
            //const result = await Subscriptions.echo({ value: 'Hello World!' });
            if (plan.type === 'mensual'){
                const result = await Subscriptions.subscribeTo({ id: 'mentapp_pro_m23' });
            }
            if (plan.type === 'anual'){
                const result = await Subscriptions.subscribeTo({ id: 'mentapp_pro_y23' });
            }
            return false;
        } else {
            if (plan.type === 'mensual'){
                // test: https://buy.stripe.com/test_8wM00x8tdbddfTOeUU
                // prod: https://buy.stripe.com/bIY4gna3q0N0e3ucMP
                //openCapacitorSite('https://buy.stripe.com/bIY4gna3q0N0e3ucMP')

                // new price 15€ = 18,15€ con IVA
                openCapacitorSite('https://buy.stripe.com/eVa8wDdfC9jw0cEcMW')
                 
                
            }
            if (plan.type === 'anual'){
                // test: https://buy.stripe.com/test_8wM00x8tdbddfTOeUU
                // PROD: https://buy.stripe.com/cN2bIPa3q9jwcZqfZ0
               //openCapacitorSite('https://buy.stripe.com/cN2bIPa3q9jwcZqfZ0')

                // new price 144€ = 174,24€ con IVA
                openCapacitorSite('https://buy.stripe.com/fZebIP5Na1R4gbC4gu')
                
            }
            setTimeout(()=>{
                history.push('/dashboard')
            },2000);
        }

    }

    const openCapacitorSite = async (url:string) => {
        await Browser.open({ url });
    };

    const unsubscribeModal = () => {
        modalMessage({type: 'unsubscribe', modalTitle: "¿Seguro que desea darse de baja?", modalSubtitle: "Si te das de baja, perderás la información de las sesiones guardadas"})
    }

    useEffect(() => {
        if(isUnsubscribe){
            userService
                .deleteUser(userId)
                .then(res => {
                    console.log(res)
                    sessionStorage.clear();
                    modalMessage({type: 'success-unsubscribe', modalTitle: "Baja gestionada correctamente", modalSubtitle: "Gracias por utilizar Mentapp. Nos vemos pronto"})
                    setTimeout(()=>{
                        signIn('', '', false, '')
                        history.push('/')
                    },2000);
                })
                .catch(err => console.error(err))
        }
    }, [isUnsubscribe])

    return (
        <div className="account-plan-container"> 
                <div className="account-plan-center">
                {showModal && <Modal />}
                {
                    errorMessage.show 
                    ?
                    <div className="account-plan error">
                    <div dangerouslySetInnerHTML={{__html: `<h3>${errorMessage.message}</h3>`}}></div>
                    </div>
                    :
                    <div className="account-plan">
                        {!expiratedAccount 
                            ? 
                            <>
                            <div className="back-icons">
                                <ArrowBack /><a href="/">Volver atrás</a>
                            </div>
                            <h2>Seleciona tu plan</h2>
                            <p>Para continuar con la creación de tu cuenta debes seleccionar un plan de suscripción.</p>
                            </>
                            :
                            <>
                            <h2>¡Tu plan de prueba expiró!</h2>
                            <p>Para continuar usando la aplicación debes seleccionar un plan de suscripción.</p>
                            { plat !== 'ios' &&
                            <p className="ios-alert">Si tu suscripción se paga habitualmente desde un dispositivo iOS, abre Mentapp desde ese dispositivo para verificar el pago.</p>
                            }
                            </>           
                        }

                        <IonCard
                            className={(plan.type === 'gratis') ? 'card-active' : ''}
                            onClick={() => {
                                setPlan({...plan, type: 'gratis', days: 30})
                            }}
                            disabled= {(expiratedAccount||waiting) ? true : false}
                        >
                            <IonCardHeader>
                                <IonCardTitle>Prueba 30 días </IonCardTitle>
                                <IonCardSubtitle>{expiratedAccount? '-':'Gratis'}</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                Acceso ilimitado a las herramientas y sesiones. <br/>{expiratedAccount? 'El periodo de prueba ha terminado.':'Límite 30 días.'}
                            </IonCardContent>
                        </IonCard>
                        <IonCard className={plan.type === 'mensual' ? 'card-active' : ''}
                            onClick={() => {
                                setPlan({...plan, type: 'mensual', days: 30})
                            }}
                            disabled= {waiting ? true : false}
                        >
                            <IonCardHeader>
                                <IonCardTitle>Plan PRO Mensual</IonCardTitle>
                                <IonCardSubtitle>{plat==='ios'? '18,49':'18,15'}€/mes IVA inc.</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                Acceso total a herramientas y sesiones en este dispositivo y vía web. Renovación <strong>Mensual</strong> automáticamente. Puedes darte de baja en cualquier momento.
                            </IonCardContent>
                            { new Date().getTime() < 1648763942000 &&
                            <div className="promo">Precio de lanzamiento.<br/>Sólo hasta el 15 de mayo.</div>
                            }
                        </IonCard>
                        <IonCard className={plan.type === 'anual' ? 'card-active' : ''}
                            onClick={() => {
                                setPlan({...plan, type: 'anual', days: 365})
                             }}
                             disabled= {waiting ? true : false}
                        >
                            <IonCardHeader>
                                <IonCardTitle>Plan PRO Anual</IonCardTitle>
                                <IonCardSubtitle>{plat==='ios'? '174,99':'174,24'}€/año IVA inc.</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                Acceso total a herramientas y sesiones en este dispositivo y vía web. Renovación <strong>Anual</strong> automáticamente. Puedes darte de baja en cualquier momento.
                            </IonCardContent>
                        </IonCard>
                        <p className="legal-alert">Consulta nuestros <a href="/terminos">términos de uso</a> y la <a href="/politica-privacidad">política de privacidad</a></p>
                        <IonButton 
                            disabled={waiting ? true : false}
                            fill="clear" className="button button-primary" 
                            onClick={() => changePlan()}
                        >Continuar</IonButton>
                    <div className="unsubscribe">
                        <p>No deseo renovar mi suscripción.</p>
                        <p onClick={() => unsubscribeModal()}>Darme de baja</p>
                    </div>
                    </div>
                }
            </div>
            <div className="footer-login">
                <LogoNegative />
            </div>
    </div>
     );
}
 
export default AccountPlan;