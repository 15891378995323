import axios from "axios";

export default class FeedbackService{
    constructor() {
        this.apiHandler = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            withCredentials: true
        })
    }

    sendFeedback = feedback => this.apiHandler.post('api/feedback/send-feedback', feedback)
    
}