import Bird from './../../../assets/img/sandbox-elements/animals/birds.png'
import Butterfly from './../../../assets/img/sandbox-elements/animals/butterfly.png'
import Dogcat from './../../../assets/img/sandbox-elements/animals/dogcat.png'
import Hummingbird from './../../../assets/img/sandbox-elements/animals/hummingbird.png'
import Penguin from './../../../assets/img/sandbox-elements/animals/penguin.png'
import Pig from './../../../assets/img/sandbox-elements/animals/pig.png'

import Building from './../../../assets/img/sandbox-elements/buildings/building.png'
import CaramelCastle from './../../../assets/img/sandbox-elements/buildings/caramel-castle.png'
import House from './../../../assets/img/sandbox-elements/buildings/house.png'
import SandCastle from './../../../assets/img/sandbox-elements/buildings/sand-castle.png'
import Skyscraper from './../../../assets/img/sandbox-elements/buildings/skyscraper.png'

import Cloud from './../../../assets/img/sandbox-elements/nature-element/cloud.png'
import MountainPeaks from './../../../assets/img/sandbox-elements/nature-element/mountain-peaks.png'
import MountainRoad from './../../../assets/img/sandbox-elements/nature-element/mountain-road.png'
import Mountains from './../../../assets/img/sandbox-elements/nature-element/mountains.png'
import River from './../../../assets/img/sandbox-elements/nature-element/river.png'
import Rock from './../../../assets/img/sandbox-elements/nature-element/rock.png'

import Boy from './../../../assets/img/sandbox-elements/persons/boy.png'
import Girl from './../../../assets/img/sandbox-elements/persons/girl.png'
import Man from './../../../assets/img/sandbox-elements/persons/man.png'
import Oldman from './../../../assets/img/sandbox-elements/persons/oldman.png'
import Woman from './../../../assets/img/sandbox-elements/persons/woman.png'

import Cactus from './../../../assets/img/sandbox-elements/plants/cactus.png'
import Flowers from './../../../assets/img/sandbox-elements/plants/flowers.png'
import Mushroom from './../../../assets/img/sandbox-elements/plants/mushroom.png'
import Plant from './../../../assets/img/sandbox-elements/plants/plant.png'

import Pine from './../../../assets/img/sandbox-elements/trees/pine.png'
import TreeNoLeaves from './../../../assets/img/sandbox-elements/trees/tree-noleaves.png'
import Tree from './../../../assets/img/sandbox-elements/trees/tree.png'

import Ambulance from './../../../assets/img/sandbox-elements/urban-element/ambulance.png'
import Bridge from './../../../assets/img/sandbox-elements/urban-element/bridge.png'
import Car from './../../../assets/img/sandbox-elements/urban-element/car.png'


export const sandboxImages = {
    animals : {
        bird: Bird,
        butterfly: Butterfly,
        dogcat: Dogcat,
        hummingbird: Hummingbird,
        penguin: Penguin,
        pig: Pig
    },
    buildings : {
        building: Building,
        caramelCastle: CaramelCastle,
        house: House,
        sandCastle: SandCastle,
        skyscraper: Skyscraper,
    },
    nature: {
        cloud: Cloud,
        mountainPeaks: MountainPeaks,
        mountainRoad: MountainRoad,
        mountains: Mountains,
        river: River,
        rock: Rock
    },
    persons:{
        boy: Boy,
        girl: Girl,
        man: Man,
        oldMan: Oldman,
        woman: Woman
    },
    plants:{
        cactus: Cactus,
        flowers: Flowers,
        mushroom: Mushroom,
        plant: Plant,
    },
    trees:{
        pine: Pine,
        treeNoLeaves: TreeNoLeaves,
        tree: Tree
    },
    urbanElements:{
        ambulance: Ambulance,
        bridge: Bridge,
        car: Car
    }
}