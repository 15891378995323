import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LogoNegative } from '../../assets';

const VerifyEmail: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        // send event to analytics
        setTimeout(() => {
            const pageEvent:any = {
                event: 'user-registration',
                page: '/signup',
            };
            //@ts-ignore
            window && window.dataLayer && window.dataLayer.push(pageEvent);
        }, 1000)
        
        setTimeout(() => {
            history.push('/acceso')
        }, 4000)
    }, [])      // eslint-disable-line

    return ( 
        <div className="login">
            <div className="session-form">
                <div className="signup">
                    <h2>Registro completado</h2>
                    <p>Hemos enviado un email a la dirección introducida en el registro.</p>
                    <p>Compruebe su bandeja de entrada y siga las instrucciones.</p>
                    <br />
                    <p>Si no lo recibe en unos minutos, compruebe la bandeja de SPAM.</p>
                </div>

            </div>
                <div className="footer-login">
                    <LogoNegative />
                </div>
        </div>
    );
}
 
export default VerifyEmail;