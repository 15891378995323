import {useContext, useState } from 'react';
import AuthService from '../../service/auth.service';
import { useHistory } from 'react-router';
import UserContext from '../../context/UserContext';

import {IonButton} from '@ionic/react';
import { Form } from 'react-bootstrap'
import { LogoEholo, Logo } from '../../assets';
import './signineholo.css';
export interface Props{
    signIn: any,
}

const SignInEholo : React.FC<Props> = ({signIn})  => {

    const {setUserId, setToken, setUserEmail} = useContext(UserContext)
    const history = useHistory();
    
    const [login, setLogin] = useState<any>({
        email: '',
        password: ''
    })

    const [error, setError] = useState<any>({
        active: false,
        errorMessage: ''
    })
    
    
    const handleChange = (e:any) => setLogin({ ...login, [e.target.name]: e.target.value })
    
    const handleSubmit = ( e : any) => {
        e.preventDefault();
        const {email, password} = login
        const authService = new AuthService();
        authService
            .logIn({email, password, eholo:'1'})
            .then((res:any) => {
                const token = res.data.token;
                const userId = res.data.user.id;
                const user = res.data.user;
                const userEmail = res.data.user.email
                const verifyMail = res.data.user.emailVerified
                const verifySuscription = res.data.user.subscription

                window.sessionStorage.setItem('eholoToken', res.data.eholoToken);

                window.sessionStorage.setItem('jwt', token);
                window.sessionStorage.setItem('userID', userId);
                window.sessionStorage.setItem('verUser', verifyMail);
                window.sessionStorage.setItem('userEmail', userEmail);
                verifySuscription !== null && window.sessionStorage.setItem('verSus', verifySuscription);
                
                setUserId(userId)
                setToken(token)
                setUserEmail(userEmail)
                signIn(token, true, verifyMail, verifySuscription)
                
                const endDate = new Date(user.dateEnd)
                const today = new Date(Date.now())
                
                if((endDate < today) || (user.subscription === null)){
                    history.push('/account-plan')
                }
                else{
                    history.push('/linking-eholo')
                }
               
            })
            .catch((err:any)=> {
                console.log(error)
                if(err.response){
                    setError({...error, active: true, errorMessage: err.response.data.error})
                }
                else{
                    setError({...error, active: true, errorMessage: "Error de servidor. Sesión no encontrada"})
                }
            })
    }

    return ( 

        <>
        <div className="login" scroll-y="false">
            <div className="session-form">
                <div className="signin">
                    <div className='eholo-mentapp'>
                        <LogoEholo/>
                        <Logo/>
                    </div>
                            <h2>Concede permiso a eHolo<br/>para vincular tu cuenta de Mentapp</h2>
                            <Form onSubmit={handleSubmit} className="signin-form">                    
                            <Form.Group controlId='email'>
                                <Form.Control 
                                    type='email'
                                    name='email'
                                    value={login.email} 
                                    placeholder="Correo electrónico"
                                    onChange={ handleChange }
                                    autoComplete="off"
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId='password'>
                                <Form.Control 
                                    type='password'
                                    name='password'
                                    value={login.password} 
                                    placeholder="Contraseña"
                                    onChange={ handleChange }
                                    autoComplete="off"
                                    required
                                />
                            </Form.Group>
                            {error.active && <p className="error-message">{error.errorMessage}</p>}
                            <IonButton fill="clear" type="submit" className="button button-primary">Acceder</IonButton>
                            </Form>
                            
                            <div>
                            <IonButton fill="clear" className="forget-password" href="/reset-password">He olvidado la contraseña</IonButton>
                            </div>
                            <div className="create-account-container access">
                                <p className="create-account">¿No tienes cuenta de terapeuta?</p><IonButton fill="clear" className="link-signin" href="/registro">Regístrate</IonButton>
                            </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default SignInEholo;
