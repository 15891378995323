import {useRef} from 'react';
import { IonContent } from '@ionic/react';
interface PersonsModeProps {
    personMode: Boolean,
    personImages: any,
    sandboxElementClass: any,
    setSandboxImage: any,
}
 
const PersonsMode: React.FC<PersonsModeProps> = ({personMode, personImages, sandboxElementClass, setSandboxImage}) => {

    const {boy, girl, man, oldMan, woman} = personImages;

    const sandboxScroll = useRef<HTMLIonContentElement>(null);

    const scrollBy = (amount:number) => {
        if(sandboxScroll.current !== null){
            sandboxScroll.current.scrollByPoint(amount, amount, 1000)
        }
    }
    
    return ( 
        <IonContent className={personMode ? 'sandbox-menu expanded' : 'sandbox-menu collapsed'} ref={sandboxScroll}>
            <div className="top-arrow" onClick={()=>scrollBy(-600)}>&raquo;</div>
            <div className="bottom-arrow" onClick={()=>scrollBy(600)}>&raquo;</div>
            <div className={`components ${personMode}`}>
                <div className="images">
                    <div className={sandboxElementClass(boy)}>
                        <img src={boy} 
                            alt="boy" 
                            draggable="false"
                            onClick={() => setSandboxImage(boy)}
                        />
                    </div>
                    <div className={sandboxElementClass(girl)}>
                        <img src={girl} 
                            alt="girl" 
                            draggable="false"
                            onClick={() => setSandboxImage(girl)}
                        />
                    </div>
                    <div className={sandboxElementClass(man)}>
                        <img src={man} 
                            alt="man" 
                            draggable="false"
                            onClick={() => setSandboxImage(man)}
                        />
                    </div>
                    <div className={sandboxElementClass(oldMan)}>
                        <img src={oldMan} 
                            alt="old-man" 
                            draggable="false"
                            onClick={() => setSandboxImage(oldMan)}
                        />
                    </div>
                    <div className={sandboxElementClass(woman)}>
                        <img src={woman} 
                            alt="woman" 
                            draggable="false"
                            onClick={() => setSandboxImage(woman)}
                        />
                    </div>
                </div>
            </div>
        </IonContent>
     );
}
 
export default PersonsMode;