import {useContext, useState} from 'react';
import UIContext from '../../context/UIContext';
import ToolsContext from '../../context/ToolsContext';
import {ToolsTherapistMenu, CardsIcon, ClicksIcon, DiceIcon, PaintIcon, SandboxIcon, TeddyIcon, VideocallIcon} from '../../assets/index';

import './bottomToolsMenu.css';

export interface ToolsMenuProps {
    tools: any[]
    onSelectionChange: any,
    showMenus: any
}
 
const BottomToolsMenu: React.FC<ToolsMenuProps> = ({tools, onSelectionChange, showMenus}) => {

    const {
        hideBottomMenu,
        iconActive, 
        modeState,
        showToyPannel,
        setHideToolsMenu,
        setIconActive,
        setIconState, 
        setShowToyPannel} = useContext(UIContext);
    const { setShowSelectCard, setShowTeddyTool, setShowClicTool} = useContext(ToolsContext)
    const [openMenu, setOpenMenu] = useState(true)

    const changeMode = (mode:any) => {
        onSelectionChange(mode)
        setIconState(mode)
    }

    const checkActiveIcon = (icon:string) => tools?.includes(icon) ? '#363636' : '#36363688'

    return ( 
        <div className={!hideBottomMenu ? 'tools-menu-container' : 'tools-menu-container-none'}>
            <div className={openMenu ? 'tools-menu-icon-expanded' : 'tools-menu-icon-collapsed'}>
                <ToolsTherapistMenu 
                    fill={!iconActive ? '#79CE67' : '#363636'}
                    onClick={() => {
                        setOpenMenu(!openMenu)
                        setIconActive(!iconActive)
                    }}
                />
            </div>
            <div className={openMenu ? 'tools-menu-container expanded' : 'tools-menu-container collapsed'}>
                <CardsIcon 
                    onClick={() => {
                        changeMode('card');
                        setHideToolsMenu(true)
                        setShowSelectCard(true)
                        showMenus(true, 'cards', false)
                    }}
                    fill={modeState === 'card' ? '#79CE67' : checkActiveIcon('cards')}
                />
                <PaintIcon 
                    onClick={() => {
                        changeMode('draw');
                        setHideToolsMenu(false)
                    }}
                    fill={modeState === 'draw' ? '#79CE67' : checkActiveIcon('paint')}
                />
                <DiceIcon 
                    onClick={() => {
                        setHideToolsMenu(true)
                        changeMode('dice');
                    }}
                    fill={modeState === 'dice' ? '#79CE67' : checkActiveIcon('dice')}
                />
                <ClicksIcon 
                    onClick={() => {
                        changeMode('toy');
                        setShowClicTool(true);
                        setShowToyPannel(!showToyPannel)
                        setHideToolsMenu(false)
                    }}
                    fill={modeState === 'toy' ? '#79CE67' : checkActiveIcon('clicks')}
                />
                <SandboxIcon 
                    onClick={() => {
                        changeMode('sandbox');
                        setHideToolsMenu(false)
                    }}
                    fill={modeState === 'sandbox' ? '#79CE67' : checkActiveIcon('sandbox')}
                />
                <TeddyIcon 
                    onClick={() => {
                        changeMode('teddy');
                        setHideToolsMenu(true)
                        setShowTeddyTool(true)
                        showMenus(true, 'teddy', false)

                    }}
                    fill={modeState === 'teddy' ? '#79CE67' : checkActiveIcon('teddy')}
                />
                <VideocallIcon 
                    onClick={() => {
                        changeMode('videocall');
                        setHideToolsMenu(true)
                        showMenus(true, 'videocall', false)
                    }}
                    fill={modeState === 'videocall' ? '#79CE67' : checkActiveIcon('videocall')}
                />
            </div>
        </div>
     );
}
 
export default BottomToolsMenu;