import {useState} from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom'
import AuthService from '../../service/auth.service';

import { Form } from 'react-bootstrap';
import {  IonButton} from '@ionic/react';
import { RevealIcon, LogoNegative } from '../../assets';

type QuizParams = {
    token: string;
  };  


const ResetPassword: React.FC = () => {


    const history = useHistory();
    const {token} = useParams<QuizParams>();

    const [resetPassword, setResetPassword] = useState<any>({
        password: '',
        confirmPassword: '',
        passwordShown: false,
    })

    const [error, setError] = useState<any>({
        active: '',
        errorMessage: ''
    })

    const [success, setSuccess] = useState<any>({
        successProcess: false,
        successMessage: ''
    })

    const handleChange = (e:any) => setResetPassword({ ...resetPassword, [e.target.name]: e.target.value })

    const handleSubmit = (e:any) => {
        e.preventDefault();

        if (resetPassword.password !== resetPassword.confirmPassword){
            setError({...error, active : 'password', errorMessage: 'La contraseña no coincide'})
        }
        else{
            const authService = new AuthService();
            const {password} = resetPassword

            authService
                .resetPassword({password, token})
                .then((res:any) => {
                    setSuccess({successProcess: true, successMessage: 'La contraseña se ha cambiado con éxito'})
                    setTimeout(() => {
                        history.push('/')
                    }, 4000);
                })
                .catch((err:any)=> {
                    console.error(err)
                })
            
        }
        setTimeout(() => {
            setError({...error, active: '' , errorMessage: ''})
        }, 2000);
    };

    const togglePasswordVisibility = () => {
        setResetPassword({...resetPassword, passwordShown : !resetPassword.passwordShown})
    }

    return ( 
    <>
        <div className="login">
            <div className="session-form">
                {
                    !success.successProcess
                        ?
                        <div className="signup">
                        <h2>Elige una nueva contraseña</h2>
                        <Form onSubmit={handleSubmit} className="signup-form">
        
                            <Form.Group controlId='password'>
                                <Form.Control 
                                    type={resetPassword.passwordShown ? 'text' : 'password'}
                                    name='password'
                                    value={resetPassword.password}
                                    placeholder="Contraseña" 
                                    onChange={ handleChange }
                                    autoComplete="off"
                                    required
                                />
                                <RevealIcon onClick={togglePasswordVisibility} />
                            </Form.Group>
        
                            <Form.Group controlId='confirmPassword'>
                                <Form.Control 
                                    className={error.active === 'password' ? 'error-input': ''}
                                    type={resetPassword.passwordShown ? 'text' : 'password'}
                                    name='confirmPassword'
                                    value={resetPassword.confirmPassword}
                                    placeholder="Confirmar contraseña"
                                    onChange={ handleChange }
                                    autoComplete="off"
                                    required
                                />
                                <RevealIcon onClick={togglePasswordVisibility} />
                            </Form.Group>
                            {error.active && <p className="error-message">{error.errorMessage}</p>}
                            <IonButton fill="clear" type="submit" className="button button-primary no-ripple">Cambiar contraseña</IonButton>
                            </Form>
                        </div>
                        :
                        <h2>{success.successMessage}</h2>
                }

            </div>
                <div className="footer-login">
                    <LogoNegative />
                </div>
        </div>
    </>
     );
}
 
export default ResetPassword;