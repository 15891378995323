import { useContext } from 'react';
import ToolsContext from '../../../../context/ToolsContext';
import UIContext from '../../../../context/UIContext';
import {DrawIcon, MarkerIcon, BrushIcon} from './../../../../assets/index'; 
import './drawModes.css'

export interface DrawModesProps {
     drawModes : Boolean;
     setDrawModes: any;
     changeMode: any;
}
 
const DrawModes: React.FC<DrawModesProps> = ({drawModes, setDrawModes, changeMode}) => {

    const {setLineWidth, setErase, setDrawState} = useContext(ToolsContext)
    const {setIconState} = useContext(UIContext)

    const drawConfig = () => {
        changeMode('draw');
        setErase(false);
        setDrawModes(!drawModes)
    }

    const selectDrawState = (value:string) => {
        setDrawState(value)
        setIconState(value)
    }

    return ( 
        <div className={drawModes ? 'draw-modes-expanded' : 'draw-modes-collapsed'}>
            <DrawIcon 
                onClick={() => {
                    drawConfig()
                    selectDrawState('paint')
                    setLineWidth(4)
                }}
                fill='#363636'
            />
            <MarkerIcon 
                onClick={() => {
                    drawConfig()
                    selectDrawState('marker')
                    setLineWidth(10)
                }}
                fill='#363636'
            />
            <BrushIcon 
                onClick={() => {
                    drawConfig()
                    selectDrawState('brush')
                    setLineWidth(14)
                }}
                fill='#363636'
            />
        </div>
     );
}
 
export default DrawModes;