import React, {useState} from 'react';

type Props = {
    children: JSX.Element
}
interface UserContextInterface{
    token: string | null,
    userId: string | null,
    userEmail: string | null,
    isUnsubscribe: Boolean,
    setToken: any,
    setUserId: any,
    setUserEmail: any,
    setIsUnsubscribe: any
}

export const UserContextDefault = {
    token: window.sessionStorage.getItem('jwt'),
    userId: window.sessionStorage.getItem('userID'),
    userEmail: window.sessionStorage.getItem('userEmail'),
    isUnsubscribe: false,
    setToken: '',
    setUserId: '',
    setUserEmail: '',
    setIsUnsubscribe: false
}

const UserContext = React.createContext<UserContextInterface>(UserContextDefault)

export function UserContextProvider ({children}: Props) {

    let [token, setToken] = useState(UserContextDefault.token);
    let [userId, setUserId] = useState(UserContextDefault.userId);
    let [userEmail, setUserEmail] = useState(UserContextDefault.userEmail);
    let [isUnsubscribe, setIsUnsubscribe] = useState(UserContextDefault.isUnsubscribe);

    return (
        <UserContext.Provider 
            value={{
                token,
                userId,
                userEmail,
                isUnsubscribe,
                setToken,
                setUserId,
                setUserEmail,
                setIsUnsubscribe
            }}>
            {children}
        </UserContext.Provider>)

}

export default UserContext

  