import * as React from 'react';
import AuthService from '../../service/auth.service';

import {IonButton} from '@ionic/react';
import { ArrowBack, LogoEholo } from '../../assets';
import { Form } from 'react-bootstrap';
import { useState } from 'react';


const LinkingEholo: React.FC = () => {

    const [email, setEmail] = useState<string>('')

    const [error, setError] = useState<any>({
        active: false,
        errorMessage: ''
    })

    const [response, setResponse] = useState<any>({
        restorePassword: false,
        responseMessage: ''
    })

    const handleSubmit = ( e : any) => {
        e.preventDefault();
        const eholoToken = window.sessionStorage.getItem('eholoToken')
        window.location.href ='https://eholo.health/mentapp-callback/?eholoToken='+eholoToken
    }

    return (
        <div className="login" scroll-y="false">
            <div className="session-form">
                <div className="signin">
                    <div className="back-icons">
                        <ArrowBack /><a href="/acceso-eholo/">Volver atrás</a>
                    </div>
                {!response.restorePassword 
                    ?
                    <>
                    <div className='logo-eholo'>
                        <LogoEholo/>
                    </div>
                            <h2>¿Quieres vincular tu cuenta de MentApp con eHolo?</h2>
                            <p>Esto permitirá a eHolo generar sesiones en tu cuenta y compartir el enlace de acceso a la sesión desde la plataforma.</p>
                            <p>Al hacer click en "Vincular cuenta" te redirigiremos de vuelta a eHolo con la autorización para operar en Mentapp.</p>
                            <Form onSubmit={handleSubmit}>                    

                            {error.active && <p className="error-message">{error.errorMessage}</p>}
                            <IonButton fill="clear" type="submit" className="button button-primary">Vincular cuenta</IonButton>
                            </Form>
                    </>
                    :
                    <>
                        <h3>{response.responseMessage}</h3>
                    </>
                }
                </div>
            </div>
        </div>
     );
}
 
export default LinkingEholo;