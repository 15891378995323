import React, {useState, useEffect, useContext, CSSProperties} from 'react';
import ToolsContext from '../../../../../context/ToolsContext';
import UserToolsContext from '../../../../../context/UserToolsContext';
import UIContext from '../../../../../context/UIContext';

import SandboxElement from './SandboxElement'
import './sandboxComponent.css'
interface SandboxComponentProps {
    onUpdate:(sandbox:any) => void,
    dragging: number,
    setDragging: any,
    sharedData: any,
    zoom: number
}
 
const SandboxComponent: React.FC<SandboxComponentProps> = ({ zoom, onUpdate, dragging, setDragging, sharedData}) => {

    const initialSandbox = {
        id: 0,
        image: "",
        pX: 0,
        pY: 0,
        zIndex: 0
    }

    const {sandboxImage, setSandboxImage} = useContext(ToolsContext)
    const {setHideBottomMenu, panning} = useContext(UIContext)
    const {sandboxState, setSandboxState} = useContext(UserToolsContext)
    
    const [sandboxElement, setSandboxElement] = useState([...sandboxState || initialSandbox])
    const [clickOffset, setClickOffset] = useState({x:0,y:0, pX:0, pY:0});
    const [isDragging, setIsDragging] = useState(-1);
    const [zIndex, setZIndex] = useState(2);
    const [position, setPosition] = useState({px:220,py: 150});
    const [isDragCanvas, setDragCanvas] = useState(false);

    const addElement = ({nativeEvent}:any)=>{
        if (isDragging !== -1) {
            return;
        }
        
        if(nativeEvent.target.className === "sandbox-image"){
            return;
        }
        if(sandboxImage===''){
            return;
        }

        const el = nativeEvent.target.parentNode.parentNode.parentNode;
        let translation = getTranslateValues(el);
        if (translation === undefined){
            translation = {x:0, y:0, z:0}
        }
        const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent;
        const positionX = (clientX)/zoom - position.px;
        const positionY = (clientY)/zoom - position.py;
        const updateElements = [...sandboxElement, {
                id: sandboxElement.length,
                image: sandboxImage, 
                pX: positionX,
                pY : positionY, 
                zIndex: zIndex + 1,
                type: 'small'
            }
        ]
        setSandboxElement(updateElements)
        setSandboxState(updateElements)
        onUpdate({sandbox: updateElements});
        setIsDragging(-1);
        setSandboxImage('');
    }

    useEffect(() => {
        setHideBottomMenu(false)
        if(sandboxState.length > 1){
            setSandboxElement(sandboxState)
            onUpdate({sandbox: sandboxState});
        }
    }, [])

    useEffect(()=>{
        if (sharedData.sandbox !== undefined && sharedData.sandbox.length > 1){
            setSandboxElement(sharedData.sandbox)
            setSandboxState(sharedData.sandbox)
        }
    }, [sharedData.sandbox]);

    useEffect(()=>{
        if (sharedData.position !== undefined){
            setPosition({...position, px: sharedData.position.px, py: sharedData.position.py})
        }}, [sharedData.position])

    const startDrag = ({nativeEvent}:any, index:number)=>{
        if(!panning){
            return;
        }
        const newElements=[...sandboxElement];
        const {pX, pY} = newElements[index];
        const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent;
        sandboxElement.forEach((element:any) => {
            element.id === index
                &&  setClickOffset({x:clientX, y:clientY, pX, pY});  
        })
        setIsDragging(index);
    }

    const drag = (e:any, index:number)=>{
        if (isDragging >= 0){

            e.stopPropagation(); 
            const {clientX, clientY} = e.nativeEvent.touches? e.nativeEvent.touches[0] : e.nativeEvent;
            const pLeft = clickOffset.pX + (clientX - clickOffset.x)/zoom;
            const pTop = clickOffset.pY + (clientY - clickOffset.y)/zoom;
            const newElements=[...sandboxElement];
            newElements[index] = {...newElements[index], pX : pLeft, pY : pTop, z: zIndex + 1};
            setZIndex(zIndexPrev => zIndexPrev+1);
            setSandboxElement(newElements)
            setSandboxState(newElements)
        }
    }
    const dragCanvas =  ({nativeEvent}:any)=>{
        if (isDragCanvas){
            const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent
            setPosition(p => { 
                const np = {...p, px : clickOffset.pX + (clientX - clickOffset.x) , py :clickOffset.pY  + (clientY-clickOffset.y) }
                onUpdate({position: np});
                return np;
            } );
        }
    }
    const downCanvas =  ({nativeEvent}:any)=>{
        if (dragging === 1){
            setDragCanvas(true);
            const {clientX, clientY} = nativeEvent.touches? nativeEvent.touches[0] : nativeEvent
            setClickOffset({...clickOffset, x:clientX, y:clientY, pX:position.px, pY: position.py});
        }
    }
    const upCanvas =  ({nativeEvent}:any)=>{
        setDragCanvas(false);
    }

    const endDrag = ({nativeEvent}:any)=>{
        setIsDragging(-1);
        onUpdate({sandbox: sandboxState});
    }

    const changeType = ({nativeEvent}:any, index:number, type:string)=>{
        const newElements = [...sandboxElement];
        if (type == 'remove'){
            newElements[index] = {...newElements[index], image:''};
        } else {
            newElements[index] = {...newElements[index], type:type};
        }
        setSandboxElement(newElements)
        setSandboxState(newElements)
        onUpdate({sandbox: newElements});
    }

    const getTranslateValues = (element:any) => {
        const style = window.getComputedStyle(element)
        const matrix = style['transform']
      
        // No transform property. Simply return 0 values.
        if (matrix === 'none' || typeof matrix === 'undefined') {
          return {
            x: 0,
            y: 0,
            z: 0
          }
        }
      
        // Can either be 2d or 3d transform
        const matrixType = matrix.includes('3d') ? '3d' : '2d'
        const matrixValues1 = matrix.match(/matrix.*\((.+)\)/)
        const matrixValues2 = matrixValues1 && matrixValues1[1]
        const matrixValues = matrixValues2?.split(', ')
      
        if (matrixValues ==null) return {x: 0,
            y: 0,
            z: 0}
        // 2d matrices have 6 values
        // Last 2 values are X and Y.
        // 2d matrices does not have Z value.
        if (matrixType === '2d') {
          return {
            x: parseFloat(matrixValues[4]),
            y: parseFloat(matrixValues[5]),
            z: 0
          }
        }
      
        // 3d matrices have 16 values
        // The 13th, 14th, and 15th values are X, Y, and Z
        if (matrixType === '3d') {
          return {
            x: parseFloat(matrixValues[12]),
            y: parseFloat(matrixValues[13]),
            z: parseFloat(matrixValues[14])
          }
        }
      }
      
    const canvasStyle : CSSProperties = {
        top: position.py,
        left: position.px,
        cursor: (dragging===1)?'grab':'auto'
    }

    return ( 
        <>
            <div id="sandbox-pannel" onClick={addElement} style={canvasStyle} onMouseMove={dragCanvas} onTouchMove={dragCanvas} onMouseDown={downCanvas} onTouchStart={downCanvas} onMouseOut={upCanvas} onTouchEnd={upCanvas} onMouseUp={upCanvas}>
                {sandboxElement.map(element => {
                    return <SandboxElement key={element.id + element.zIndex} element={element} onStartDrag={startDrag} onChangeType={changeType} onDrag={drag} onEndDrag={endDrag}/>
                })}
            </div>
        </>
     );
}
 
export default SandboxComponent;