import {useState, useContext, useEffect} from 'react';
import SessionService from '../../../service/sessions.service';
import UserContext from '../../../context/UserContext';
import UIContext from '../../../context/UIContext';
import ModalContext from '../../../context/ModalContext'

import Modal from '../../shared/modal/Modal'
import TodaySessionsDashboardCard from './TodaySessionsDashboardCard';
import FutureSessionsDashboardCard from './FutureSessionsDashboardCard';
import { versionNumber } from '../../shared/version/VersionNumber';


import { IonHeader, IonButton} from '@ionic/react';
import { Plugins } from '@capacitor/core';
const JitsiMeet = Plugins.Jitsi;


const SessionPannelDashboard: React.FC = () => {
    const {userId, token} = useContext(UserContext);
    const {showModal, modalMessage, deleteModal, setDeleteModal} = useContext(ModalContext);
    const {setDashboardState} = useContext(UIContext);
    const sessionService = new SessionService(); 
    
    const [todaySessions, setTodaySessions] = useState<any>([])
    const [futureSessions, setFutureSession] = useState<any>([])

    useEffect(()=>{
        getServices();
        changeMode('');

        JitsiMeet.leaveConference();
    }, []);


    const changeMode = (mode:any) => {
        //socketService.sendMessage('event', {mode, gid:roomName})    
    }

    const getServices = () => {
        sessionService
            .getUserSessions(userId, token)
            .then((res:any) => {
                sortedSessions(res.data.userSessions)
            })
            .catch((err:any) => {
                console.error(err)
            })
    }

    const actualDate = Date.now();
    const today = new Date(actualDate)
    let dayInMillis=24*3600000;

    const sortedSessions = (sessions:any) => {

        let todaySess:any = [];
        let futureSess:any = [];
        
        sessions.forEach((session:any) => {
            let days1=Math.floor(today.getTime()/dayInMillis);
            let days2=Math.floor(new Date(session.date).getTime()/dayInMillis);
            if(days1 === days2){
                todaySess.push(session);
            }
            else if(days1 < days2){
                futureSess.push(session);
            }
        });
        
        const sortedSessions = todaySess.sort((a:any, b:any) => (a.date > b.date) ? 1 : -1)
        setTodaySessions(sortedSessions)

        const sortedFutureSessions = futureSess.sort((a:any, b:any) => (a.date > b.date) ? 1 : -1)
        setFutureSession(sortedFutureSessions)

    };

    const deleteSession = (id:any) => {
        const sessionService = new SessionService();
        sessionService
            .deleteSession(id, userId, token)
            .then((res:any) => {
                sortedSessions(res.data.userSessions)
                modalMessage({type: 'success', modalTitle: 'Sesión eliminada', modalSubtitle: 'Tu sesión ha sido eliminada correctamente'})
                setDeleteModal({...deleteModal, show: false, id: 0})
            })
            .catch((err:any) => {
                console.error(err)
            }) 
    }

    return ( 
        <div className="dashboard-content">
        <IonHeader>
            <h1>Sesiones</h1>
            {/* <IonRouterLink routerLink="/new-session"> */}
                <IonButton
                    fill="clear"
                    className={`button button-primary`}
                    onClick={() => setDashboardState('new-session')}
                    >Añadir nueva sesión</IonButton>
            {/* </IonRouterLink>   */}
        </IonHeader>
        {todaySessions.length > 0 || futureSessions.length > 0
            ?
            <div className="sessions-table">
                <h3>Hoy</h3>
                {todaySessions.length > 0
                        ?
                        todaySessions.map((sessions:any) => 
                        <TodaySessionsDashboardCard
                            key={sessions.id}
                            id={sessions.id}
                            code={ sessions.code }
                            name={ sessions.name }
                            date={ sessions.date }
                            deleteSession={deleteSession}
                        />
                    )
                    :
                    <p className="alert-session-dashboard">No hay sesiones programadas para hoy</p>
                }
                <div className="future-session-container">
                <h3>Próximas sesiones</h3>
                {futureSessions.length > 0 
                        ?
                        futureSessions.map((sessions:any) =>
                        <FutureSessionsDashboardCard
                            key={sessions.id}
                            id={sessions.id}
                            code={ sessions.code }
                            name={ sessions.name }
                            date={ sessions.date }
                            deleteSession={deleteSession}
                        />
                )
                    :
                    <p className="alert-session-dashboard">No hay sesiones programadas</p>
                }
                </div>
            </div>
            :
            <h1 
                className="modal"
                onClick={() => setDashboardState('new-session')}
            >Crea tu primera sesión para comenzar</h1>
            }
            {showModal && <Modal /> }
            <div className='version-code'>
                <p>V. {versionNumber()}</p>
            </div>
        </div>
     );
}
 
export default SessionPannelDashboard;